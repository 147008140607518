import React, { useState } from "react";

export default function Search({ searchQuery, placeholder }) {
 const [searchString, setSearchString] = useState("");
 return (
  <div className="flex items-center">
   <div className="relative w-full md:w-64">
    <div className="pl-2 absolute inset-y-0 left-0 flex items-center">
     <svg
      className="w-4 h-4 text-gray-400"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
     >
      <path d="M10.88 9.933l3.593 3.6a.667.667 0 01-.946.934L9.94 10.88a5.333 5.333 0 11.94-.94v-.007zm-4.213.734a4 4 0 100-8 4 4 0 000 8z" />
     </svg>
    </div>
    <form
     onSubmit={(e) => {
      e.preventDefault();
      searchQuery(searchString);
     }}
    >
     <input
      className="w-full px-8 py-2 md:py-1 bg-white rounded border border-gray-200 text-gray-800 focus:outline-none focus:shadow-outline"
      type="text"
      minLength="3"
      autoComplete="off"
      placeholder={placeholder}
      value={searchString}
      onChange={(e) => {
       setSearchString(e.target.value);
      }}
     />
    </form>
    {searchString !== "" ? (
     <div className="absolute right-0 top-0 bottom-0">
      <button
       className="px-1 py-1 h-full focus:outline-none focus:shadow-outline"
       onClick={() => {
        setSearchString("");
        return searchQuery("");
       }}
      >
       <svg
        className="w-6 h-6 text-gray-800"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
       >
        <path d="M16.24 14.83a1 1 0 01-1.41 1.41L12 13.41l-2.83 2.83a1 1 0 01-1.41-1.41L10.59 12 7.76 9.17a1 1 0 011.41-1.41L12 10.59l2.83-2.83a1 1 0 011.41 1.41L13.41 12l2.83 2.83z"></path>
       </svg>
      </button>
     </div>
    ) : null}
   </div>
  </div>
 );
}

import React from "react";

import { langCodes } from "./utils/languageCodes";
import { t } from "../i18n/language";

export default function Select({ selected, onSelect, disabled }) {
  // const [value, setValue] = useState();

  //  const handleSelect = choice => {
  //   // console.log(choice);
  //   if (choice === "approve") {
  //    setValue("approved");
  //    onSelect("approved");

  //    // TO DO
  //    // SEND OFF A TRIGGER TO PUBLISH THE SELECTED TRACK
  //   } else if (choice === "approved") {
  //    setValue("approved");

  //    // TO DO
  //    // SEND OFF A TRIGGER TO PUBLISH THE SELECTED TRACK
  //   } else if (choice === "processing") {
  //    setValue("processing");
  //   } else {
  //    setValue("draft");
  //    //  onSelect("draft");

  //    // TO DO
  //    // SEND OFF A TRIGGER TO UNPUBLISH TO SELECTED TRACK
  //   }
  //  };

  let extraClasses;
  if (selected === "processing") {
    extraClasses =
      "bg-white text-gray-400 border border-gray-200 cursor-not-allowed";
  } else if (selected === "approved") {
    extraClasses = "text-green-900 bg-green-200 border-none";
  } else {
    extraClasses = "bg-white border border-gray-200";
  }

  //  useEffect(() => {
  //   handleSelect(selected);
  //   return () => {
  //    setValue("");
  //   };
  //  }, [selected]);

  return (
    <select
      className={`pl-2 w-full h-10 rounded ${extraClasses}`}
      name=""
      id=""
      disabled={selected === "processing" ? true : false || disabled}
      value={selected}
      onChange={(e) => onSelect(e.target.value)}
    >
      <option disabled value="">
        {t("Choose")}...
      </option>
      <option value="approved">{t("Approved")}</option>

      <option value="draft">{t("Draft")}</option>

      <option value="disabled">{t("Inactive")}</option>

      {/* HIDDEN OPTIONS */}
      {/* <option hidden value="approved">
    Approved
   </option> */}

      <option hidden value="processing">
        {t("Processing")}...
      </option>

      <option hidden value="updating">
        {t("Updating")}...
      </option>

      <option hidden value="draft">
        {t("Draft")}
      </option>
    </select>
  );
}

const DefaultSelect = ({ selected = "", children }) => {
  return (
    <select
      className={`pl-2 w-full h-10 rounded bg-white border border-grey-200`}
      name=""
      id=""
      //  disabled={selected === "processing" ? true : false}
      //  value={selected}
      onChange={(e) => {
        return selected(e.target.value);
      }}
    >
      <option value="">{t("Choose")}...</option>
      {children}
    </select>
  );
};

// const langCodesShort = [
//  {
//   code: "en",
//   label: "English"
//  },
//  {
//   code: "ga",
//   label: "Irish"
//  },
//  {
//   code: "sv",
//   label: "Swedish"
//  }
// ];

export function SelectLanguage({ selected }) {
  return (
    <DefaultSelect selected={selected}>
      <option value={JSON.stringify(langCodes[30])}>
        {langCodes[30].label} ({langCodes[30].code.toUpperCase()})
      </option>
      <option value={JSON.stringify(langCodes[103])}>
        {langCodes[103].label} ({langCodes[103].code.toUpperCase()})
      </option>
      <option value="">- - - - - - - - -</option>
      {langCodes.map((language) => {
        return (
          <option key={language.code} value={JSON.stringify(language)}>
            {language.label} ({language.code.toUpperCase()})
          </option>
        );
      })}
    </DefaultSelect>
  );
}

const trackTypes = [
  {
    type: "subtitles",
    label: "Subtitles",
  },
  {
    type: "captions",
    label: "Captions",
  },
  {
    type: "chapters",
    label: "Chapters",
  },
  {
    type: "descriptive",
    label: "Descriptive",
  },
];

export function SelectTrackType({ selected }) {
  return (
    <DefaultSelect selected={selected}>
      {trackTypes.map((track) => {
        return (
          <option key={track.type} value={track.type}>
            {t(track.label)}
          </option>
        );
      })}
    </DefaultSelect>
  );
}

// const audio_track_types = [
//  { type: "alternate", label: "Alternate" },
//  { type: "dub", label: "Dub" },
//  { type: "commentary", label: "Commentary" },
//  { type: "descriptive", label: "Descriptive" }
// ];

// export function SelectAudioTrackType({ selected }) {
//  return (
//   <DefaultSelect selected={selected}>
//    {audio_track_types.map(track => {
//     return (
//      <option key={track.type} value={track.type}>
//       {track.label}
//      </option>
//     );
//    })}
//   </DefaultSelect>
//  );
// }

import React, { Fragment } from "react";

import { t } from "../../i18n/language";
import { Link } from "react-router-dom";

const updates = [
 {
  headline: t("New language: Swedish"),
  body: t(
   "The Equal Play dashboard now supports Swedish, head over to your account settings to change language."
  ),
  linkText: t("Try it now"),
  link: "/settings/language",
 },
 {
  headline: t("Import tracks from Brightcove"),
  body: t(
   "You can now import text tracks from your Brightcove account. Simply use the 'Import' option in the transcript editor."
  ),
 },
];

export default function SidebarUpdates() {
 return (
  <Fragment>
   <div className="flex flex-col py-2 pb-4">
    {updates.map((update) => {
     return (
      <div className="mb-3 " key={update.headline}>
       <h3 className="py-2 text text-gray-700 text font-bold">
        {update.headline}
       </h3>
       <span className="mt-2 text-sm text-gray-600">
        {update.body}{" "}
        {update.link && (
         <Link
          to={update.link}
          className="text-gray-800 border-b border-gray-200 hover:border-gray-500 focus:border-gray-500"
         >
          {update.linkText}
         </Link>
        )}
       </span>
      </div>
     );
    })}
   </div>
  </Fragment>
 );
}

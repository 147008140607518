import { db, functionBaseUrl } from "../auth/firebase";
import { requestToken } from "./brightcoveRequest";
import axios from "axios";

// https://firebase.google.com/docs/firestore/security/get-started
// allow create: if get(/databases/$(database)/documents/users/$(request.auth.uid)).data.is_manager == true;
// allow read, update, write, create: if get(/databases/$(database)/documents/users/$(request.auth.uid)).data.is_manager == true;
// match /bc_accounts/{bc_account}
// match /orgs/{orgId}
// match /users/{user}

/** Get all clients from the "orgs" collection */
const listClients = async () => {
  const clients = await db
    .collection("orgs")
    .get()
    .then((response) => {
      if (response.empty) {
        console.warn("No Accounts at all, weird...");
        return [];
      }

      const orgs = [];

      response.docs.forEach((org, index) => {
        const data = org.data();
        if (!data.org_id) {
          data.org_id = org.id;
        }
        orgs.push(data);
      });

      return orgs;
    })
    .catch((err) => {
      // console.log("Error getting orgs", err);
      throw err;
    });
  return clients;
};

/** Creates a client in the "orgs" collection */
const createClient = async ({ name, user_limit }) => {
  // console.log("Add client", name, user_limit);
  const client = await db
    .collection("orgs")
    .add({ name, user_limit, account_type: "addon" })
    .then(async (docRef) => {
      await docRef.set({ org_id: docRef.id }, { merge: true });
      return docRef.id;
    })
    .catch((err) => {
      throw err;
    });
  return client;
};

/** Get all users from "users" collection */
const listUsers = async () => {
  const users = await db
    .collection("users")
    .get()
    .then((response) => {
      if (response.empty) {
        console.warn("No users at all, weird...");
        return [];
      }

      const orgs = [];

      response.docs.forEach((org, index) => {
        const data = org.data();
        orgs.push(data);
      });

      return orgs;
    })
    .catch((err) => {
      throw err;
    });
  return users;
};

/** Get a single user */
const getUser = async (uid) => {
  return await db
    .collection("users")
    .doc(uid)
    .get()
    .then((response) => {
      if (!response.exists) {
        console.warn("No users at all, weird...");
        return {};
      }
      return response.data();
    })
    .catch((err) => {
      throw err;
    });
};

/** Merges new userdata */
const updateUser = async (user) => {
  const { uid } = user;
  return await db
    .collection("users")
    .doc(uid)
    .set(user, { merge: true })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      throw err;
    });
};

/** List registered BC accounts, perhaps sanitize the response a bit here */
const listBcAccounts = async () => {
  const accounts = await db
    .collection("bc_accounts")
    .get()
    .then((response) => {
      if (response.empty) {
        console.warn("No Accounts at all, weird...");
        return [];
      }

      const orgs = [];

      response.docs.forEach((org, index) => {
        const data = org.data();
        if (!data.account_id) {
          // console.log("no id", org);
          data.account_id = org.id;
        }
        orgs.push(data);
      });

      return orgs;
    })
    .catch((err) => {
      // console.log("Error getting orgs", err);
      throw err;
    });
  return accounts;
};

/** Get a single brightcove account */
const getBcAccount = async (account_id) => {
  return await db
    .collection("bc_accounts")
    .doc(account_id)
    .get({ source: "server" })
    .then((account) => {
      const data = account.data();
      if (!data.account_id) {
        data.account_id = account.id;
      }
      return data;
    })
    .catch((err) => {
      throw err;
    });
};

/** Tests the connection to the BC account */
const verifyBcAccount = async ({ account_id, client_secret, client_id }) => {
  // console.log("Verify BC account", account_id, client_id, client_secret);
  // What do we need to verify an account...
  // * First we need to see if we can get a token.

  const defaultCustomField = {
    description:
      "Stores information for the Equal Play Accessibility Integration. Do no edit this field from Brightcove directly, it updates from https://dashboard.equalplay.eu",
    display_name: "Equal Play",
    id: "equalplay",
    required: false,
    type: "string",
  };

  const getToken = async () => {
    const query = `?client_id=${client_id}&client_secret=${client_secret}`;
    const url = `${functionBaseUrl}/verifyBcAccount${query}`;

    const response = await axios({
      method: "GET",
      url,
    }).catch((err) => {
      if (err.response) {
        throw new Error(err.response.data);
      } else if (err.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(err.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", err.message);
      }
    });

    return response.data;
  };

  const getCustomFields = async (token) => {
    // console.log("Get custom fields with:", token);
    const response = await axios({
      method: "GET",
      url: `https://cms.api.brightcove.com/v1/accounts/${account_id}/video_fields/custom_fields`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }).catch((err) => {
      if (err.response) {
        // console.log(err.response);
        throw new Error(`${err.response.status} / ${err.response.statusText} `);
      } else if (err.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(err.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", err.message);
      }
    });

    return response.data;
  };

  /** PATCH:es the customfield with standard values */
  const updateCustomField = async (token) => {
    // /v1/accounts/{account_id}/video_fields/custom_fields/{custom_field_id}
    const response = await axios({
      method: "PATCH",
      url: `https://cms.api.brightcove.com/v1/accounts/${account_id}/video_fields/custom_fields/${defaultCustomField.id}`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        id: defaultCustomField.id,
        display_name: defaultCustomField.display_name,
        description: defaultCustomField.description,
      },
    }).catch((err) => {
      if (err.response) {
        throw new Error(err.response.data);
      } else if (err.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(err.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", err.message);
      }
    });

    return response.data;
  };

  /** Create custom field on brightcove account, DOES NOT WORK! Create manually
   *  https://apis.support.brightcove.com/cms/references/reference.html#tag/Custom-Fields/operation/CreateCustomField
   */
  const createCustomField = async (token) => {
    ///v1/accounts/{account_id}/video_fields/custom_fields
    const response = await axios({
      method: "POST",
      url: `https://cms.api.brightcove.com/v1/accounts/${account_id}/video_fields/custom_fields`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: defaultCustomField,
    }).catch((err) => {
      if (err.response) {
        throw new Error(err.response.data);
      } else if (err.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(err.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", err.message);
      }
    });

    return response.data;
  };

  let token;

  // test a token
  try {
    token = await getToken();
    token.currentTokenExpires = Date.now() + token.expires_in * 1000;
  } catch (err) {
    console.log("Catch error getting token", err.message);
    return [`Could not verify account ${account_id}:${err.message}`];
  }

  // use the token
  try {
    const customFields = await getCustomFields(token.access_token);

    // If we get custom fields here, the account, token and secret works
    const eqpField = customFields.filter((field) => field.id === "equalplay");

    if (eqpField.length === 0) {
      // create custom field
      console.info("No equal play field");
      await createCustomField();
    } else if (eqpField[0].description !== defaultCustomField.description) {
      // console.info("Descriptions dont match", eqpField[0]);
      await updateCustomField(token.access_token);
    }

    console.log(customFields);
  } catch (err) {
    // const parsedError = JSON.parse(err);
    // const message = `${parsedError.error_code} / ${parsedError.message}`;
    console.log("Error: ", err);
    //console.log("Error:", message);

    return [`Error getting custom fields: ${err}`];
  }

  return [
    null,
    {
      account_id,
      client_id,
      client_secret,
      currentToken: token.access_token,
      currentTokenExpires: token.currentTokenExpires,
      tokenType: token.token_type,
    },
  ];
};

/** Merges data with bc_account, account.account_id needed! */
const saveBcAccount = async (account) => {
  const { account_id } = account;
  return await db
    .collection("bc_accounts")
    .doc(account_id)
    .set(account, { merge: true })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      throw err;
    });
};

const getBcAccountPlayers = async (account) => {
  console.log("Getting players for:", account);
  let { account_id, currentToken, currentTokenExpires } = account;

  if (currentTokenExpires < new Date().getTime()) {
    console.log("Token has expired", currentTokenExpires);
    await requestToken(account_id);
    const newAccount = await getBcAccount(account_id);
    currentToken = newAccount.currentToken;
    currentTokenExpires = newAccount.currentTokenExpires;
    console.log(
      "New token:",
      currentTokenExpires,
      newAccount.currentTokenExpires
    );
  }

  const url = `https://players.api.brightcove.com/v2/accounts/${account_id}/players`;

  const players = await axios({
    method: "GET",
    url,
    headers: { Authorization: `Bearer ${currentToken}` },
  });

  //return players;
  return { players: players.data, currentToken, currentTokenExpires };
};

/** Currently hardcoded to 1.1.19 */
const getLatestBCPlugin = async () => {
  return {
    name: "equalplay",
    version: "1.1.19",
    scripts: ["https://storage.googleapis.com/bcplugin/equalplay_1.1.19.js"],
    stylesheets: [
      "https://storage.googleapis.com/bcplugin/equalplay_1.1.19.css",
    ],
  };
};

/** Add a plugin to the collection "plugin" */
const addPlugin = async (pluginDetails) => {
  const { ver } = pluginDetails;
  return await db
    .collection("plugins")
    .doc(ver)
    .set(pluginDetails, { merge: true })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      throw err;
    });
};

/** Get an array of plugins */
const getPlugins = async () => {
  const plugins = await db
    .collection("plugins")
    .orderBy("date", "desc")
    .get()
    .then((response) => {
      if (response.empty) {
        console.warn("No plugins at all, weird...");
        return [];
      }

      const plugins = [];

      response.docs.forEach((plugin, index) => {
        const data = plugin.data();
        plugins.push(data);
      });

      return plugins;
    })
    .catch((err) => {
      throw err;
    });
  return plugins;
};

const getLatestPlugin = async () => {
  const plugin = await db
    .collection("plugins")
    .orderBy("date", "desc")
    .limit(1)
    .get()
    .then((response) => {
      if (response.empty) {
        console.warn("No plugins at all, weird...");
        return {};
      }

      let plug;
      response.docs.forEach((plugin, index) => {
        plug = plugin.data();
      });

      return plug;
    })
    .catch((err) => {
      throw err;
    });

  return plugin;
};

const updateBCPlayer = async (account, player_id, configuration) => {
  let { account_id } = account;

  const url = `${functionBaseUrl}updateBCPlayer`;

  const update = await axios({
    method: "POST",
    url,
    headers: {
      "Content-Type": "application/json",
    },
    data: { account_id, player_id, configuration },
  });

  return update;
};

export {
  listClients,
  createClient,
  listUsers,
  getUser,
  updateUser,
  listBcAccounts,
  getBcAccount,
  verifyBcAccount,
  saveBcAccount,
  getBcAccountPlayers,
  getLatestBCPlugin,
  addPlugin,
  getPlugins,
  getLatestPlugin,
  updateBCPlayer,
};

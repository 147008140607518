import axios from "axios";

import store, { functionBaseUrl } from "../auth/firebase";
import emptyCustomField from "./customField";
import { addNotification } from "../Notification";
import { t } from "../../i18n/language";

// const sleep = (m) => new Promise((r) => setTimeout(r, m));

/** Requests a new token */
export const requestToken = async (account_id) => {
  let startTime = performance.now();

  const url = `${functionBaseUrl}token?account=${account_id}`;

  // const url =
  //   process.env.REACT_APP_ENV === "production"
  //     ? `https://europe-west1-equal-play.cloudfunctions.net/token?account=${account_id}`
  //     : `https://europe-west1-wallen-media-portal.cloudfunctions.net/token?account=${account_id}`;

  return axios({
    method: "post",
    url,
  })
    .then((res) => {
      let t1 = performance.now();
      const { data, status } = res;
      return { data, status, time: Math.round(t1 - startTime) };
    })
    .catch((err) => {
      throw err;
    });
};

export const refreshToken = async (account_id) => {
  // the post below only answers that there is a new token in the DB.

  let t0 = performance.now();

  const currentToken = store.getState().user.token;

  const currentTokenTimeLeft =
    (currentToken.currentTokenExpires - Date.now()) / 1000;

  if (currentTokenTimeLeft > 10) return currentToken;
  // TO DO
  // Fix below to remove sleeper function and do it right
  const url = `${functionBaseUrl}token?account=${account_id}`;

  // let url =
  //   process.env.REACT_APP_ENV === "production"
  //     ? `https://europe-west1-equal-play.cloudfunctions.net/token?account=${account_id}`
  //     : `https://europe-west1-wallen-media-portal.cloudfunctions.net/token?account=${account_id}`;
  // let url = `https://europe-west1-equal-play.cloudfunctions.net/token?account=${account_id}`;
  await axios({
    method: "post",
    url,
  })
    .then((res) => {
      let t1 = performance.now();
      console.log(`Updated token in ${Math.round(t1 - t0)}ms`);
      return res;
    })
    .catch((err) => {
      console.log("Error refreshing token:", err);
    });

  function select(state) {
    //return state.firestore.data.bc_accounts[account_id].currentToken;
    return state.user.token.currentToken;
  }

  const johnProm = () => {
    return new Promise((resolve, reject) => {
      // console.info("Subscribe to store change...");
      // console.log("Current state:", store.getState());
      // let currentValue = select(store.getState());
      let currentValue = currentToken.currentToken;
      let unsubscribe;
      const handleChange = () => {
        let previousValue = currentValue;
        currentValue = select(store.getState());
        if (previousValue !== currentValue) {
          // console.log(
          //   "Some deep nested property changed from",
          //   previousValue,
          //   "to",
          //   currentValue
          // );
          unsubscribe();
          resolve();
        }
      };

      unsubscribe = store.subscribe(handleChange);
    });
  };

  await johnProm();

  const t2 = performance.now();
  console.log("Complete time...", t2 - t0);

  // return await store.getState().firestore.data.bc_accounts[account_id];
  return store.getState().user.token;
};

// /**
//  * Get a BC token and token type based on an account.
//  * @function bcToken
//  */
// const bcToken = async () => {
//  // check if current token is expired

//  // if not, run request with token

//  // if so, get new token then check for new token

//  // then run request

//  try {
//   const token = await axios({
//    method: "post",
//    url: "http://stream.wallenmedia.se/lab/api/auth.php",
//    headers: {
//     "WALLEN-MEDIA": "hilda"
//    }
//   });

//   return token;
//  } catch (error) {
//   console.error("bcToken", error);
//  }
// };

/**
 * Check the BC CMS API for videos in a given account .
 * @function bcCMSVideos
 * @param {string} account - BC account of the current user.
 * @param {string} searchQuery - The search string.
 * @param {string} sortOrder - How the videos should be sorted.
 * @param {string} tags - What tags to filter by. Should be '' or 'internal,this,that'
 * @param {number} currentPage - Offset for pagination
 */
export async function bcCMSVideos(
  account,
  searchQuery,
  sortOrder,
  tags = [],
  currentPage = 0
) {
  const { account_id } = account;

  const tagsString = tags
    .filter((tag) => {
      if (tag.default_checked === true) {
        return tag;
      } else return null;
    })
    .map((tag) => `${tag.name.toLowerCase().replace(" ", "%20")}`);

  try {
    // const token = await bcToken();
    const token = await refreshToken(account_id);

    const totalVideoCount = await axios({
      method: "get",
      url: `https://cms.api.brightcove.com/v1/accounts/${account_id}/counts/videos?q=${searchQuery}${
        tagsString.length !== 0 ? `%20tags:${tagsString.toString()}` : "%20"
      }%20-tags:equalplay-alpha&sort=${sortOrder}`,
      headers: {
        Authorization: `Bearer ${token.currentToken}`,
        "Content-Type": "application/json",
      },
    });

    const results = await axios({
      method: "get",
      url: `https://cms.api.brightcove.com/v1/accounts/${account_id}/videos?limit=10&q=${searchQuery}${
        tagsString.length !== 0 ? `%20tags:${tagsString.toString()}` : "%20"
      }%20-tags:equalplay-alpha&sort=${sortOrder}&offset=${currentPage * 10}`,
      headers: {
        Authorization: `Bearer ${token.currentToken}`,
        "Content-Type": "application/json",
      },
    });

    if (results.data.length > 0) {
      results.data.map((video) => {
        let id = video.id;
        return store.dispatch({
          type: "ADD_VIDEO",
          video: { [id]: { ...video } },
        });
      });

      return { results, totalVideoCount };
    } else {
      return { results: { data: "no results found" }, totalVideoCount };
    }
  } catch (error) {
    console.error("bcCMSVideos", error);
  }
}

/**
 * Check the BC CMS API for a single video based on its ID.
 * @function bcCMSVideo
 * @param {string} account - BC account of the current user.
 * @param {string} videoId - The search string.
 */
export async function bcCMSVideo(account, videoId) {
  const { account_id } = account;

  try {
    const token = await refreshToken(account_id);

    const result = await axios({
      method: "get",
      url: `https://cms.api.brightcove.com/v1/accounts/${account_id}/videos/${videoId}`,
      headers: {
        Authorization: `Bearer ${token.currentToken}`,
        "Content-Type": "application/json",
      },
    });

    if (result.data) {
      // result.data.map((video) => {
      // 	let id = video.id;
      store.dispatch({
        type: "ADD_VIDEO",
        video: { [result.data.id]: { ...result.data } },
      });
      // });

      return { data: [{ ...result.data }] };
    } else {
      return { data: "no results found" };
    }
  } catch (error) {
    console.error(error);
  }
}

/**
 * POST the BC CMS API to create a video.
 * @function bcCMSCreateVideo
 * @param {string} account - BC account of the current user.
 * @param {string} name - Name of the video to create.
 * @param {string} object - Other settings to add.
 */
export async function bcCMSCreateVideo(
  account,
  name = "Untitled video created by Equal Play",
  object = {}
) {
  const { account_id } = account;
  const data = JSON.stringify({ name, ...object });

  try {
    const token = await refreshToken(account_id);

    const result = await axios({
      method: "post",
      url: `https://cms.api.brightcove.com/v1/accounts/${account_id}/videos`,
      headers: {
        Authorization: `Bearer ${token.currentToken}`,
        "Content-Type": "application/json",
      },
      data,
    });

    return result;
  } catch (error) {
    console.error(error);
  }
}

/**
 * POST BC to ingest a given file and return the status.
 * @function bcIngest
 * @param {string} account - BC account of the current user.
 * @param {string} videoId - BC video ID of the requesting video.
 * @param {object} object - Ingest object for the API.
 */
export async function bcIngest(account, videoId, object) {
  const { account_id } = account;
  const data = JSON.stringify(object);

  const token = await refreshToken(account_id);

  const request = await axios({
    method: "post",
    url: `https://ingest.api.brightcove.com/v1/accounts/${account_id}/videos/${videoId}/ingest-requests`,
    headers: {
      Authorization: `Bearer ${token.currentToken}`,
      "Content-Type": "application/json",
    },
    data,
  })
    .then((res) => {
      return { data: res.data, status: res.status, statusText: res.statusText };
    })
    .catch((err) => {
      return { status: err.status, statusText: err.statusText };
    });

  return request;
}

/**
 * DELETE audio track on a given video.
 * @function bcDeleteAudioTrack
 * {@link https://support.brightcove.com/implementing-multiple-audio-tracks-using-apis#Managing_audio_tracks Brightcove}.
 * @param {string} account - BC account of the current user.
 * @param {string} videoId - BC video ID of the requesting video.
 * @param {string} trackId - Track ID for the track to delete - Formed as language_variant e.g. "en_descriptive".
 */
export async function bcDeleteAudioTrack(account, videoId, trackId) {
  const { account_id } = account;

  const token = await refreshToken(account_id);

  const request = await axios({
    method: "delete",
    url: `https://cms.api.brightcove.com/v1/accounts/${account_id}/videos/${videoId}/audio_tracks/${trackId}`,
    headers: {
      Authorization: `Bearer ${token.currentToken}`,
      "Content-Type": "application/json",
    },
  })
    .then((res) => {
      return { data: res.data, status: res.status, statusText: res.statusText };
    })
    .catch((err) => {
      return { status: err.status, statusText: err.statusText };
    });

  return request;
}

/**
 * ADD text track on a given video.
 * @function bcAddTextTrack
 * {@link https://support.brightcove.com/using-cms-api-add-webvtt-captions-text-tracks Brightcove}.
 * @param {string} account - BC account of the current user.
 * @param {string} videoId - BC video ID of the requesting video.
 * @param {object[]} track - Track object to add.
 * @param {string} track[].url - URL to ingest
 * @param {string} track[].srclang - Lang short code of track
 * @param {string} track[].kind - Kind: 'subtitles', 'captions', 'descriptions', 'chapters'
 * @param {string} track[].label - Label of the track E.g. 'English'
 * @param {boolean} track[].default - Should this track be the default
 * @param {boolean} remove - Should this track be removed from BC and not replaced
 */
export function bcAddTextTrack(account, videoId, track, remove) {
  const { account_id } = account;

  const request = bcCMSVideo(account, videoId)
    .then((res) => {
      return res.data[0].text_tracks || [];
    })
    .then((existingTracks) => {
      let filtered = [];

      //  console.log("existingTracks", existingTracks);

      if (existingTracks.length > 0) {
        filtered = existingTracks.filter((t) => {
          if (t.srclang !== track.srclang) {
            return {
              url: t.url,
              srclang: t.srclang,
              kind: t.kind,
              label: t.label,
              default: t.default,
            };
          }

          if (t.kind !== track.kind) {
            return {
              url: t.url,
              srclang: t.srclang,
              kind: t.kind,
              label: t.label,
              default: t.default,
            };
          }
        });
      }
      return filtered;
    })
    .then((res) => {
      //  console.log("filtered", res);
      const data = {
        text_tracks: [...res],
      };

      const request = refreshToken(account_id).then((res) => {
        return axios({
          method: "patch",
          url: `https://cms.api.brightcove.com/v1/accounts/${account_id}/videos/${videoId}`,
          headers: {
            Authorization: `Bearer ${res.currentToken}`,
            "Content-Type": "application/json",
          },
          data: JSON.stringify(data),
        })
          .then((res) => {
            // console.log(res);
            if ((res.status === 202 || res.status === 200) && !remove) {
              // ADD NEW TEXT TRACK TO VIDEO VIA INGEST

              refreshToken(account_id).then((res) => {
                return axios({
                  method: "post",
                  url: `https://ingest.api.brightcove.com/v1/accounts/${account_id}/videos/${videoId}/ingest-requests`,
                  headers: {
                    Authorization: `Bearer ${res.currentToken}`,
                    "Content-Type": "application/json",
                  },
                  data: JSON.stringify({
                    text_tracks: [{ ...track }],
                  }),
                })
                  .then((res) => {
                    // console.log(res);
                    return {
                      data: res.data,
                      status: res.status,
                      statusText: res.statusText,
                    };
                  })
                  .catch((err) => {
                    return { status: "error", statusText: err.statusText };
                  });
              });
            } else return;
          })
          .catch((err) => {
            console.log("err", err);
            // return { status: "error", statusText: err.statusText };
          });
      });

      return request;
    });

  return request;
}

// /**
//  * ADD text track on a given video.
//  * @function bcAddTextTrack
//  * {@link https://support.brightcove.com/using-cms-api-add-webvtt-captions-text-tracks Brightcove}.
//  * @param {string} account - BC account of the current user.
//  * @param {string} videoId - BC video ID of the requesting video.
//  * @param {object[]} track - Track object to add.
//  * @param {string} track[].url - URL to ingest
//  * @param {string} track[].srclang - Lang short code of track
//  * @param {string} track[].kind - Kind: 'subtitles', 'captions', 'descriptive', 'chapters'
//  * @param {string} track[].label - Label of the track E.g. 'English'
//  * @param {boolean} track[].default - Should this track be the default
//  */
// export function bcAddTextTrack(account, videoId, track) {
//   const { account_id } = account;

//   const body = {
//    text_tracks: [{ ...track }]
//   };

// const request = bcToken().then(res => {
//  return axios({
//   method: "post",
//   url: `https://ingest.api.brightcove.com/v1/accounts/${account_id}/videos/${videoId}/ingest-requests`,
//   headers: {
//    Authorization: `${res.data.token_type} ${res.data.access_token}`,
//    "Content-Type": "application/json"
//   },
//   data: JSON.stringify(body)
//  })
//   .then(res => {
//    return { data: res.data, status: res.status, statusText: res.statusText };
//   })
//   .catch(err => {
//    return { status: "error", statusText: err.statusText };
//   });
// });

//   return request;
//  }

/**
 * PATCH request to update the Equal Play custom field for a given video.
 * @function bcCustomFieldUpdate
 * @param {object} account - BC account of the current user.
 * @param {string} videoId - Video ID to update.
 * @param {string} type - Type of update: 'sign' 'audio_description' 'transcript'.
 * @param {object[]} updateObject - Update to the object. Leave blank for
 * @param {string} updateObject[].lang - Language
 * @param {string} updateObject[].label - Label
 * @param {string} updateObject[].status - Status: "draft, processing, uploaded, approved, disabled"
 * @param {boolean} remove - Do you want to remove this object? Default: false
 * @param {boolean} notify - Should the user get a notifciation that a change has been made? Default: true
 */
export function bcCustomFieldUpdate(
  account,
  videoId,
  type,
  updateObject,
  remove = false,
  notify = true
) {
  const { account_id } = account;

  // CHECK IF EMPTY OBJECT
  // IF EMPTY, ADD ONE
  // if not, just add the object that came in

  const existingCustomField = bcCMSVideo(account, videoId).then((res) => {
    if (res.data[0].custom_fields.equalplay === undefined) {
      return emptyCustomField;
    } else {
      return JSON.parse(res.data[0].custom_fields.equalplay);
    }
  });

  return existingCustomField
    .then((res) => {
      // IF object exists, filter it and change it
      const filtered = res[type].filter(
        (element) => element.lang === updateObject.lang
      );
      const remaining = res[type].filter(
        (element) => element.lang !== updateObject.lang
      );
      let equalplay;
      if (filtered.length > 0) {
        if (remove === true) {
          equalplay = {
            ...res,
            [type]: [...remaining],
          };
        } else {
          equalplay = {
            ...res,
            [type]: [
              ...remaining,
              { ...filtered[0], status: updateObject.status },
            ],
          };
        }
      } else {
        equalplay = { ...res, [type]: [...res[type], { ...updateObject }] };
      }

      // continue if it doesn't

      //  const equalplay = { ...res, [type]: [...res[type], { ...updateObject }] };
      const equalplayString = JSON.stringify(equalplay);
      const data = {
        custom_fields: {
          equalplay: equalplayString,
        },
      };

      store.dispatch({
        type: "UPDATE_STATUS",
        video: videoId,
        equalplay: equalplayString,
      });

      const request = refreshToken(account_id).then((res) => {
        return axios({
          method: "patch",
          url: `https://cms.api.brightcove.com/v1/accounts/${account_id}/videos/${videoId}`,
          headers: {
            Authorization: `Bearer ${res.currentToken}`,
            "Content-Type": "application/json",
          },
          data,
        })
          .then((res) => {
            return {
              data: res.data,
              status: res.status,
              statusText: res.statusText,
            };
          })
          .catch((err) => console.error(err));
      });

      return request;
    })
    .then((res) => {
      if (notify) {
        return addNotification(
          t("Changes updated!"),
          t(
            "It can take a while to see these changes in your Brightcove account."
          ),
          undefined
        );
      } else {
        return console.log("Changes updated");
      }
    })
    .catch((err) => {
      console.error(err);
      addNotification(
        t("Oops!"),
        t("Looks like that didn't work. Please try again."),
        "bg-red-600"
      );
    });
}

/**
 * Return the video sources for the given video ID.
 * @function bcVideoSources
 * @param {string} account - BC account of the current user.
 */
export async function bcVideoSources(account, video_id) {
  const { account_id } = account;

  try {
    const token = await refreshToken(account_id);

    const results = await axios({
      method: "get",
      url: `https://cms.api.brightcove.com/v1/accounts/${account_id}/videos/${video_id}/sources`,
      headers: {
        Authorization: `Bearer ${token.currentToken}`,
        "Content-Type": "application/json",
      },
    });

    return results;
  } catch (error) {
    return new Error(error);
  }
}

// We need to update theese functions, I have no idea of the logic in them
// https://apis.support.brightcove.com/dynamic-ingest/index.html
// https://apis.support.brightcove.com/dynamic-ingest/ingest-guides/ingesting-webvtt-files-text-trackscaptions.html#add_to_existing_vide

/** Get a single video from Brightcove CMS Api
 * @param {string} accountId - BC account Id.
 * @param {string} videoId - BC video ID of the requesting video.
 */
const getBcVideo = async ({ accountId, videoId }) => {
  try {
    const token = await refreshToken(accountId);
    const result = await axios({
      method: "GET",
      url: `https://cms.api.brightcove.com/v1/accounts/${accountId}/videos/${videoId}`,
      headers: {
        Authorization: `Bearer ${token.currentToken}`,
        "Content-Type": "application/json",
      },
    });

    if (result.status === 200 && result.data) {
      store.dispatch({
        type: "ADD_VIDEO",
        video: { [result.data.id]: { ...result.data } },
      });

      return result.data;
    } else {
      return null;
    }
  } catch (error) {
    throw error;
  }
};

/** Set the entire equalplay custom field */
const setBcVideoEqpField = async ({ accountId, videoId, eqpField }) => {
  const equalplayString = JSON.stringify(eqpField);
  const data = {
    custom_fields: {
      equalplay: equalplayString,
    },
  };

  // Not sure what this does yet...
  store.dispatch({
    type: "UPDATE_STATUS",
    video: videoId,
    equalplay: equalplayString,
  });

  const token = await refreshToken(accountId);

  const patch = await axios({
    method: "patch",
    url: `https://cms.api.brightcove.com/v1/accounts/${accountId}/videos/${videoId}`,
    headers: {
      Authorization: `Bearer ${token.currentToken}`,
      "Content-Type": "application/json",
    },
    data,
  })
    .then((res) => {
      return {
        data: res.data,
        status: res.status,
        statusText: res.statusText,
      };
    })
    .catch((err) => {
      throw err;
    });

  if (patch.status === 200) {
    return addNotification(
      t("Changes updated!"),
      t("It can take a while to see these changes in your Brightcove account."),
      undefined
    );
  } else {
    console.log(patch);
    return addNotification(
      t("Oops!"),
      t("Looks like that didn't work. Please try again."),
      "bg-red-600"
    );
  }
};

export { getBcVideo, setBcVideoEqpField };

import React, { Fragment, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import Navbar from "./Navbar";
import TwoColWithSidebar, { Sidebar, Main } from "./layouts/TwoColWithSidebar";
import SidebarUpdates from "./layouts/SidebarUpdates";
import SidebarSection from "./layouts/SidebarSection";
import SidebarHeader from "./layouts/SidebarHeader";
import Table from "./table/Table";
import Button from "./Button";
import Notification from "./Notification";

import { bcCMSVideos } from "./utils/brightcoveRequest";
import { t } from "../i18n/language";

// import { bcQuery } from "../samples/bcQuery";

export default function Dashboard() {
 const [bcQuery, setBcQuery] = useState([]);

 const account = useSelector((state) => state.user.selectedAccount);
 const recent_projects = useSelector((state) => state.user.recent_projects);

 // CHECK USERS RECENT PROJECTS ARRAY

 useEffect(() => {
  if (account) {
   setBcQuery([]);
   if (recent_projects) {
    if (recent_projects.length === 0) {
     setBcQuery("no results found");
    } else {
     let searchQuery;
     if (recent_projects.length === 1) {
      searchQuery = `${recent_projects[0]}`;
     } else if (recent_projects.length === 2) {
      searchQuery = `${recent_projects[0]} ${recent_projects[1]}`;
     } else {
      searchQuery = `${recent_projects[0]} ${recent_projects[1]}  ${recent_projects[2]}`;
     }

     bcCMSVideos(account, searchQuery, "updated_at").then((res) => {
      if (res !== undefined) {
       setBcQuery(res.results.data);
      } else {
       setBcQuery("no results found");
      }
     });
    }
   } else {
    setBcQuery("no results found");
   }
  }
 }, [account, recent_projects]);
 return (
  <Fragment>
   <Navbar />
   <Notification />
   <TwoColWithSidebar>
    <Sidebar>
     <SidebarSection>
      <SidebarHeader text={t("Updates")} icon="updates" />
      <SidebarUpdates />
     </SidebarSection>
    </Sidebar>
    <Main>
     <h1 className="text-xl font-semibold">{t("Recent projects")}</h1>
     <Table
      bcQuery={bcQuery}
      amount={3}
      noResultsMessage={t("No recent projects.")}
      linkText={t("Start one now...")}
      linkTo={process.env.PUBLIC_URL + "/videos"}
     />
     <div className="mt-4">
      <Link to={process.env.PUBLIC_URL + "/videos"}>
       <Button text={t("See all videos")} colour="white" />
      </Link>
     </div>
    </Main>
   </TwoColWithSidebar>
  </Fragment>
 );
}

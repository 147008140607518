import React, { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { useFirestore } from "react-redux-firebase";

import Button from "../Button";
import Modal from "../Modal";
import FileUploader from "../FileUploader";
import * as editorActions from "../../actions/editor-actions";
import { SelectTrackType } from "../Select";
import Warning from "../Warning";
import { parseVTT } from "../utils/parseVTT";
import { vttBlob } from "../utils/vttBlob";

import BCStatusChange from "../layouts/BCStatusChange";
import {
  bcCustomFieldUpdate,
  bcVideoSources,
} from "../utils/brightcoveRequest";
import BCTextTrackStatus from "../layouts/BCTextTrackStatus";
import { addNotification } from "../Notification";
import { t } from "../../i18n/language";
import { firebaseFunctions } from "../auth/firebase";

export default function TitleAndButtons({ videoId, languagesModal }) {
  const [trackType, setTrackType] = useState("");
  const [importedFile, setImportedFile] = useState();
  const [optionsMenuOpen, setOptionsMenuOpen] = useState(false);
  const [generatingAsr, setGeneratingAsr] = useState(false);
  const [savingText, setSavingText] = useState("Save");

  // MODALS
  const [importModal, setImportModal] = useState(false);
  const [importModalStep, setImportModalStep] = useState(1);
  const [exportModal, setExportModal] = useState(false);
  const [publishModal, setPublishModal] = useState(false);
  const [deleteLangModal, setDeleteLangModal] = useState(false);
  const [deleteTrackModal, setDeleteTrackModal] = useState(false);
  const [asrModal, setAsrModal] = useState(false);

  const firestore = useFirestore();

  const user = useSelector((state) => state.user);
  const account = useSelector((state) => state.user.selectedAccount);
  const video = useSelector((state) => state.videos[videoId]);
  const project = useSelector((state) => state.projects[videoId]);
  const project_user_specific = useSelector(
    (state) => state.projects_user_specific[videoId]
  );

  /** Only enable transcripts if there are any text tracks */
  const enableTranscriptButton = () => {
    if (video && video.text_tracks) {
      let track = video.text_tracks.filter(
        (track) => track.kind === "subtitles" || track.kind === "captions"
      );
      if (track.length > 0) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  };

  const handleSave = async () => {
    setSavingText("Saving...");
    editorActions.saveProject(videoId, true);

    const saveProjectBackup =
      firebaseFunctions.httpsCallable("saveProjectBackup");

    const cleanup = () => {
      setSavingText("Saved");
      addNotification(t("Changes saved"), t("Nice work!"), undefined);

      setTimeout(() => {
        setSavingText("Save");
      }, 3000);
    };

    await saveProjectBackup({ org_id: user.org_id, uid: user.uid, project })
      .then((res) => {
        console.log(res);
        cleanup();
      })
      .catch((err) => {
        console.error(err);
        addNotification(
          t("We couldn't save your changes"),
          t("Please try again"),
          "bg-red-600"
        );

        setSavingText("Save");
      });
  };

  const handleASR = (track, cues) => {
    editorActions.removeTrack(
      videoId,
      project_user_specific.selectedLang,
      track
    );
    editorActions.addTrack(videoId, project_user_specific.selectedLang, track);
    cues.map((cue) =>
      editorActions.addCue(
        videoId,
        { ...cue },
        {
          selectedLang: project_user_specific.selectedLang,
          selectedTrack: track,
          selectedCue: project_user_specific.selectedCue,
        },
        false
      )
    );
    setAsrModal(false);
    editorActions.selectTrack(videoId, track);
    return editorActions.saveProject(videoId);
  };

  const generateASR = () => {
    setGeneratingAsr(true);
    bcVideoSources(account, videoId)
      .then((res) => {
        if (res.status === 200) {
          const filtered = res.data.filter((source) => source.src);

          //Does language have to be full
          // language:project_user_specific.selectedLang === "sv" ? "sv-SE" : "en-US",
          return firestore
            .collection("asr")
            .add({
              bc_id: videoId,
              org_id: user.org_id,
              uid: user.uid,
              language: project_user_specific.selectedLang,
              url: filtered[0].src,
              date: new Date(),
              duration: video.duration,
            })
            .then((res) => {
              setGeneratingAsr(false);
              //  setAsrModal(false);
              editorActions.addAsr(videoId);

              addNotification(
                t("Generating ASR"),
                t("We'll let you know when it's ready"),
                "bg-equalplay-light"
              );
              return editorActions.saveProject(videoId, false);
            });
        } else {
          addNotification(
            t("Something went wrong"),
            t("Please try again in a few moments"),
            "bg-red-600"
          );
          return setGeneratingAsr(false);
        }
      })
      .catch((err) => console.error("There was an error", err));
  };

  const handleImportFile = () => {
    editorActions.addTrack(
      videoId,
      project_user_specific.selectedLang,
      trackType
    );

    importedFile.cues.map((cue) => {
      return editorActions.addCue(
        videoId,
        {
          start: cue.start,
          end: cue.end,
          text: cue.text,
          identifier: cue.identifier,
          styles: cue.styles,
        },
        {
          selectedLang: project_user_specific.selectedLang,
          selectedTrack: trackType,
          selectedCue: project_user_specific.selectedCue,
        },
        false
      );
    });

    editorActions.saveProject(videoId, false);

    setTrackType("");
    setImportModalStep(1);
    setImportedFile();

    return setImportModal(false);
  };

  const handleExportFile = (cues, kind) => {
    let blob = vttBlob(cues, kind, project_user_specific.selectedLang);

    if (blob === null) {
      return console.log("Issue to fix");
    }

    let a = document.createElement("a");
    a.href = blob.href;
    a.download =
      videoId + "-" + project_user_specific.selectedLang + "-" + kind + ".vtt";
    a.click();
  };

  return (
    <div className="flex justify-between items-center">
      <h1 className="font-semibold text-gray-900">
        {video ? (
          video.name
        ) : (
          <span className="bg-gray-200 text-gray-200 rounded-sm">
            Video title loading right now...
          </span>
        )}
      </h1>
      <div className="flex">
        <Button
          text={`${t("Language")}${
            project &&
            project_user_specific &&
            project_user_specific.selectedLang
              ? `: ${
                  project[project_user_specific.selectedLang].label
                } (${project_user_specific.selectedLang.toUpperCase()})`
              : `: ${t("Not selected")}`
          }`}
          size="small"
          iconBefore="translate"
          onClick={() => {
            languagesModal(true);
          }}
        />

        {/* Select language for other options */}
        {project &&
        project_user_specific &&
        project_user_specific.selectedLang ? (
          <Fragment>
            <div className="ml-3">
              <Button
                text="ASR"
                tooltip={t("Automatic Speech Recognition (ASR)")}
                colour="blue"
                size="small"
                iconBefore="sparkles"
                onClick={() => setAsrModal(true)}
              />
            </div>

            {/* ASR MODAL */}
            <Modal
              title={t("Automatic Speech Recognition (ASR)")}
              show={asrModal}
              acceptButtonText={t("Done")}
              onAccept={() => setAsrModal(false)}
              acceptShow={false}
              onClose={() => setAsrModal(false)}
              working={generatingAsr}
            >
              {/* NO ASR HAS BEEN GENERATED */}

              {!project.asr_triggered ? (
                <div className="w-full">
                  <p className="mb-4 text-sm text-gray-600">
                    {t(
                      "ASR is generated by a deep-learning neural network. This means that accuracy isn't always spot on, but the service improves over time. ASR is intended as a starting point for captioning, from there you can correct and improve it!"
                    )}
                  </p>
                  <Button
                    text={t("Generate ASR")}
                    colour="blue"
                    cssClasses="w-full text-center"
                    onClick={() => generateASR()}
                  />
                </div>
              ) : !project.asr ||
                (project.asr.status &&
                  project.asr.status.toUpperCase() !== "DONE") ? (
                <Fragment>
                  <span className="mt-4 text-sm text-gray-800 leading-tight">
                    {t(
                      "Generating ASR. Please check back later. In the meantime you can close this window."
                    )}
                  </span>
                </Fragment>
              ) : (
                <Fragment>
                  <h3 className="mt-4 font-semibold">{t("Import ASR")}</h3>
                  {Object.entries(project.asr).map(([key, value]) => {
                    const asrLang = key.split("-")[0];

                    if (typeof value === "object") {
                      return (
                        <div key={key} className="mt-2 w-full">
                          <h3 className="text-xs text-gray-600">
                            {asrLang.toUpperCase()}
                          </h3>
                          <Button
                            cssClasses="mt-2 w-full text-center"
                            text={t("Import as Subtitles")}
                            colour="white"
                            iconBefore="import"
                            onClick={() => handleASR("subtitles", value.cues)}
                          >
                            {asrLang}
                          </Button>
                          <Button
                            cssClasses="mt-2 w-full text-center"
                            text={t("Import as Captions")}
                            colour="white"
                            iconBefore="import"
                            onClick={() => handleASR("captions", value.cues)}
                          >
                            {asrLang}
                          </Button>
                          <Warning
                            title={t("Heads up!")}
                            colour="orange"
                            customCss="mt-2"
                          >
                            {t(
                              "Importing ASR will overwrite the current cues on the text track"
                            )}
                          </Warning>
                          <Button
                            cssClasses="mt-6 w-full text-center"
                            text={t("Rerun ASR")}
                            colour="white"
                            iconBefore="replay"
                            onClick={() => {
                              return generateASR();
                            }}
                          ></Button>
                        </div>
                      );
                    } else return null;
                  })}
                </Fragment>
              )}
            </Modal>

            <div className="ml-3">
              <Button
                text={t("Publish")}
                colour="white"
                size="small"
                iconBefore="publish"
                onClick={() => {
                  setPublishModal(true);
                }}
              />
            </div>

            <div className="ml-3">
              <Button
                text={t(savingText)}
                colour="white"
                size="small"
                iconBefore="save"
                onClick={handleSave}
              />
            </div>
            <div className="ml-3 relative">
              <Button
                colour="white"
                tooltip={t("More options")}
                size="small"
                iconBefore="options"
                onClick={() => {
                  setOptionsMenuOpen(!optionsMenuOpen);
                }}
              />
              <div
                className={`${
                  optionsMenuOpen ? "inline-block" : "hidden"
                } py-2 mt-10 absolute flex flex-col justify-center align-middle text-sm bg-white h-100 right-0 rounded shadow top-0 w-100`}
              >
                <span className="mt-1 text-xs font-light text-gray-700 text-center">
                  {t("Import/Export")}
                </span>
                <button
                  className="mt-1 px-6 py-2 hover:bg-gray-100 focus:bg-gray-200 focus:outline-none whitespace-no-wrap"
                  onClick={() => {
                    setOptionsMenuOpen(false);
                    return setImportModal(true);
                  }}
                >
                  {t("Import track")}
                </button>
                <button
                  className="mt-1 px-6 py-2 hover:bg-gray-100 focus:bg-gray-200 focus:outline-none whitespace-no-wrap"
                  onClick={() => {
                    setOptionsMenuOpen(false);
                    return setExportModal(true);
                  }}
                >
                  {t("Export track")}
                </button>
                <span className="mt-1 mx-2 border border-t-1 border-gray-200"></span>
                <span className="mt-2 text-xs font-light text-gray-700 text-center">
                  {t("Delete")}
                </span>
                <button
                  className="mt-1 px-6 py-2 hover:bg-gray-100 focus:bg-gray-200 focus:outline-none whitespace-no-wrap"
                  onClick={() => {
                    setOptionsMenuOpen(false);
                    return setDeleteLangModal(true);
                  }}
                >
                  {t("Delete language")}
                </button>
                <button
                  className="mt-1 px-6 py-2 hover:bg-gray-100 focus:bg-gray-200 focus:outline-none whitespace-no-wrap"
                  onClick={() => {
                    setOptionsMenuOpen(false);
                    return setDeleteTrackModal(true);
                  }}
                >
                  {t("Delete text track")}
                </button>
              </div>
            </div>
          </Fragment>
        ) : (
          <span className="ml-4 bg-gray-200 text-gray-200 rounded-sm">
            Select language for more...
          </span>
        )}

        {/* PUBLISH MODAL */}
        <Modal
          title={t("Publish")}
          subtitle={
            video && project && project_user_specific.selectedLang
              ? `${
                  project[project_user_specific.selectedLang].label
                } (${project_user_specific.selectedLang.toUpperCase()})`
              : ""
          }
          width="w-96"
          show={publishModal}
          acceptButtonText={t("Done")}
          onAccept={() => {
            setPublishModal(false);
          }}
          onClose={() => {
            setPublishModal(false);
          }}
        >
          {video && project && project_user_specific.selectedLang ? (
            video.delivery_type !== "dynamic_origin" ? (
              <Warning title={t("Dynamic Delivery issue")} customCss="mt-2">
                {t(
                  "This video is not enabled for Dynamic Delivery. This is needed for importing text tracks. Please retranscode to enable Dynamic Delivery in your"
                )}{" "}
                <a
                  href={`https://studio.brightcove.com/products/videocloud/media/videos/${videoId}`}
                  target="_blank"
                  rel="noreferrer"
                  className="underline"
                >
                  Brightcove {t("account")}
                </a>
                .
              </Warning>
            ) : (
              <Fragment>
                {/* SUBTITLES */}
                <div className="mt-6 flex justify-between items-center">
                  <h3 className="font-semibold">{t("Subtitles")}</h3>
                  <div className="ml-2 w-48">
                    <BCTextTrackStatus
                      videoId={videoId}
                      trackType="subtitles"
                      closeModal={() => setPublishModal(false)}
                    />
                  </div>
                </div>

                {/* CAPTIONS */}
                <div className="mt-6 flex justify-between items-center">
                  <h3 className="font-semibold">{t("Captions")}</h3>
                  <div className="ml-2 w-48">
                    <BCTextTrackStatus
                      videoId={videoId}
                      trackType="captions"
                      closeModal={() => setPublishModal(false)}
                    />
                  </div>
                </div>

                {/* CHAPTERS */}
                <div className="mt-6 flex justify-between items-center">
                  <h3 className="font-semibold">{t("Chapters")}</h3>
                  <div className="ml-2 w-48">
                    <BCTextTrackStatus
                      videoId={videoId}
                      trackType="chapters"
                      closeModal={() => setPublishModal(false)}
                    />
                  </div>
                </div>

                {/* DESCRIPTIVE */}
                <div className="mt-6 flex justify-between items-center">
                  <h3 className="font-semibold">{t("Descriptive")}</h3>
                  <div className="ml-2 w-48">
                    <BCTextTrackStatus
                      videoId={videoId}
                      trackType="descriptive"
                      closeModal={() => setPublishModal(false)}
                    />
                  </div>
                </div>

                {/* TRANSCRIPT */}
                <div className="mt-6 flex justify-between items-center">
                  <h3 className="font-semibold">{t("Transcript")}</h3>
                  <div className="ml-2 w-48">
                    <BCStatusChange
                      videoId={videoId}
                      type="transcript"
                      lang={project_user_specific.selectedLang}
                      label={project[project_user_specific.selectedLang].label}
                      disabled={!enableTranscriptButton()}
                    />
                  </div>
                </div>

                {enableTranscriptButton() ? (
                  <></>
                ) : (
                  <div className="mt-6 flex justify-between items-center">
                    {" "}
                    <Warning
                      title={t("Heads up!")}
                      colour="orange"
                      customCss="mt-2"
                    >
                      {t(
                        "Can not publish transcript without any published captions or subtitles"
                      )}
                    </Warning>
                  </div>
                )}
              </Fragment>
            )
          ) : (
            t("Not selected")
          )}
        </Modal>

        {/* IMPORT MODAL */}

        {project && project_user_specific.selectedLang ? (
          <Modal
            title={t("Import track")}
            width="w-88"
            show={importModal}
            acceptButtonText={t("Import")}
            onAccept={handleImportFile}
            acceptDisabled={
              trackType === "" ? true : !importedFile ? true : false
            }
            onClose={() => {
              setImportedFile();
              setImportModalStep(1);
              setImportModal(false);
              setTrackType("");
            }}
            cancelButtonText={t("Cancel")}
            cancelDisabled={!importedFile ? true : false}
          >
            {importModalStep === 1 ? (
              <Fragment>
                <h2 className="mt-4 text-sm text-gray-600">
                  {t("Track type to import")}
                </h2>
                <div className="mt-2">
                  <SelectTrackType
                    selected={(choice) => setTrackType(choice)}
                  />
                </div>
                {project &&
                project[project_user_specific.selectedLang][trackType] &&
                project[project_user_specific.selectedLang][trackType].cues &&
                project[project_user_specific.selectedLang][trackType].cues
                  .length > 0 ? (
                  <Warning
                    title={t("Heads up!")}
                    colour="orange"
                    customCss="mt-2"
                  >
                    {t(
                      "Importing a track of this type will overwrite the cues on your current track."
                    )}
                  </Warning>
                ) : null}
                {trackType === "" ? null : (
                  <div className="mt-4 flex justify-end">
                    <Button
                      text={t("Continue")}
                      colour="white"
                      onClick={() => setImportModalStep(2)}
                    />
                  </div>
                )}
              </Fragment>
            ) : (
              <Fragment>
                <h3 className="mt-4 font-semibold">
                  {t("Import from Brightcove")}
                </h3>
                {video && video.text_tracks && video.text_tracks.length > 0 ? (
                  <Fragment>
                    <p className="mt-1 text-gray-500 text-xs">
                      {t(
                        "We've found these tracks in your Brightcove account."
                      )}
                    </p>
                    <div className="flex flex-col">
                      {video.text_tracks.map((track) => {
                        // TODO - IF mime_type !== "text/webvtt" warn user
                        return (
                          <label
                            key={`${track.srclang}-${track.kind}`}
                            htmlFor={`${track.srclang}-${track.kind}`}
                          >
                            <input
                              type="radio"
                              name="import-track-from-bc"
                              id={`${track.srclang}-${track.kind}`}
                              onChange={() => {
                                const trackToUse = () => {
                                  let source = track.src.replace(
                                    /https:|http:/gi,
                                    ""
                                  );
                                  if (
                                    track.sources &&
                                    track.sources.length > 0
                                  ) {
                                    for (
                                      let i = 0;
                                      i < track.sources.length;
                                      i++
                                    ) {
                                      if (
                                        track.sources[i].src.includes("https")
                                      ) {
                                        source = track.sources[i].src;
                                        break;
                                      }
                                    }
                                  }
                                  return source;
                                };

                                axios
                                  .get(trackToUse())
                                  .then((res) =>
                                    parseVTT(res.data, { meta: true })
                                  )
                                  .then((res) => {
                                    // setTrackType(track.kind);
                                    return setImportedFile(res);
                                  })
                                  .catch((err) => {
                                    addNotification(
                                      t("Something went wrong"),
                                      t("Please try again"),
                                      "bg-red-600"
                                    );
                                    console.error(err);
                                  });
                              }}
                            />
                            <span className="mt-4 ml-4 inline-block capitalize text-gray-700">
                              {track.kind} ({track.srclang.toUpperCase()})
                            </span>
                          </label>
                        );
                      })}
                    </div>
                  </Fragment>
                ) : (
                  <span className="mt-1 text-gray-500 text-xs">
                    {t("No tracks found in your account.")}
                  </span>
                )}

                <div className="mt-8 flex">
                  <span className="text-gray-600 uppercase text-center text-sm font-semibold">
                    {t("Or")}
                  </span>
                </div>
                <h3 className="mt-8 font-semibold">{t("Upload")}</h3>
                <p className="mt-1 text-gray-500 text-xs">
                  {t("Upload a .vtt file from your computer.")}
                </p>
                <h4 className="mt-4 text-sm text-gray-600">{t("File")}</h4>
                <div className="mt-2">
                  <FileUploader
                    type="vtt"
                    disabled={trackType === "" ? true : false}
                    disabledText="Choose the track type first."
                    importedFile={(file) => setImportedFile(file)}
                  />
                </div>
              </Fragment>
            )}
          </Modal>
        ) : null}

        {/* EXPORT MODAL */}
        <Modal
          title={t("Export track")}
          show={exportModal}
          acceptButtonText={t("Export")}
          onAccept={() => {
            setExportModal(false);
          }}
          acceptDisabled={true}
          onClose={() => {
            setExportModal(false);
            // setImportedFile();
            // setTrackType("");
          }}
          cancelDisabled={false}
          width="w-88"
        >
          {project && project[project_user_specific.selectedLang] ? (
            <Fragment>
              {Object.entries(project[project_user_specific.selectedLang]).map(
                ([key, value]) => {
                  if (key === "label" || !value.cues || value.cues.length === 0)
                    return null;
                  else
                    return (
                      <div
                        key={key}
                        className="mt-6 flex justify-between items-center"
                      >
                        <span className="font-semibold capitalize">
                          {t(key)}
                        </span>
                        <Button
                          text={t("Download .vtt")}
                          size="small"
                          colour="white"
                          iconBefore="download"
                          onClick={() => {
                            handleExportFile(value.cues, key);
                          }}
                        />
                      </div>
                    );
                }
              )}
            </Fragment>
          ) : null}
        </Modal>

        {/* DELETE LANG MODAL */}
        <Modal
          title={t("Delete")}
          show={deleteLangModal}
          acceptButtonText={t("Delete")}
          onAccept={() => {
            let langToDelete = project_user_specific.selectedLang;

            editorActions.removeLang(
              videoId,
              project_user_specific.selectedLang
            );
            setDeleteLangModal(false);
            languagesModal(true);
            return bcCustomFieldUpdate(
              account,
              videoId,
              "transcript",
              { lang: langToDelete },
              true
            );
          }}
          onClose={() => setDeleteLangModal(false)}
          cancelDisabled={false}
        >
          <div>{t("Are you sure you want to delete this language?")}</div>
          <span className="px-1 mt-4 inline-block bg-gray-200">
            {project && project_user_specific.selectedLang
              ? project[project_user_specific.selectedLang].label
              : null}
          </span>
        </Modal>

        {/* DELETE TEXT TRACK MODAL */}
        <Modal
          title={t("Delete")}
          show={deleteTrackModal}
          acceptButtonText={t("Delete")}
          onAccept={() => {
            let trackToDelete = {
              lang: project_user_specific.selectedLang,
              track: project_user_specific.selectedTrack,
            };

            //  editorActions.removeLang(
            //    videoId,
            //    project_user_specific.selectedLang
            //  );
            editorActions.removeTrack(
              videoId,
              trackToDelete.lang,
              trackToDelete.track
            );
            editorActions.addTrack(
              videoId,
              trackToDelete.lang,
              trackToDelete.track
            );

            editorActions.selectTrack(videoId, trackToDelete.track);

            setDeleteTrackModal(false);
            // languagesModal(true);
            //  return bcCustomFieldUpdate(
            //    account,
            //    videoId,
            //    "transcript",
            //    { lang: langToDelete },
            //    true
            //  );
            return editorActions.saveProject(videoId);
          }}
          onClose={() => setDeleteTrackModal(false)}
          cancelDisabled={false}
        >
          <div>{t("Are you sure you want to delete this text track?")}</div>
          <span className="px-1 mt-4 inline-block bg-gray-200 capitalize whitespace-no-wrap">
            {project &&
            project_user_specific.selectedLang &&
            project_user_specific.selectedTrack
              ? `${t(
                  project_user_specific.selectedTrack
                )} (${project_user_specific.selectedLang.toUpperCase()})`
              : null}
          </span>
        </Modal>
      </div>
    </div>
  );
}

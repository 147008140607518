import React from "react";

export default function TwoColWithSidebar(props) {
 const sidebarContent = props.children[0].props.children;
 const mainContent = props.children[1].props.children;
 return (
  <div className="container mx-auto px-5 py-5">
   <div className="flex flex-col-reverse md:grid grid-cols-12 gap-0 md:gap-10">
    <div className="mt-6 md:mt-0 md:col-span-3 break-words text-sm">
     {sidebarContent}
    </div>
    <div className="md:col-span-9">
     <div>{mainContent}</div>
    </div>
   </div>
  </div>
 );
}

export function Sidebar({ children }) {
 return <div>{children}</div>;
}

export function Main({ children }) {
 return <div>{children}</div>;
}

import React, { Fragment } from "react";
import { useSelector } from "react-redux";

import { generateTranscript } from "../utils/transcript";
import * as editorActions from "../../actions/editor-actions";
import { t } from "../../i18n/language";

export default function Transcript({ videoId, player }) {
	const video = useSelector((state) => state.projects[videoId]);
	const selectedLang = useSelector(
		(state) => state.projects_user_specific[videoId].selectedLang
	);

	var captionsCues = [];
	var descriptiveCues = [];
	var chapterCues = [];

	if (
		video[selectedLang]["captions"].cues &&
		video[selectedLang]["captions"].cues.length > 0
	) {
		captionsCues = video[selectedLang]["captions"].cues;
	} else {
		if (video[selectedLang]["subtitles"].cues) {
			captionsCues = video[selectedLang]["subtitles"].cues;
		}
	}

	if (video[selectedLang]["descriptive"].cues) {
		descriptiveCues = video[selectedLang]["descriptive"].cues;
	}

	if (video[selectedLang]["chapters"].cues) {
		chapterCues = video[selectedLang]["chapters"].cues;
	}

	const htmlString = generateTranscript(
		captionsCues,
		descriptiveCues,
		chapterCues,
		"en",
		"equalplay"
	);

	return (
		<Fragment>
			<h2 className="text-gray-600 font-light text-sm">{t("Transcript")}</h2>

			<div className="mt-2 h-96 px-4 py-4 bg-white border border-gray-200 overflow-y-scroll rounded">
				<span
					onClick={(e) => {
						player.currentTime(parseFloat(e.target.dataset.start));
						return editorActions.selectCue(videoId, e.target.dataset.id);
					}}
					dangerouslySetInnerHTML={{ __html: htmlString.innerHTML }}
				/>
			</div>
		</Fragment>
	);
}

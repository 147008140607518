const webvtt = require("node-webvtt");

/**
 * Takes a VTT file and returns an Equal Play object
 * @param {array} vtt -
 * @param {object} meta -
 */
export function parseVTT(vtt, meta) {
 const parsed = webvtt.parse(vtt, { meta: meta });

 return parsed;
}

/**
 * Takes an array of Equal Play cues and turns them into a VTT file, ready for download
 * @param {array} cues - Array of cues to compile to VTT
 * @param {string} lang - Lang code for meta
 * @param {string} kind - Track kind for meta
 */
export function toVTT(cues, lang, kind) {
 //  cues.map(cue => console.log(cue.start));

 let input = {
  meta: {
   Kind: kind,
   Language: lang,
  },
  cues: cues,
  valid: true,
 };

 try {
  const result = webvtt.compile(input);

  return result;
 } catch (error) {
  console.error("toVTT", error);
  return null;
 }
}

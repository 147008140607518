import React, { Fragment } from "react";

import { t } from "../../i18n/language";

const tips = {
 sl: [
  {
   headline: t("Lighting"),
   body: t(
    "Make sure the background is evenly lit and that no green light is leaking onto the talent."
   ),
  },
  {
   headline: t("Length"),
   body: t(
    "The sign language video should be the same length and in sync with the original video."
   ),
  },
  {
   headline: t("Resolution and FPS"),
   body: t(
    "Upload 720p or higher and with the same FPS as the original video."
   ),
  },
  {
   headline: t("Framing"),
   body: t("Frame the interpreter from the hips and up."),
  },
  {
   headline: t("Composition"),
   body: t(
    "Try not to use more than 50% of the image width and keep the talent centered."
   ),
  },
 ],
 ad: [
  {
   headline: t("Length"),
   body: t(
    "The descriptive track should be the same length and in sync with the original video's audio."
   ),
  },
  {
   headline: t("Content"),
   body: t(
    "Your audio description file should contain all audio from the video, not just the audio descriptive cues."
   ),
  },
 ],
};

export default function SidebarTips({ type }) {
 return (
  <Fragment>
   <div className="flex flex-col py-2 pb-4">
    {tips[type].map((tip) => {
     return (
      <div className="mb-3 " key={tip.headline}>
       <h3 className="py-2 text text-gray-700 text font-bold">
        {tip.headline}
       </h3>
       <span className="mt-2 text-sm text-gray-600">
        {tip.body}{" "}
        <a
         className="border-b border-gray-200 hover:border-gray-500 focus:border-gray-500"
         href={tip.link}
        >
         {tip.linkText}
        </a>
       </span>
      </div>
     );
    })}
   </div>
  </Fragment>
 );
}

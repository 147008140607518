import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";

import Navbar from "./Navbar";
import Button from "./Button";
import * as slActions from "../actions/sign-actions";
import Spinner from "./Spinner";

export default function SignLanguage() {
 const [imageLoaded, setImageLoaded] = useState(false);
 const [screenshot, setScreenshot] = useState("");

 const [manipImageLoaded, setManipImageLoaded] = useState(false);
 const [manipScreenshot, setManipScreenshot] = useState(undefined);

 const videoId = useParams().id;
 const sign_language_editor = useSelector(
  (state) => state.sign_language_editor
 );

 const handleManipulateScreenshot = () => {
  slActions.loadingImage(true);
  manipulateScreenshot(sign_language_editor).then((screenshot) => {
   // slActions.loadingImage(false);
   setManipScreenshot(screenshot);
   setManipImageLoaded(true);
  });
 };

 useEffect(() => {
  getScreenshot().then((screenshot) => {
   setScreenshot(screenshot);
   setImageLoaded(true);
  });
 }, []);

 return (
  <div>
   <Navbar />
   <div className="container mx-auto px-5 py-5">
    <div>{videoId}</div>

    <div className="h-full bg-white border border-gray-200 rounded">
     <div className="px-4 py-4 grid grid-cols-4 gap-6">
      <div className="col-span-3">
       {/* {imageLoaded && screenshot !== "" ? ( */}
       <Screenshot
        screenshot={screenshot}
        manipScreenshot={
         manipScreenshot === undefined ? screenshot : manipScreenshot
        }
        onClick={(maskData) => {
         handleManipulateScreenshot();
        }}
       />
       {/* ) : null} */}

       {/* {manipImageLoaded && manipScreenshot !== "" ? (
        <Screenshot screenshot={screenshot}  />
       ) : null} */}
      </div>
      <div>
       <div className="h-full flex flex-col justify-between">
        <Tabs onClick={handleManipulateScreenshot} />
        <div className="mt-4 block flex justify-end">
         <Button
          text="Process"
          disabled={
           sign_language_editor.chromaColour &&
           sign_language_editor.maskAdded &&
           !sign_language_editor.loadingImage
            ? false
            : true
          }
         />
        </div>
       </div>
      </div>
     </div>
    </div>
   </div>
  </div>
 );
}

const Tabs = ({ onClick }) => {
 const [selectedTab, setSelectedTab] = useState("chroma");
 return (
  <div className="flex flex-col h-full">
   <Tab title="Chroma" type="chroma">
    <ChromaContent onClick={onClick} />
   </Tab>
   <Tab title="Mask" type="mask">
    <MaskContent onClick={onClick} />
   </Tab>
   {/* <Tab title="Scale & Position" type="scale">
     <ChromaContent /> 
   </Tab> */}
   {/* <Tab title="Colour Correction" type="colour-correction">
    <ChromaContent /> 
   </Tab> */}
  </div>
 );
};

const Tab = ({ title, type, children }) => {
 //  const [selected, setSelected] = useState(false);
 const selected = useSelector((state) => state.sign_language_editor.tab);

 //  useEffect(() => {
 //   if (selectedTab === type) {
 //    setSelected(true);
 //   } else {
 //    setSelected(false);
 //   }
 //  }, [selectedTab]);

 return (
  <div className={`mt-2 ${selected === type ? "flex-1" : ""}`}>
   <button
    onClick={() => {
     if (selected === type) {
      slActions.selectTab("");
     } else {
      slActions.selectTab(type);
     }
    }}
    className={`w-full block px-3 py-1 flex justify-between align-middle text-gray-800 border rounded cursor-pointer focus:outline-none focus:shadow-outline hover:bg-gray-100 ${
     selected === type
      ? "bg-gray-100 border-gray-100"
      : "bg-white border-gray-200 "
    }`}
   >
    <div className="flex justify-center align-middle items-center">
     {selected === type ? (
      <svg
       className="w-4 h-4 text-gray-800"
       fill="currentColor"
       xmlns="http://www.w3.org/2000/svg"
      >
       <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.234 5.834a.8.8 0 011.131 0L8 8.47l2.634-2.635a.8.8 0 111.131 1.132l-3.2 3.2a.8.8 0 01-1.13 0l-3.2-3.2a.8.8 0 010-1.132z"
       />
      </svg>
     ) : (
      <svg
       className="w-4 h-4 text-gray-800"
       fill="currentColor"
       xmlns="http://www.w3.org/2000/svg"
      >
       <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.834 11.765a.8.8 0 010-1.13L8.47 8 5.834 5.366a.8.8 0 011.132-1.132l3.2 3.2a.8.8 0 010 1.132l-3.2 3.2a.8.8 0 01-1.132 0z"
       />
      </svg>
     )}
     <div className="ml-2">{title}</div>
    </div>
   </button>
   {selected === type ? children : null}
  </div>
 );
};

const ChromaContent = ({ onClick }) => {
 const choosingChroma = useSelector(
  (state) => state.sign_language_editor.choosingChroma
 );
 const chromaColour = useSelector(
  (state) => state.sign_language_editor.chromaColour
 );
 return (
  <div className="px-2 py-2 text-sm text-gray-700">
   <div className="text-base">Chroma colour</div>
   <div className="flex">
    <button
     onClick={() => {
      if (choosingChroma === true) {
       slActions.choosingChromaColour(false);
      } else {
       slActions.choosingChromaColour(true);
      }
     }}
     className="w-full mt-2 flex focus:outline-none"
    >
     <div
      style={{ backgroundColor: `#${chromaColour ? chromaColour : "f7fafc"}` }}
      className="w-10 h-10 border border-gray-100 rounded"
     ></div>
     <div className="h-10 ml-2 px-3 py-1 flex-1 flex text-gray-500 justify-start items-center text-base border border-gray-200 rounded">
      {choosingChroma
       ? "Choosing..."
       : chromaColour
       ? `#${chromaColour}`
       : "Choose colour..."}
     </div>
    </button>
    {choosingChroma && (
     <div className="ml-2 h-auto">
      <button
       onClick={() => {
        if (choosingChroma === true) {
         slActions.choosingChromaColour(false);
         onClick();
        } else {
         slActions.choosingChromaColour(true);
        }
       }}
       className="w-full mt-2 flex focus:outline-none"
      >
       <div className="h-10 px-3 py-1 flex-1 flex text-gray-800 justify-start items-center text-base border border-gray-200 rounded">
        Apply
       </div>
      </button>
     </div>
    )}
   </div>
   <p className="mt-4 text-xs text-gray-500">
    <strong>Tip</strong>: Here is some helpful info that might help the user
    make a good decision about this thing right here.
   </p>
  </div>
 );
};

const MaskContent = ({ onClick }) => {
 const addingMask = useSelector(
  (state) => state.sign_language_editor.addingMask
 );
 const maskAdded = useSelector((state) => state.sign_language_editor.maskAdded);
 const maskShape = useSelector((state) => state.sign_language_editor.maskShape);

 return (
  <div className="px-2 py-2 text-sm text-gray-700">
   <div className="text-base">Your mask</div>
   <div className="flex">
    <button
     onClick={() => {
      if (addingMask === true) {
       slActions.addingMask(false);
      } else {
       slActions.addingMask(true);
      }
      // slActions.maskAdded(true);
     }}
     className="w-full mt-2 flex focus:outline-none"
    >
     <div className="h-10 px-3 py-1 flex-1 flex text-gray-500 justify-start items-center text-base border border-gray-200 rounded">
      {addingMask
       ? "Drawing mask..."
       : maskAdded
       ? `Mask added!`
       : "Add mask..."}
     </div>
    </button>
    {addingMask && (
     <div className="ml-2 h-auto">
      <button
       onClick={() => {
        if (addingMask === true) {
         slActions.maskAdded(true);
         slActions.addingMask(false);
         onClick();
        } else {
         slActions.addingMask(true);
        }
       }}
       className="w-full mt-2 flex focus:outline-none"
      >
       <div className="h-10 px-3 py-1 flex-1 flex text-gray-800 justify-start items-center text-base border border-gray-200 rounded">
        Apply
       </div>
      </button>
     </div>
    )}
   </div>
   <p className="mt-4 text-xs text-gray-500">
    <strong>Tip</strong>: Here is some helpful info that might help the user
    make a good decision about this thing right here.
   </p>
  </div>
 );
};

const getScreenshot = async () => {
 return await axios({
  method: "get",
  url:
   "https://europe-west1-equal-play.cloudfunctions.net/chroma/haZ03i4scNXt8axQmkuO",
  headers: {
   "Content-Type": "image/png",
  },
  responseType: "arraybuffer",
 })
  .then((res) => new Buffer(res.data, "binary").toString("base64"))
  //   .then(res => console.log(res))
  .catch((err) => console.error(err));
};

const manipulateScreenshot = async (
 sign_language_editor,
 maskData = undefined
) => {
 const data = JSON.stringify({
  preview: true,
  alpha: true,
  // "curves": [
  //     {
  //         "name": "master",
  //         "value": "0.125/0.004 0.320/0.563 0.566/0.879 1.000/0.965"
  //     }
  // ],
  maskColor: sign_language_editor.chromaColour,
  pickedColor: sign_language_editor.chromaColour,
  similarity: 0.05,
  blend: 0.03,
  scale: 0.9,
  mask: {
   enabled: sign_language_editor.maskAdded,
   image: sign_language_editor.maskShape,
  },
 });

 return await axios({
  method: "put",
  url:
   "https://europe-west1-equal-play.cloudfunctions.net/chroma/haZ03i4scNXt8axQmkuO",
  headers: {
   "Content-Type": "application/json",
  },
  responseType: "arraybuffer",
  data: data,
 })
  .then((res) => new Buffer(res.data, "binary").toString("base64"))
  .catch((err) => console.error(err));
};

const Screenshot = ({ screenshot, manipScreenshot = undefined, onClick }) => {
 const [drawingMask, setDrawingMask] = useState(false);
 const [maskStartPoint, setMaskStartPoint] = useState({ x: 0, y: 0 });
 const [maskEndPoint, setMaskEndPoint] = useState({ x: 0, y: 0 });
 const [maskElipsis, setMaskElispsis] = useState({
  x: 0,
  y: 0,
  radiusX: 0,
  radiusY: 0,
 });

 let canvasOriginal = React.createRef();
 let imageOriginal = React.createRef();
 let canvas = React.createRef();
 let canvasMask = React.createRef();
 let image = React.createRef();

 const sign_language_editor = useSelector(
  (state) => state.sign_language_editor
 );

 const chooseColour = (e) => {
  const canvasRect = canvasOriginal.current.getBoundingClientRect();
  var x = e.clientX - canvasRect.x;
  var y = e.clientY - canvasRect.y;
  const ctx = canvasOriginal.current.getContext("2d");
  let gridData = ctx.getImageData(x - 1, y - 1, 3, 3).data;

  var hex = averageOfGrid(gridData);

  slActions.selectChromaColour(hex);
  // slActions.choosingChromaColour(false);
  // slActions.loadingImage(true);

  // onClick();
 };

 const startDrawMask = (e) => {
  var bounds = e.target.getBoundingClientRect();
  setDrawingMask(true);
  setMaskStartPoint({
   x: e.clientX - bounds.left,
   y: e.clientY - bounds.top,
  });
 };

 const handleMaskDraw = (e) => {
  const canvasRect = canvas.current.getBoundingClientRect();
  var maskCanvasCtx = canvasMask.current.getContext("2d");
  // console.log(`x: ${e.clientX - canvasRect.x}, y: ${e.clientY - canvasRect.y}`);

  var bounds = e.target.getBoundingClientRect();

  var nDeltaX = maskStartPoint.x - (e.clientX - canvasRect.left);
  var nDeltaY = maskStartPoint.y - (e.clientY - canvasRect.top);

  let xRadius = Math.sqrt(nDeltaX * nDeltaX + nDeltaX * nDeltaX);
  let yRadius = Math.sqrt(nDeltaY * nDeltaY + nDeltaY * nDeltaY);

  maskCanvasCtx.clearRect(0, 0, canvasRect.width, canvasRect.height);
  maskCanvasCtx.save();

  maskCanvasCtx.fillStyle = "rgba(0, 0, 0, 1)";
  maskCanvasCtx.fillRect(0, 0, canvasRect.width, canvasRect.height);

  maskCanvasCtx.beginPath();
  maskCanvasCtx.ellipse(
   maskStartPoint.x,
   maskStartPoint.y,
   xRadius,
   yRadius,
   0,
   0,
   2 * Math.PI
  );

  setMaskElispsis({
   x: maskStartPoint.x,
   y: maskStartPoint.y,
   radiusX: xRadius,
   radiusY: yRadius,
  });

  maskCanvasCtx.fillStyle = "rgba(255, 255, 255, 1)";
  // maskCanvasCtx.globalCompositeOperation = "destination-out";
  maskCanvasCtx.fill();

  maskCanvasCtx.restore();
 };

 const endDrawMask = (e) => {
  setMaskEndPoint({
   x: e.clientX,
   y: e.clientY,
  });
  setDrawingMask(false);

  saveMask();
 };

 const saveMask = () => {
  const mask = canvasMask.current;
  // var maskCtx = mask.getContext("2d");

  // maskCtx.fillStyle = "rgba(255, 255, 255, 1)";
  // maskCtx.fillRect(0, 0, mask.width, mask.height);

  var maskData = mask.toDataURL("image/png", 1.0);

  return slActions.maskShape(maskData);

  // onClick(maskData);
 };

 useEffect(() => {
  const canvasRect = canvasOriginal.current.getBoundingClientRect();
  const ctx = canvasOriginal.current.getContext("2d");
  const img = imageOriginal.current;

  ctx.clearRect(0, 0, 898, 515);

  img.onload = () => {
   console.log("Original loaded");
   slActions.loadingImage(false);
   ctx.drawImage(img, 0, 0, 898, 515);
  };
 }, [screenshot]);

 useEffect(() => {
  // let img;
  // if (manipScreenshot) {
  //  img = image.current;
  // } else {
  //  img = imageOriginal.current;
  // }
  const img = image.current;
  const canvasRect = canvas.current.getBoundingClientRect();
  const ctx = canvas.current.getContext("2d");

  ctx.clearRect(0, 0, 898, 515);

  img.onload = () => {
   console.log("Manip loaded");
   slActions.loadingImage(false);
   ctx.drawImage(img, 0, 0, 898, 515);
  };
 }, [manipScreenshot]);

 return (
  <div className="">
   <div className="w-100 h-16x9 relative">
    <div className="absolute top-0 right-0 left-0 bottom-0 flex justify-center items-center pointer-events-none">
     {sign_language_editor.loadingImage !== false ? <Spinner /> : null}
    </div>
    <canvas
     ref={canvasOriginal}
     onClick={(e) => {
      if (sign_language_editor.choosingChroma === true) {
       chooseColour(e);
      } else {
       return null;
      }
     }}
     width="898"
     height="515"
     className={`bg-gray-100 ${
      sign_language_editor.choosingChroma ? "cursor-move" : "cursor-default"
     } `}
    />
    <canvas
     ref={canvas}
     width="898"
     height="515"
     className={`absolute top-0 left-0 bg-gray-100 ${
      sign_language_editor.choosingChroma ? "cursor-move" : "cursor-default"
     }
     ${
      sign_language_editor.choosingChroma
       ? "hidden"
       : !manipScreenshot && "hidden"
     }`}
    />
    <canvas
     ref={canvasMask}
     onMouseDown={(e) => {
      if (sign_language_editor.addingMask === true) {
       startDrawMask(e);
      } else {
       return null;
      }
     }}
     onMouseMove={(e) => {
      if (sign_language_editor.addingMask === true && drawingMask === true) {
       handleMaskDraw(e);
      } else {
       return null;
      }
     }}
     onMouseUp={(e) => {
      if (sign_language_editor.addingMask === true) {
       endDrawMask(e);
      } else {
       return null;
      }
     }}
     width="898"
     height="515"
     className={`absolute top-0 left-0 opacity-25
     ${!sign_language_editor.addingMask && "hidden"}

     ${sign_language_editor.choosingChroma && "hidden"}
      ${
       sign_language_editor.addingMask ? "cursor-colresize" : "cursor-default"
      } `}
    />

    <img
     ref={imageOriginal}
     src={`data:image/png;base64, ${screenshot}`}
     className="hidden"
    />
    {/* {manipScreenshot && ( */}
    <img
     ref={image}
     src={`data:image/png;base64, ${manipScreenshot}`}
     className="hidden"
    />
    {/* )} */}
   </div>
  </div>
 );
};

const rgbToHex = (rgb) => {
 var hex = Number(rgb).toString(16);
 if (hex.length < 2) {
  hex = "0" + hex;
 }
 return hex;
};

const fullColorHex = (r, g, b) => {
 var red = rgbToHex(r);
 var green = rgbToHex(g);
 var blue = rgbToHex(b);
 return red + green + blue;
};

const averageOfGrid = (gridData) => {
 let r = 0;
 let g = 0;
 let b = 0;

 for (var i = 4; i <= gridData.length; i += 4) {
  r += gridData[i - 4];
  g += gridData[i - 3];
  b += gridData[i - 2];
 }

 r = Math.round(r / 9);
 g = Math.round(g / 9);
 b = Math.round(b / 9);

 return fullColorHex(r, g, b);
};

// Functions that can be used when not logged in, probably most for the preview page
import axios from "axios";
import { functionBaseUrl } from "../auth/firebase";

/** Gets the configured player_id or "default" */
const getAccountPlayerId = async (accountId) => {
  if (!accountId) {
    return "default";
  }

  const playerId = await axios({
    method: "GET",
    url: `${functionBaseUrl}getPlayerId?account_id=${accountId}`,
  })
    .then((res) => {
      const { data } = res;
      return data.player_id || "default";
    })
    .catch((err) => {
      console.error(err);
      return "default";
    });

  return playerId;
};

export { getAccountPlayerId };

import { useState } from "react";
import { Link } from "react-router-dom";
import { listClients, listUsers } from "../utils/adminFunctions";
import { getKey } from "../utils/helpers";
import { t } from "../../i18n/language";
import { LinkIcon, Tick } from "../utils/svgs";

function Users() {
  const [users, setUsers] = useState();
  const [clients, setClients] = useState();

  const filterUsers = (org_id) => {
    const filteredUsers = users.filter((user) => user.org_id === org_id);
    return filteredUsers;
  };

  if (!users && !clients) {
    Promise.all([listClients(), listUsers()])
      .then((values) => {
        values[0].sort((a, b) => a.name.localeCompare(b.name));
        setClients(values[0]);
        setUsers(values[1]);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  return (
    <>
      <h1 className="text-2xl font-semibold pb-3">Users</h1>

      {clients &&
        clients.map((client) => {
          const clientUsers = filterUsers(client.org_id);
          return (
            <div key={getKey()}>
              <h2 className="text-xl font-semibold">{client.name}</h2>
              <div className="mt-2 bg-white rounded border border-gray-200 text-sm">
                <div className="px-3 py-2 grid grid-cols-11 text-xs text-gray-600 uppercase font-semibold leading-relaxed">
                  <div className="col-span-4">{t("Name")}</div>
                  <div className="col-span-4">{"Email"}</div>
                  <div className="col-span-1 mx-auto">{"Link"}</div>
                  <div className="col-span-1 mx-auto">{"Admin"}</div>
                  <div className="col-span-1 mx-auto">{"Manager"}</div>
                </div>

                {clientUsers.length === 0 && (
                  <div className="px-3 py-4 grid grid-cols-11 border-t border-gray-200">
                    <div className="col-span-11 font-semibold">
                      No users for this client...
                    </div>
                  </div>
                )}
                {clientUsers.map((user) => {
                  return (
                    <div
                      className="px-3 py-4 grid grid-cols-11 border-t border-gray-200"
                      key={getKey()}
                    >
                      <div className="col-span-4 font-semibold">
                        {`${user.first_name} ${user.last_name}`}
                      </div>
                      <div className="col-span-4">{user.email}</div>
                      <div className="col-span-1 mx-auto">
                        <Link to={`/admin/users/${user.uid}`}>
                          <LinkIcon />
                        </Link>
                      </div>
                      <div className="col-span-1 mx-auto">
                        {user.is_admin && <Tick />}
                      </div>
                      <div className="col-span-1 mx-auto">
                        {user.is_manager && <Tick />}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
    </>
  );
}

export default Users;

import React from "react";

export default function Button({
 onClick,
 text,
 size = "large",
 colour = "green",
 disabled = false,
 iconBefore = null,
 iconAfter = null,
 cssClasses = "",
 tooltip = ""
}) {
 let classBase = "rounded";
 let classSize;
 let classColour;
 let classDisabled;

 // BUTTON COLOUR
 switch (colour) {
  case "white":
   classColour =
    "border border-gray-300 bg-white text-gray-700 hover:bg-gray-200 active:bg-gray-800 focus:outline-none focus:shadow-outline";
   break;

  case "blue":
   classColour =
    "border border-blue-600 bg-blue-600 text-white hover:bg-blue-700 active:bg-blue-800 focus:outline-none focus:shadow-outline";
   break;

  default:
   classColour =
    "border border-equalplay-light bg-equalplay-light text-white hover:bg-equalplay-dark active:bg-gray-800 focus:outline-none focus:shadow-outline";
   break;
 }

 // BUTTON SIZING
 switch (size) {
  case "small":
   classSize = "text-sm px-2 py-1 ";
   break;

  default:
   classSize = "px-3 py-1 ";
   break;
 }

 // BUTTON DISABLED
 switch (disabled) {
  case true:
   classDisabled =
    "border-gray-100 bg-gray-100 text-gray-100 cursor-not-allowed hover:bg-gray-100";
   break;

  default:
   classDisabled = "";
   break;
 }

 // ICON BEFORE
 let iconBeforeSVG;
 switch (iconBefore) {
  case "delete":
   iconBeforeSVG = (
    <svg
     className="w-5 h-5 text-gray-600"
     fill="currentColor"
     xmlns="http://www.w3.org/2000/svg"
    >
     <path d="M6.667 5V3.333c0-.916.75-1.666 1.666-1.666h3.334a1.667 1.667 0 011.666 1.666V5H17.5a.833.833 0 110 1.667h-.833v10A1.667 1.667 0 0115 18.333H5a1.667 1.667 0 01-1.667-1.666v-10H2.5A.833.833 0 012.5 5h4.167zM5 6.667v10h10v-10H5zM11.667 5V3.333H8.333V5h3.334zM8.333 8.333a.833.833 0 01.834.834v5a.833.833 0 01-1.667 0v-5a.833.833 0 01.833-.834zm3.334 0a.833.833 0 01.833.834v5a.833.833 0 01-1.667 0v-5a.833.833 0 01.834-.834z" />
    </svg>
   );
   break;

  case "settings":
   iconBeforeSVG = (
    <svg
     className="w-5 h-5 text-gray-600"
     fill="currentColor"
     xmlns="http://www.w3.org/2000/svg"
    >
     <path d="M7.5 3.817v-.484c0-.916.75-1.666 1.667-1.666h1.666A1.667 1.667 0 0112.5 3.333v.484a6.667 6.667 0 011.6.925l.417-.242a1.667 1.667 0 012.283.608l.833 1.45a1.667 1.667 0 01-.608 2.275l-.417.242a6.72 6.72 0 010 1.85l.417.25a1.667 1.667 0 01.608 2.267l-.833 1.45a1.667 1.667 0 01-2.275.608l-.417-.25c-.49.388-1.03.705-1.608.942v.475a1.667 1.667 0 01-1.667 1.666H9.167A1.667 1.667 0 017.5 16.667v-.484a6.662 6.662 0 01-1.6-.925l-.417.242a1.669 1.669 0 01-2.283-.608l-.833-1.45a1.667 1.667 0 01.608-2.275l.417-.242a6.717 6.717 0 010-1.85l-.417-.25a1.667 1.667 0 01-.608-2.267l.833-1.45A1.667 1.667 0 015.475 4.5l.417.25A6.666 6.666 0 017.5 3.808v.009zm-.933 2.55l-.45.425-1.475-.85-.834 1.45 1.467.841-.142.609a5.017 5.017 0 000 2.316l.142.609-1.467.841.834 1.45 1.475-.85.45.425c.566.54 1.25.94 2 1.167l.6.167v1.7h1.666v-1.7l.592-.167a5 5 0 002.008-1.167l.45-.425 1.475.85.834-1.45-1.467-.841.142-.609c.18-.761.18-1.555 0-2.316l-.142-.609 1.467-.841-.834-1.45-1.475.85-.45-.425a5 5 0 00-2-1.167l-.6-.167v-1.7H9.167v1.7l-.592.167a5 5 0 00-2.008 1.167zM10 13.333a3.333 3.333 0 110-6.667 3.333 3.333 0 010 6.667zm0-1.666a1.667 1.667 0 100-3.334 1.667 1.667 0 000 3.334z" />
    </svg>
   );
   break;

  case "options":
   iconBeforeSVG = (
    <svg
     className="w-5 h-5 text-gray-600"
     fill="currentColor"
     xmlns="http://www.w3.org/2000/svg"
    >
     <path d="M3.333 12.5a2.5 2.5 0 110-5 2.5 2.5 0 010 5zm0-1.667a.834.834 0 100-1.667.834.834 0 000 1.667zM10 12.5a2.5 2.5 0 110-5 2.5 2.5 0 010 5zm0-1.667a.834.834 0 100-1.667.834.834 0 000 1.667zm6.667 1.667a2.5 2.5 0 110-5 2.5 2.5 0 010 5zm0-1.667a.833.833 0 100-1.666.833.833 0 000 1.666z" />
    </svg>
   );
   break;

  case "zoomin":
   iconBeforeSVG = (
    <svg
     className="w-5 h-5 text-gray-600"
     fill="currentColor"
     xmlns="http://www.w3.org/2000/svg"
    >
     <path d="M13.6 12.417l4.492 4.5a.834.834 0 01-1.184 1.166L12.425 13.6a6.667 6.667 0 111.175-1.175v-.008zm-5.267.916a5 5 0 100-10 5 5 0 000 10zM9.167 7.5h1.666a.833.833 0 010 1.667H9.167v1.666a.833.833 0 01-1.667 0V9.167H5.833a.833.833 0 010-1.667H7.5V5.833a.833.833 0 011.667 0V7.5z" />
    </svg>
   );
   break;

  case "zoomout":
   iconBeforeSVG = (
    <svg
     className="w-5 h-5 text-gray-600"
     fill="currentColor"
     xmlns="http://www.w3.org/2000/svg"
    >
     <path d="M13.6 12.417l4.492 4.5a.834.834 0 01-1.184 1.166L12.425 13.6a6.667 6.667 0 111.175-1.175v-.008zm-5.267.916a5 5 0 100-10 5 5 0 000 10zm3.334-5a.833.833 0 01-.834.834h-5a.833.833 0 010-1.667h5a.833.833 0 01.834.833z" />
    </svg>
   );
   break;

  case "replay":
   iconBeforeSVG = (
    <svg
     className="w-5 h-5 text-gray-600"
     fill="currentColor"
     xmlns="http://www.w3.org/2000/svg"
    >
     <path d="M5 15.583V17.5a.833.833 0 11-1.667 0v-4.167a.833.833 0 01.834-.833h4.166a.833.833 0 110 1.667H5.917A5.834 5.834 0 0015.833 10a.833.833 0 011.667 0A7.5 7.5 0 015 15.583zM15 4.417V2.5a.833.833 0 111.667 0v4.167a.833.833 0 01-.834.833h-4.166a.833.833 0 010-1.667h2.416A5.833 5.833 0 004.167 10 .833.833 0 112.5 10 7.5 7.5 0 0115 4.417z" />
    </svg>
   );
   break;

  case "play":
   iconBeforeSVG = (
    <svg
     className="w-5 h-5 text-gray-600"
     fill="currentColor"
     //  strokeWidth="2"
     //  fill="none"
     xmlns="http://www.w3.org/2000/svg"
    >
     <path d="M16 12c.333-.167.5-.6.5-1s-.167-.833-.5-1C13 8 7 4.5 5.5 3.5c-1.2-.8-1.5.333-1.5 1V16c0 1.2.5 1.5 1.5 1L16 12z" />
    </svg>
   );
   break;

  case "pause":
   iconBeforeSVG = (
    <svg
     className="w-5 h-5 text-gray-600"
     stroke="currentColor"
     strokeWidth="3"
     strokeLinecap="round"
     strokeLinejoin="round"
     fill="none"
     xmlns="http://www.w3.org/2000/svg"
    >
     <path d="M6 4v12M14 4v12" />
    </svg>
   );
   break;

  case "add":
   iconBeforeSVG = (
    <svg
     className="w-5 h-5 text-gray-600"
     fill="currentColor"
     xmlns="http://www.w3.org/2000/svg"
    >
     <path d="M10 18a8 8 0 110-16 8 8 0 010 16zm0-1.6a6.4 6.4 0 100-12.8 6.4 6.4 0 000 12.8zm.8-7.2h1.6a.8.8 0 110 1.6h-1.6v1.6a.8.8 0 11-1.6 0v-1.6H7.6a.8.8 0 010-1.6h1.6V7.6a.8.8 0 011.6 0v1.6z" />
    </svg>
   );
   break;

  case "download":
   iconBeforeSVG = (
    <svg
     className="w-5 h-5 text-gray-600"
     fill="currentColor"
     xmlns="http://www.w3.org/2000/svg"
    >
     <path d="M9.167 12.158V2.5a.833.833 0 111.666 0v9.658l2.75-2.75a.833.833 0 011.167 1.184l-4.167 4.166a.833.833 0 01-1.166 0L5.25 10.592a.834.834 0 011.167-1.184l2.75 2.75zM2.5 14.167a.833.833 0 111.667 0v2.5h11.666v-2.5a.833.833 0 111.667 0v2.5a1.667 1.667 0 01-1.667 1.666H4.167A1.667 1.667 0 012.5 16.667v-2.5z" />
    </svg>
   );
   break;

  case "publish":
   iconBeforeSVG = (
    <svg
     className="w-5 h-5 text-gray-600"
     fill="currentColor"
     xmlns="http://www.w3.org/2000/svg"
    >
     <path d="M13.508 14.125a4.167 4.167 0 01-3.35 4.083l-3.208.642a.834.834 0 01-.75-.225l-.592-.583a1.666 1.666 0 010-2.359l1.2-1.208c-.423-.361-.819-.754-1.183-1.175l-1.2 1.2a1.668 1.668 0 01-2.358 0l-.584-.583a.833.833 0 01-.233-.75l.642-3.217A4.167 4.167 0 015.975 6.6h.717a10.892 10.892 0 0110.683-4.558.834.834 0 01.692.691A10.816 10.816 0 0113.5 13.417v.708h.008zm-3.675 2.45a2.5 2.5 0 002.009-2.45v-1.167a.834.834 0 01.391-.7 9.2 9.2 0 004.267-8.65 9.15 9.15 0 00-8.65 4.267.833.833 0 01-.708.392H5.975a2.5 2.5 0 00-2.45 2l-.55 2.783.275.275 1.867-1.867a.834.834 0 011.266.1 9.233 9.233 0 002.167 2.167.833.833 0 01.1 1.267l-1.867 1.866.275.275 2.775-.558zM12.5 8.333a.833.833 0 110-1.667.833.833 0 010 1.667z" />
    </svg>
   );
   break;

  case "save":
   iconBeforeSVG = (
    <svg
     className="w-5 h-5 text-gray-600"
     fill="none"
     stroke="currentColor"
     xmlns="http://www.w3.org/2000/svg"
     strokeLinecap="round"
     strokeLinejoin="round"
     strokeWidth="1.5"
    >
     <path d="M6.667 14.167a4.167 4.167 0 01-.764-8.264 4.168 4.168 0 018.194 0 4.168 4.168 0 01-.764 8.264M7.5 10L10 7.5m0 0l2.5 2.5M10 7.5v10" />
    </svg>
   );
   break;

  case "sparkles":
   iconBeforeSVG = (
    <svg
     className="w-5 h-5 text-white"
     fill="none"
     stroke="currentColor"
     xmlns="http://www.w3.org/2000/svg"
     strokeWidth="1.5"
     strokeLinecap="round"
     strokeLinejoin="round"
    >
     <path d="M4.167 2.5v3.333V2.5zM2.5 4.167h3.333H2.5zm2.5 10V17.5v-3.333zm-1.667 1.666h3.334-3.334zm7.5-13.333l1.905 5.714L17.5 10l-4.762 1.786-1.905 5.714-1.905-5.714L4.167 10l4.761-1.786L10.833 2.5z" />
    </svg>
   );
   break;

  case "translate":
   iconBeforeSVG = (
    <svg
     className="w-5 h-5 text-white"
     fill="none"
     stroke="currentColor"
     xmlns="http://www.w3.org/2000/svg"
     strokeWidth="1.5"
     strokeLinecap="round"
     strokeLinejoin="round"
    >
     <path d="M2.5 4.167h10M7.5 2.5v1.667M10.417 15h5.833m-7.083 2.5l4.166-8.333L17.5 17.5M10.626 4.167c-.807 4.808-3.901 8.841-8.126 10.94m5.873-3.024A15.018 15.018 0 015.343 7.5l3.03 4.583z" />
    </svg>
   );
   break;

  case "import":
   iconBeforeSVG = (
    <svg
     className="w-5 h-5 text-gray-600"
     fill="none"
     stroke="currentColor"
     xmlns="http://www.w3.org/2000/svg"
     strokeWidth="1.5"
     strokeLinecap="round"
     strokeLinejoin="round"
    >
     <path d="M10 8.333v5-5zm0 5l-2.5-2.5 2.5 2.5zm0 0l2.5-2.5-2.5 2.5zm4.167 4.167H5.833a1.667 1.667 0 01-1.666-1.667V4.167A1.667 1.667 0 015.833 2.5h4.655c.221 0 .433.088.59.244l4.511 4.512a.833.833 0 01.244.589v7.988a1.667 1.667 0 01-1.666 1.667z" />
    </svg>
   );
   break;

  case "arrow-left":
   iconBeforeSVG = (
    <svg
     className="w-5 h-5 text-gray-600"
     fill="currentColor"
     xmlns="http://www.w3.org/2000/svg"
    >
     <path d="M4.508 9.167H17.5a.833.833 0 110 1.666H4.508l4.417 4.417a.833.833 0 01-1.183 1.167l-5.834-5.834a.833.833 0 010-1.166l5.834-5.834A.833.833 0 018.925 4.75L4.5 9.167h.008z" />
    </svg>
   );
   break;

  case "arrow-right":
   iconBeforeSVG = (
    <svg
     className="w-5 h-5 text-gray-600"
     fill="currentColor"
     xmlns="http://www.w3.org/2000/svg"
    >
     <path d="M15.492 10.833H2.5a.834.834 0 010-1.666h12.992L11.075 4.75a.834.834 0 111.183-1.167l5.834 5.834a.833.833 0 010 1.166l-5.834 5.834a.833.833 0 01-1.183-1.167l4.417-4.417z" />
    </svg>
   );
   break;

  default:
   break;
 }

 // ICON AFTER
 let iconAfterSVG;
 switch (iconAfter) {
  case "options":
   iconAfterSVG = (
    <svg
     className="w-5 h-5 text-gray-500"
     fill="currentColor"
     xmlns="http://www.w3.org/2000/svg"
    >
     <path d="M3.333 12.5a2.5 2.5 0 110-5 2.5 2.5 0 010 5zm0-1.667a.834.834 0 100-1.667.834.834 0 000 1.667zM10 12.5a2.5 2.5 0 110-5 2.5 2.5 0 010 5zm0-1.667a.834.834 0 100-1.667.834.834 0 000 1.667zm6.667 1.667a2.5 2.5 0 110-5 2.5 2.5 0 010 5zm0-1.667a.833.833 0 100-1.666.833.833 0 000 1.666z" />
    </svg>
   );
   break;

  case "add":
   iconAfterSVG = (
    <svg
     className="w-5 h-5 text-gray-600"
     fill="currentColor"
     xmlns="http://www.w3.org/2000/svg"
    >
     <path d="M10 18a8 8 0 110-16 8 8 0 010 16zm0-1.6a6.4 6.4 0 100-12.8 6.4 6.4 0 000 12.8zm.8-7.2h1.6a.8.8 0 110 1.6h-1.6v1.6a.8.8 0 11-1.6 0v-1.6H7.6a.8.8 0 010-1.6h1.6V7.6a.8.8 0 011.6 0v1.6z" />
    </svg>
   );
   break;

  default:
   break;
 }

 return (
  <button
   disabled={disabled}
   className={`${classBase} ${cssClasses} ${classSize} ${classColour} ${classDisabled} relative ${tooltip !==
    "" && "tooltip"}`}
   onClick={onClick}
   data-tooltip={tooltip}
  >
   <span className="flex items-center">
    {iconBeforeSVG}
    {iconBeforeSVG && text ? <span className="ml-1">{text}</span> : text}
    {iconAfter ? <span className="ml-1">{iconAfterSVG}</span> : null}
   </span>
  </button>
 );
}

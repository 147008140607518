import React from "react";
import PropTypes from "prop-types";

import Button from "./Button";
import Spinner from "./Spinner";

import { t } from "../i18n/language";
import { Cross } from "./utils/svgs";

export default function Modal({
  title,
  subtitle,
  width,
  acceptButtonText,
  acceptShow,
  acceptDisabled,
  cancelButtonText,
  cancelShow,
  cancelDisabled,
  show,
  onClose,
  onAccept,
  children,
  working,
}) {
  const handleClose = () => {
    return onClose();
  };

  const handleAccept = () => {
    onAccept();
  };

  if (!show) {
    return null;
  }

  return (
    <div
      onKeyDownCapture={(e) => {
        if (e.key === "Escape") return handleClose();
      }}
      tabIndex="-1"
      className="fixed flex items-center top-0 left-0 w-screen h-screen z-1000"
    >
      <div
        className={`${width} max-h-screen overflow-auto px-8 py-8 mx-auto z-1002 bg-white opacity-100 rounded-lg border border-gray-200 shadow-2xl relative`}
      >
        <button
          autoFocus
          onClick={handleClose}
          className="mx-6 my-5 px-1 py-1 absolute top-0 right-0 hover:bg-gray-200 rounded-full focus:outline-none focus:shadow-outline"
        >
          <Cross />
        </button>
        <h2 className="font-bold text-lg text-center">{title}</h2>
        {subtitle && <h3 className="mt-2 text-sm text-center">{subtitle}</h3>}
        <div className="mt-10 relative">
          {working && (
            <div className="absolute w-full h-full bg-white opacity-75 z-1002">
              <div className="h-full opacity-100 flex justify-center items-center">
                <Spinner />
              </div>
            </div>
          )}
          {children}
        </div>
        <div className="mt-10 flex justify-end">
          {cancelShow && (
            <div className="mr-4">
              <Button
                text={cancelButtonText}
                onClick={handleClose}
                disabled={cancelDisabled}
                colour="white"
              />
            </div>
          )}
          {acceptShow && (
            <Button
              text={acceptButtonText}
              onClick={handleAccept}
              disabled={acceptDisabled}
            />
          )}
        </div>
      </div>
      <div
        onClick={handleClose}
        className="fixed w-screen h-screen bg-gray-900 opacity-50 z-1000"
      ></div>
    </div>
  );
}

export function WhatHappensNextModal({ show, onAccept }) {
  return (
    <Modal
      title="Processing..."
      show={show}
      acceptButtonText="Okay"
      onAccept={onAccept}
    >
      <div>
        We are now processing this request. Best to check back in a while.
      </div>
    </Modal>
  );
}

export function ErrorModal({ text, show, onClose }) {
  return <div>{text}</div>;
}

Modal.propTypes = {
  title: PropTypes.string.isRequired,
  acceptButtonText: PropTypes.string.isRequired,
  onAccept: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
};

Modal.defaultProps = {
  show: false,
  onClose: () => {
    console.warn("Modal wants to close but onClose is not defined!");
  },
  title: "Modal Title",
  width: "w-72",
  working: false,
  cancelShow: false,
  cancelDisabled: true,
  cancelButtonText: t("Cancel"),
  acceptShow: true,
  acceptDisabled: false,
  acceptButtonText: t("Approve"),
  onAccept: () => {
    console.warn("Modal wants to Accept, but onAccept is not defined!");
  },
};

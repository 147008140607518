const emptyCustomField = {
 is_sign: false,
 master: null,
 sign: [],
 audio_description: [],
 transcript: []
};

export default emptyCustomField;

// https://stream.wallenmedia.se/lab/plugin/newobject.html

// {
//     "is_sign": false,
//     "master": "bcid",
//     "sign": [
//       {
//         "status": "draft, processing, uploaded, approved, disabled",
//         "id": "(bc video id)",
//         "label": "English(US)"
//       }
//     ],
//     "audio_description": [
//       {
//         "lang": "en- US",
//         "label": "English(US)",
//         "status": "draft, processing, uploaded, approved, disabled"
//       }
//     ],
//     "transcript": [
//       {
//         "lang": "en-US",
//         "Label": "English(US)",
//         "status": "draft, processing, uploaded, approved, disabled"
//       },
//       {
//         "lang": "en-UK",
//         "Label": "English(UK)",
//         "status": "draft, processing, uploaded, approved, disabled"
//       }
//     ]
//   }

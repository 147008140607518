import { i18n } from "./languageFile";

/**
 * Function to determine the language used by the user.
 * 1) First checks for localStorge option.
 * 2) Then checks for browsers default language.
 * 3) Finally will default to 'en'
 */
const dashboardLanguage = () => {
  let lang = "";

  if (localStorage.getItem("language") !== null) {
    lang = localStorage.getItem("language");
  } else if (navigator.language.slice(0, 2) !== null) {
    lang = navigator.language.slice(0, 2);
  } else {
    lang = "en";
  }

  if (lang === "") {
    return "en";
  } else {
    return lang;
  }
};

/**
 * Checks the language file for avalible languages.
 * @returns {Array<String>} Currently ['sv','en']
 */
const avalibleLanguages = () => {
  let languages = [];
  for (var k in i18n) {
    languages.push(k);
  }
  return languages;
};

/**
 * This is the translate function which takes in an 'en' string and determines if there is a translated version to show instead
 * @param  {} string - String with 'en' text to translate
 */
export const t = (string) => {
  const langObj =
    i18n[dashboardLanguage()] !== undefined
      ? i18n[dashboardLanguage()]
      : i18n.en;

  const translation = langObj[string] !== undefined ? langObj[string] : string;

  if (
    langObj[string] === undefined &&
    window.location &&
    dashboardLanguage() !== "en"
  ) {
    console.log(
      `🌍 No ${dashboardLanguage()} translation for '${string}' on ${
        window.location.pathname
      }`
    );
  }

  return translation;
};

export const handleLanguageChange = (lang) => {
  localStorage.setItem("language", lang);
  return window.location.reload(false);
};

export default dashboardLanguage;

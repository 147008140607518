function insertItem(array, action) {
 let newArray = array.slice();
 newArray.splice(action.index, 0, action.item);
 return newArray;
}
function removeItem(array, action) {
 let newArray = array.slice().filter((item) => item.id !== action.id);
 //  console.log(newArray);
 return newArray;
}

export default function notifications(state = [], action) {
 switch (action.type) {
  case "ADD_NOTIFICATION":
   return insertItem(state, action);

  case "REMOVE_NOTIFICATION":
   return removeItem(state, action);
  default:
   return state;
 }
}

import { useEffect, useState } from "react";
import { useParams, NavLink } from "react-router-dom";
import logo from "../img/equalplay_logo_green.svg";
import Spinner from "./Spinner";
import { getAccountPlayerId } from "./utils/publicFunctions";

function PreviewPage() {
  const { accountId, videoId } = useParams();
  const [playerId, setPlayerId] = useState();

  useEffect(() => {
    if (accountId) {
      getAccountPlayerId(accountId)
        .then((id) => {
          setPlayerId(id);
        })
        .catch((err) => console.error(err));
    } else {
      console.error("Could not determin account ID");
    }
  }, [accountId]);

  useEffect(() => {
    if (playerId) {
      const script = document.createElement("script");

      script.src = `https://players.brightcove.net/${accountId}/${playerId}_default/index.min.js`;
      script.async = true;
      script.defer = true;

      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
  }, [playerId, accountId]);

  return (
    <>
      <header className="flex items-center h-10 bg-equalplay-dark text-white">
        <nav className="flex justify-between container mx-auto px-5 items-center relative">
          <div className="flex items-center">
            <NavLink to={process.env.PUBLIC_URL + "/"} className="flex">
              <div>
                <img className="w-6 h-6" src={logo} alt="Equal Play Logo" />
              </div>
              <span className="hidden md:inline-block ml-2 text-white font-equal-play">
                Equal Play
              </span>
            </NavLink>
          </div>
        </nav>
      </header>
      <main className="container mx-auto max-w-3xl px-5 py-5">
        <div className="bg-white rounded-lg border border-gray-200 shadow-2xl relative">
          {!playerId && (
            <div className="flex flex-col h-20 items-center justify-center">
              <Spinner />
              <h5 className="text-base font-equal-play">
                Preparing preview player
              </h5>
            </div>
          )}
          {playerId && (
            <video-js
              data-account={accountId}
              data-player={playerId}
              data-embed="default"
              controls=""
              data-video-id={videoId}
              data-playlist-id=""
              data-application-id="Equal Play"
              data-show-draft="true"
              class="vjs-fluid"
            >
              <span className="h-0 inline-block pt-16x9 bg-gray-100"></span>
            </video-js>
          )}
        </div>
      </main>
    </>
  );
}

export default PreviewPage;

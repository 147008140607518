function toggleTags(array, action) {
 let newArray = array.slice().map((tag) => {
  if (tag.name === action.tag.name) {
   return action.tag;
  } else {
   return tag;
  }
 });
 //  console.log(newArray);
 return newArray;
}

function addMessage(array = [], action) {
 let newArray = array.slice();

 if (
  newArray.filter((item) => {
   return item.job_id === action.message.job_id;
  }).length === 0
 ) {
  newArray.push(action.message);
 } else {
  newArray = newArray.map((item) => {
   if (item.job_id === action.message.job_id) {
    return { ...action.message };
   } else {
    return item;
   }
  });
 }

 //  console.log(array, newArray);
 return newArray;
}

export default function userReducer(state = {}, action) {
 switch (action.type) {
  case "ADD_USER":
   return Object.assign({}, state, { ...action.user });

  case "REMOVE_USER":
   return {};

  case "TOGGLE_FILTER":
   return Object.assign({}, state, {
    default_filter_tags: [...toggleTags(state.default_filter_tags, action)],
   });

  case "ADD_MESSAGE":
   return Object.assign({}, state, {
    messages: [...addMessage(state.messages, action)],
   });

  default:
   return state;
 }
}

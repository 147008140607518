import React, { Fragment, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useComboKeys } from "react-combo-keys";
import { debounce } from "lodash";

import Button from "../Button";
import Modal from "../Modal";
import * as editorActions from "../../actions/editor-actions";
import { secondsToHms } from "../utils/timeToTime";
import { t } from "../../i18n/language";

export default function QuickActions({ videoId, player }) {
  const [cue, setCue] = useState({});
  const [currentCueText, setCurrentCueText] = useState("");
  const [lineOne, setLineOne] = useState(0);
  const [lineTwo, setLineTwo] = useState(0);
  const [isPlayingAndSettingCue, setIsPlayingAndSettingCue] = useState(false);

  // MODALS
  const [deleteModal, setDeleteModal] = useState(false);

  const currentProject = useSelector((state) => state.projects[videoId]);
  const selectedLang = useSelector(
    (state) => state.projects_user_specific[videoId].selectedLang
  );
  const selectedTrack = useSelector(
    (state) => state.projects_user_specific[videoId].selectedTrack
  );
  const selectedCue = useSelector(
    (state) => state.projects_user_specific[videoId].selectedCue
  );

  var textArea = React.createRef();

  const handleTextUpdate = (text) => {
    editorActions.updateCue(
      videoId,
      selectedCue,
      { text: text },
      { selectedLang, selectedTrack, selectedCue }
    );
  };

  const handleDebounce = debounce(handleTextUpdate, 250);

  const selectTextArea = () => {
    document.getElementById("textArea").focus();
    document.getElementById("textArea").select();
  };

  const deselectTextArea = () => {
    document.getElementById("textArea").blur();
  };

  const handlePlayCreateCue = () => {
    setIsPlayingAndSettingCue(true);
    editorActions.addCue(
      videoId,
      {
        start: player.currentTime() + 0.01,
        end: player.currentTime() + 1,
        text: t("New cue"),
      },
      { selectedLang, selectedTrack, selectedCue }
    );
    player.play();
  };

  const handlePauseEndCue = () => {
    setIsPlayingAndSettingCue(true);
    player.pause();
    player.currentTime(player.currentTime() - 0.01);
    editorActions.updateCue(
      videoId,
      selectedCue,
      {
        end: player.currentTime(),
      },
      { selectedLang, selectedTrack, selectedCue }
    );
    selectTextArea();
    setIsPlayingAndSettingCue(false);
  };

  const handleReplayCue = () => {
    player.currentTime(cue.start);
    player.play();

    player.setTimeout(() => {
      player.pause();
      selectTextArea();
      player.currentTime(cue.end);
    }, (cue.end - cue.start) * 1000 + 250);
  };

  const handleSelectNextCue = (focus) => {
    if (currentProject[selectedLang][selectedTrack].cues.length === 0)
      return null;
    const currentCue = currentProject[selectedLang][selectedTrack].cues.filter(
      (cue) => cue.id === selectedCue
    );

    const currentCueIndex = currentProject[selectedLang][
      selectedTrack
    ].cues.indexOf(currentCue[0]);

    const idOfNextCue =
      currentCueIndex ===
      currentProject[selectedLang][selectedTrack].cues.length - 1
        ? selectedCue
        : currentProject[selectedLang][selectedTrack].cues[currentCueIndex + 1]
            .id;

    editorActions.selectCue(videoId, idOfNextCue);
    player.currentTime(
      currentProject[selectedLang][selectedTrack].cues.filter(
        (cue) => cue.id === idOfNextCue
      )[0].start + 0.01
    );
    if (focus) {
      return selectTextArea();
    }
  };

  const handleSelectPrevCue = (focus) => {
    //If have a selected cue on one track, then switch to another with fewer cues, this can explode, why does the cues rely on ID at all?
    if (currentProject[selectedLang][selectedTrack].cues.length === 0)
      return null;

    const currentCue = currentProject[selectedLang][selectedTrack].cues.filter(
      (cue) => cue.id === selectedCue
    );

    const currentCueIndex = currentProject[selectedLang][
      selectedTrack
    ].cues.indexOf(currentCue[0]);

    let _idOfPrevCue;

    if (currentCueIndex < 0) {
      //It is -1 since the selected cue is in another track
      console.info("Current Cue is in another track:", currentCue);
      let _time = player.currentTime();

      var closest = currentProject[selectedLang][selectedTrack].cues.reduce(
        function (prev, curr) {
          return Math.abs(curr.start - _time) < Math.abs(prev.start - _time)
            ? curr
            : prev;
        }
      );

      console.log("Closest:", closest);
      _idOfPrevCue = closest.id;
    } else {
      _idOfPrevCue =
        currentCueIndex === 0
          ? selectedCue
          : currentProject[selectedLang][selectedTrack].cues[
              currentCueIndex - 1
            ].id;
    }

    // const idOfPrevCue =
    //   currentCueIndex === 0
    //     ? selectedCue
    //     : currentProject[selectedLang][selectedTrack].cues[currentCueIndex - 1]
    //         .id;

    editorActions.selectCue(videoId, _idOfPrevCue);
    player.currentTime(
      currentProject[selectedLang][selectedTrack].cues.filter(
        (cue) => cue.id === _idOfPrevCue
      )[0].start + 0.01
    );
    if (focus) {
      return selectTextArea();
    }
  };

  useComboKeys(
    {
      space: (e) => {
        // PLAY or PAUSE
        e.preventDefault();
        if (player.paused()) return player.play();
        else return player.pause();
      },
      right: (e) => {
        // SEEK 0.01s FORWARD
        e.preventDefault();
        player.currentTime(player.currentTime() + 0.01);
      },
      left: (e) => {
        // SEEK 0.01s BACKWARD
        e.preventDefault();
        player.currentTime(player.currentTime() - 0.01);
      },
      "shift+right": (e) => {
        // SEEK 0.1s FORWARD
        e.preventDefault();
        player.currentTime(player.currentTime() + 0.1);
      },
      "shift+left": (e) => {
        // SEEK 0.1s BACKWARD
        e.preventDefault();
        player.currentTime(player.currentTime() - 0.1);
      },
      "ctrl+right": (e) => {
        e.preventDefault();
        handleSelectNextCue();
      },
      "ctrl+left": (e) => {
        e.preventDefault();
        handleSelectPrevCue();
      },
      "shift+down": (e) => {
        // SEEK 0.1s FORWARD
        e.preventDefault();
        handleSelectNextCue(true);
      },
      "shift+up": (e) => {
        // SEEK 0.1s FORWARD
        e.preventDefault();
        handleSelectPrevCue(true);
      },
      n: (e) => {
        // ADD CUE AT PLAYHEAD
        e.preventDefault();
        editorActions.addCue(
          videoId,
          {
            start: player.currentTime() + 0.01,
            end: player.currentTime() + 1,
            text: t("New cue"),
          },
          { selectedLang, selectedTrack, selectedCue }
        );
        selectTextArea();
      },
      i: (e) => {
        // ADD IN POINT AND START PLAYING
        e.preventDefault();
        handlePlayCreateCue();
      },
      o: (e) => {
        // ADD OUT POINT AND STOP PLAYING
        // OR EXTEND THE CURRENT CUE
        e.preventDefault();
        handlePauseEndCue();
      },
      r: (e) => {
        // ADD CUE AT PLAYHEAD
        e.preventDefault();
        handleReplayCue();
      },
      d: (e) => {
        // DELETE CURRENTLY SELECTED CUE
        e.preventDefault();
        setDeleteModal(true);
      },
      enter: (e) => {
        e.preventDefault();
        selectTextArea();
      },
    },
    {},
    []
  );

  useEffect(() => {
    const lines = currentCueText ? currentCueText.split("\n") : ["", ""];

    setLineOne(lines[0].length);

    if (lines.length > 1) {
      setLineTwo(lines[1].length);
    } else {
      setLineTwo(0);
    }
    // eslint-disable-next-line
  }, [currentCueText]);

  //  useEffect(() => {
  //   if (
  //    currentProject[selectedLang] &&
  //    currentProject[selectedLang][selectedTrack] &&
  //    currentProject[selectedLang][selectedTrack].cues
  //   ) {
  //    const currentCue = currentProject[selectedLang][selectedTrack].cues.filter(
  //     cue => cue.id === selectedCue
  //    )[0];
  //    //  setCue(currentCue);
  //    setCurrentCueText(currentCue.text);
  //   }
  //   // eslint-disable-next-line
  //  }, [selectedCue, currentProject[selectedLang]]);

  useEffect(() => {
    if (selectedLang && selectedTrack) {
      const c = currentProject[selectedLang][selectedTrack].cues.filter(
        (cue) => cue.id === selectedCue
      )[0];
      setCue(c);
      if (c && c.text) {
        setCurrentCueText(
          currentProject[selectedLang][selectedTrack].cues.filter(
            (cue) => cue.id === selectedCue
          )[0].text
        );
      }
    }
    // eslint-disable-next-line
  }, [selectedLang, selectedTrack, selectedCue]);

  return (
    <Fragment>
      <div>
        <h2 className="text-gray-600 font-light text-sm">
          {t("Quick actions")}
        </h2>
        {/* ROW 1 */}
        <div className="flex mt-2">
          <Button
            iconBefore="add"
            size="small"
            colour="white"
            disabled={selectedTrack === "" ? true : false}
            onClick={() => {
              editorActions.addCue(
                videoId,
                {
                  start: player.currentTime(),
                  end: player.currentTime() + 1,
                  text: t("New cue"),
                },
                { selectedLang, selectedTrack, selectedCue }
              );
              textArea.focus();
              textArea.select();
            }}
            tooltip={t("Add new cue")}
          />
          <div className="ml-2">
            <div className={`${isPlayingAndSettingCue ? "hidden" : "inline"}`}>
              <Button
                text=" + "
                size="small"
                colour="white"
                iconBefore="play"
                iconAfter="add"
                onClick={handlePlayCreateCue}
                disabled={selectedTrack === "" ? true : false}
                tooltip={t("Play + Add new cue")}
              />
            </div>
            <div className={`${!isPlayingAndSettingCue ? "hidden" : "inline"}`}>
              <Button
                text={` + ${t("Edit cue")}`}
                size="small"
                colour="white"
                iconBefore="pause"
                onClick={handlePauseEndCue}
                disabled={selectedTrack === "" ? true : false}
                tooltip={t("Pause + edit cue")}
              />
            </div>
          </div>
          <div className="ml-2">
            <Button
              size="small"
              colour="white"
              iconBefore="replay"
              onClick={handleReplayCue}
              disabled={!cue ? true : false}
              tooltip={t("Replay cue")}
            />
          </div>
          <div className="ml-2">
            <Button
              size="small"
              colour="white"
              iconBefore="delete"
              onClick={() => {
                setDeleteModal(true);
              }}
              disabled={selectedCue === "" ? true : false}
              tooltip={t("Delete cue")}
            />
          </div>
          <div className="ml-2">
            <Button
              size="small"
              colour="white"
              iconBefore="arrow-left"
              onClick={handleSelectPrevCue}
              disabled={selectedCue === "" ? true : false}
              tooltip={t("Previous cue")}
            />
          </div>
          <div className="ml-2">
            <Button
              size="small"
              colour="white"
              iconBefore="arrow-right"
              onClick={handleSelectNextCue}
              disabled={selectedCue === "" ? true : false}
              tooltip={t("Next cue")}
            />
          </div>
          <Modal
            title={`${t("Delete cue")}?`}
            acceptButtonText={t("Delete")}
            show={deleteModal}
            onAccept={() => {
              setDeleteModal(false);
              editorActions.removeCue(videoId, {
                selectedLang,
                selectedTrack,
                selectedCue,
              });
            }}
            onClose={() => {
              setDeleteModal(false);
            }}
          >
            <div>{t("Are you sure you want to delete this cue?")}</div>
            <span className="px-1 mt-4 inline-block bg-gray-200">
              {cue ? cue.text : null}
            </span>
          </Modal>
          {/* <div className="ml-2">
        <Button text="Split at playhead" size="small" colour="white" />
       </div> */}
        </div>
      </div>
      <div>
        <h2 className="mt-4 text-gray-600 font-light text-sm">
          {t("Cue editor")}
        </h2>
        <div className="mt-2 flex text-xxs text-gray-500">
          <div className="px-1 py-1 bg-white border border-gray-200 rounded">
            <span className="uppercase">{t("In")}</span>
            <span className="ml-1 text-gray-600">
              {cue ? secondsToHms(cue.start) : "00:00"}
            </span>
          </div>
          <div className="ml-1 px-1 py-1 bg-white border border-gray-200 rounded">
            <span className="uppercase">{t("Out")}</span>
            <span className="ml-1 text-gray-600">
              {cue ? secondsToHms(cue.end) : "00:00"}
            </span>
          </div>
          <div className="ml-1 px-1 py-1 bg-white border border-gray-200 rounded">
            <span className="uppercase">{t("Duration")}</span>
            <span className="ml-1 text-gray-600">
              {cue ? secondsToHms(cue.end - cue.start) : "00:00"}
            </span>
          </div>
          <div
            className={`ml-1 px-1 py-1 bg-white border border-gray-200 rounded ${
              lineOne > 31 ? "border-red-300" : ""
            }`}
          >
            <span className="uppercase">{t("Line")} 1</span>
            <span className="ml-1 text-gray-600">{lineOne}</span>
          </div>
          <div
            className={`ml-1 px-1 py-1 bg-white border border-gray-200 rounded ${
              lineTwo > 31 ? "border-red-300" : ""
            }`}
          >
            <span className="uppercase">{t("Line")} 2</span>
            <span className="ml-1 text-gray-600">{lineTwo}</span>
          </div>
        </div>
        <textarea
          id="textArea"
          className="mt-2 px-5 py-5 w-full text-lg rounded border border-gray-200 focus:outline-none focus:border focus:border-gray-400 focus:shadow-outline"
          type="input"
          rows="2"
          ref={(input) => {
            textArea = input;
          }}
          //   value={
          //    currentProject[selectedLang][selectedTrack].cues
          //     ? currentProject[selectedLang][selectedTrack].cues.filter(
          //        cue => cue.id === selectedCue
          //       ).length > 0
          //       ? currentProject[selectedLang][selectedTrack].cues.filter(
          //          cue => cue.id === selectedCue
          //         )[0].text
          //       : ""
          //     : ""
          //   }
          value={currentCueText}
          onChange={(e) => {
            setCurrentCueText(e.target.value);
            handleDebounce(e.target.value);
          }}
          onBlur={() => editorActions.saveProject(videoId, false)}
          onKeyDown={(e) => {
            if (e.keyCode === 27) {
              deselectTextArea();
            }
          }}
        />
        {/* <textarea
     id="textArea"
     className="mt-2 px-5 py-5 w-96 text-lg rounded border border-gray-200 focus:outline-none focus:border focus:border-gray-400 focus:shadow-md"
     type="input"
     rows="2"
     value={currentCueText}
    /> */}
      </div>
    </Fragment>
  );
}

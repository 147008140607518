import React, { Fragment, useEffect } from "react";
import { Routes, Route, Link, Navigate, useParams } from "react-router-dom";
import {
  ContentfulClient,
  ContentfulProvider,
  useContentful,
} from "react-contentful";
import { BLOCKS, MARKS, INLINES } from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

import Navbar from "./Navbar";
import Notification from "./Notification";
import TwoColWithSidebar, { Sidebar, Main } from "./layouts/TwoColWithSidebar";
import SidebarSection from "./layouts/SidebarSection";
import SidebarHeader from "./layouts/SidebarHeader";
import SidebarLink from "./layouts/SidebarLink";
import Spinner from "./Spinner";
import dashboardLanguage, { t } from "../i18n/language";

const contentfulClient = new ContentfulClient({
  accessToken: "hxGN4dsRMbTCjiXPYb_b_u98aEPCWrq50zlsPuR1nEM",
  space: "6ibetg2fxe0k",
});

const contentfulLocales = ["en", "sv"]; //The current locales in contentful

export default function Support() {
  let params = useParams();

  // console.log(params);

  useEffect(() => {
    const script = document.createElement("script");

    script.src = `//players.brightcove.net/2346984629001/CqpQjnNE_default/index.min.js`;
    script.async = true;
    // script.defer = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [params]);

  return (
    <Fragment>
      <Navbar />
      <Notification />
      <ContentfulProvider client={contentfulClient}>
        <Routes>
          <Route path="/" element={<Navigate to="/support/home" />}></Route>
          <Route path="/:slug" element={<SupportPage />} />
        </Routes>
      </ContentfulProvider>
    </Fragment>
  );
}

const SupportPage = (props) => {
  const { slug } = useParams();
  return (
    <TwoColWithSidebar>
      <Sidebar>
        <Sections />
      </Sidebar>
      <Main>
        <Page slug={slug} />
      </Main>
    </TwoColWithSidebar>
  );
};

const sections = [
  { name: "Support", icon: "support" },
  { name: "Dashboard", icon: "home" },
  { name: "Videos", icon: "play" },
  { name: "Transcript editor", icon: "transcript_editor" },
  { name: "Audio description", icon: "audio_description" },
  { name: "Sign language", icon: "sign" },
  { name: "Settings", icon: "settings" },
];

const Sections = () => {
  let lang = dashboardLanguage();

  if (contentfulLocales.indexOf(lang) < 0) {
    lang = "en";
  }

  const { data, error, fetched, loading } = useContentful({
    contentType: "supportDocument",
    locale: lang === "en" ? "en-US" : lang,
  });

  if (loading || !fetched) {
    // console.log(`Loading: ${loading}, fetched: ${fetched}`);
    return null;
  }

  if (error) {
    console.error(error);
    return null;
  }

  if (!data) {
    return <p>{t("No pages found")}.</p>;
  }

  return (
    <>
      {sections.map((s, index) => {
        return (
          <div className={`${index > 0 && "mt-4"}`} key={s.name}>
            <SidebarSection>
              <SidebarHeader text={t(s.name)} icon={s.icon} />
              <div className="mt-4 flex flex-col items-start">
                {data.items.map((page) => {
                  const { section, title, slug = "" } = page.fields;
                  if (section === s.name) {
                    return (
                      <SidebarLink
                        title={title}
                        to={`/support/${slug}`}
                        key={slug}
                      />
                    );
                  }
                  return null;
                })}
              </div>
            </SidebarSection>
          </div>
        );
      })}
    </>
  );
};

const Page = (props) => {
  const { slug } = props;

  let lang = dashboardLanguage();

  if (contentfulLocales.indexOf(lang) < 0) {
    lang = "en";
  }

  const { data, error, fetched, loading } = useContentful({
    contentType: "supportDocument",
    query: {
      "fields.slug[in]": slug,
      locale: lang === "en" ? "en-US" : lang,
    },
  });

  if (loading || !fetched) {
    return <Spinner />;
  }

  if (error) {
    console.error(error);
    return null;
  }

  if (!data || data.items.length < 1) {
    return <p>{t("Page does not exist")}.</p>;
  }

  const {
    title = "",
    document,
    section,
    brightcoveVideoId = "",
  } = data.items[0].fields;

  const docInHTML = documentToReactComponents(document, options);

  // Process and pass in the loaded `data` necessary for your page or child components.
  return (
    <div className="text-gray-800">
      <p className="uppercase text-xs font-semibold text-gray-600">
        {t(section)}
      </p>
      <h1 className="text-2xl font-semibold">{title}</h1>

      {/* VIDEO */}
      {brightcoveVideoId && (
        <div className="max-w-2xl mt-6 mx-auto">
          <video-js
            data-account="2346984629001"
            data-player="CqpQjnNE"
            data-embed="default"
            data-video-id={brightcoveVideoId}
            class="vjs-fluid"
            controls
          >
            <span className="h-0 inline-block pt-16x9 bg-gray-100"></span>
          </video-js>
        </div>
      )}

      {/* ARTICLE */}
      <div className="mt-4 leading-relaxed">{docInHTML}</div>

      {/* CALL TO ACTION */}
      <SupportCTA />
    </div>
  );
};

const SupportCTA = () => {
  return (
    <div className="mt-10 px-6 py-4 text-sm flex justify-between items-center rounded border border-gray-20 bg-white">
      <div>
        {t("Didn't find what you were looking for? Feel free to")}{" "}
        <a
          className="border-b border-gray-300 hover:border-gray-500"
          href="mailto:info@equalplay.eu"
        >
          {t("reach out over email")}
        </a>
        .
      </div>
    </div>
  );
};

const Code = ({ children }) => (
  <span className="bg-gray-200 inline text-gray-600 rounded-sm px-1 text-sm">
    {children}
  </span>
);

const Heading2 = ({ children }) => (
  <h2 className="mt-6 mb-1 font-semibold text-lg">{children}</h2>
);

const Paragraph = ({ children }) => <p className="mt-2">{children}</p>;

const Bold = ({ children }) => <span className="font-medium">{children}</span>;

const Image = ({ node, children }) => {
  const { file, title } = node.data.target.fields;
  return (
    <div className="mb-2 mt-2 mx-auto rounded">
      <img
        className="shadow-md mx-auto border border-gray-200 rounded"
        src={file.url}
        alt={title}
      />
    </div>
  );
};

const InlineLink = ({ node, children }) => {
  const { uri } = node.data;
  if (uri.includes("mailto")) {
    return <a href={uri}>{children}</a>;
  } else {
    return (
      <Link to={uri} className="font-medium border-b border-gray-300">
        {children}
      </Link>
    );
  }
};

const options = {
  renderMark: {
    [MARKS.CODE]: (text) => <Code>{text}</Code>,
    [MARKS.BOLD]: (text) => <Bold>{text}</Bold>,
  },
  renderNode: {
    [BLOCKS.HEADING_2]: (node, children) => <Heading2>{children}</Heading2>,
    [BLOCKS.PARAGRAPH]: (node, children) => <Paragraph>{children}</Paragraph>,
    [BLOCKS.UL_LIST]: (node, children) => (
      <ul className="list-disc ml-5">{children}</ul>
    ),
    [INLINES.HYPERLINK]: (node, children) => (
      <InlineLink node={node}>{children}</InlineLink>
    ),
    [BLOCKS.EMBEDDED_ASSET]: (node, children) => (
      <Image node={node}>{children}</Image>
    ),
  },
  //  renderText: (text) => text.replace("!", "?"),
};

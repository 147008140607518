import React, { Fragment, useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useFirestore } from "react-redux-firebase";
import moment from "moment";

import Button from "./Button";
import Input from "./Input";
import Modal from "./Modal";
import Navbar from "./Navbar";
import Notification, { addNotification } from "./Notification";
import TwoColWithSidebar, { Sidebar, Main } from "./layouts/TwoColWithSidebar";
import SidebarSection from "./layouts/SidebarSection";
import SidebarHeader from "./layouts/SidebarHeader";
import SidebarLink from "./layouts/SidebarLink";
import { t } from "../i18n/language";
import LanguageSelector from "./settings/LanguageSelector";
import Spinner from "./Spinner";
import { Tick } from "./utils/svgs";

export default function Settings() {
  // ADMIN STATES
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const [addUserModalOpen, setAddUserModalOpen] = useState(false);
  const [addingUser, setAddingUser] = useState(false);
  const [orgUsers, setOrgUsers] = useState([]);
  const [userLimit, setUserLimit] = useState(undefined);
  const [userToRemove, setUserToRemove] = useState({});
  const [removeUserModalOpen, setRemoveUserModalOpen] = useState(false);
  const [removingUser, setRemovingUser] = useState(false);
  const [usage, setUsage] = useState(undefined);

  const firestore = useFirestore();
  const currentUser = useSelector((state) => state.user);
  const location = useLocation();

  console.log("Location:", location);
  // setOrgId to be current users org id

  const handleAddUser = (e) => {
    //   e.preventDefault();
    // bc_accounts: [...currentUser.bc_accounts],
    setAddingUser(true);
    const newUser = {
      first_name: firstName,
      last_name: lastName,
      email,
      is_admin: isAdmin,
      org_id: currentUser.org_id,
      bc_accounts: [{ ...currentUser.bc_accounts[0] }],
      selectedAccount: { ...currentUser.bc_accounts[0] },
      recent_projects: [],
      default_filter_tags: [...currentUser.default_filter_tags],
      admin_email: currentUser.email,
      admin_name: `${currentUser.first_name} ${currentUser.last_name}`,
      created_at: new Date(),
    };

    return firestore
      .collection("pendingUsers")
      .add(newUser)
      .then((res) => {
        setAddingUser(false);
        setFirstName("");
        setLastName("");
        setEmail("");
        setIsAdmin(false);

        return addNotification(
          t("User added"),
          `${firstName} ${t(
            "has been added as a user. They will receive an email shortly."
          )}`,
          undefined,
          email
        );
      })
      .catch((err) => {
        console.log(err);
        setAddingUser(false);
        return addNotification(
          t("Error"),
          t(`We couldn't add the user right now. Please try again.`),
          "bg-red-400",
          email
        );
      });
  };

  const handleRemoveUser = async () => {
    setRemovingUser(true);

    await firestore
      .collection("users")
      .doc(userToRemove.uid)
      .update({ org_id: null })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.error(err);
        setRemovingUser(false);
      });

    return firestore
      .collection("removingUsers")
      .add(userToRemove)
      .then((res) => {
        setRemovingUser(false);
        setUserToRemove({});
        setRemoveUserModalOpen(false);

        return addNotification(
          t("User removed!"),
          `${userToRemove.first_name} ${t("has been removed.")}`,
          undefined
        );
      })
      .catch((err) => {
        console.log(err);
        setAddingUser(false);
        setRemovingUser(false);
        return addNotification(
          t("Error"),
          t(`We couldn't remove the user right now. Please try again.`),
          "bg-red-400",
          email
        );
      });
  };

  useEffect(() => {
    console.info("Mounting Settings.js");
    if (currentUser && currentUser.is_admin) {
      firestore.onSnapshot(
        { collection: "users", where: ["org_id", "==", currentUser.org_id] },
        (snapshot) => {
          setOrgUsers(snapshot.docs);
        }
      );

      firestore.onSnapshot(
        { collection: "orgs", doc: currentUser.org_id },
        (snapshot) => {
          setUserLimit(snapshot.data().user_limit);
        }
      );

      firestore.onSnapshot(
        { collection: "billing", doc: currentUser.org_id },
        (snapshot) => {
          if (!snapshot.exists) return null;
          const {
            billing_month = 1,
            month_totals = [],
            asr_limit = 0,
            sl_limit = 0,
          } = snapshot.data();
          const year = new Date().getFullYear();
          const monthWithLeadingZero =
            billing_month <= 9 ? `0${billing_month}` : billing_month;
          const currentCycleStart = parseInt(`${year}${monthWithLeadingZero}`);

          const validMonth = [
            parseInt(moment().format("YYYYMM")),
            parseInt(moment().subtract(1, "months").format("YYYYMM")),
            parseInt(moment().subtract(2, "months").format("YYYYMM")),
            parseInt(moment().subtract(3, "months").format("YYYYMM")),
            parseInt(moment().subtract(4, "months").format("YYYYMM")),
            parseInt(moment().subtract(5, "months").format("YYYYMM")),
            parseInt(moment().subtract(6, "months").format("YYYYMM")),
            parseInt(moment().subtract(7, "months").format("YYYYMM")),
            parseInt(moment().subtract(8, "months").format("YYYYMM")),
            parseInt(moment().subtract(9, "months").format("YYYYMM")),
            parseInt(moment().subtract(10, "months").format("YYYYMM")),
            parseInt(moment().subtract(11, "months").format("YYYYMM")),
          ];

          //  console.log(year, month, parseInt(`${year}${month}`) - 12);

          const monthsThisCycle = month_totals.filter((m) => {
            if (
              validMonth.includes(m.year_month) &&
              m.year_month >= currentCycleStart
            ) {
              return m;
            }
          });

          const asrUsage = monthsThisCycle.reduce(
            (accumulator, currentValue) => {
              return accumulator + currentValue.asr;
            },
            0
          );

          const slUsage = monthsThisCycle.reduce(
            (accumulator, currentValue) => {
              return accumulator + currentValue.sl;
            },
            0
          );

          const asrPercentage = (100 * asrUsage) / asr_limit;
          const slPercentage = (100 * slUsage) / sl_limit;

          //  CONVERT FROM SECONDS TO MINUTES
          setUsage({
            ...snapshot.data(),
            asrUsage: (asrUsage / 60).toFixed(0),
            slUsage: (slUsage / 60).toFixed(0),
            asr_limit: (asr_limit / 60).toFixed(0),
            sl_limit: (sl_limit / 60).toFixed(0),
            asrPercentage,
            slPercentage,
          });
        }
      );
    }
    return () => {
      console.info("UnMounting Settings.js");
    };
    // eslint-disable-next-line
  }, [currentUser]);

  return (
    <Fragment>
      <Navbar />
      <Notification />
      <TwoColWithSidebar>
        <Sidebar>
          <SidebarSection>
            <SidebarHeader text={t("Settings")} icon="settings" />
            <div className="mt-4 flex flex-col items-start">
              <SidebarLink title={t("Personal")} to={`/settings`} />
              <SidebarLink title={t("Language")} to={`/settings/language`} />
            </div>
          </SidebarSection>
          {!currentUser.is_admin ? null : (
            <div className="mt-4">
              <SidebarSection>
                <SidebarHeader text={t("Admin")} icon="admin" />
                <div className="mt-4 flex flex-col items-start">
                  <SidebarLink title={t("Users")} to={`/settings/users`} />
                  <SidebarLink title={t("Usage")} to={`/settings/usage`} />
                  {/* <SidebarLink
         title="Billing"
         label="billing"
         activeTab={activeTab}
         onClick={tab => {
          setActiveTab(tab);
         }}
        /> */}
                </div>
              </SidebarSection>
            </div>
          )}
        </Sidebar>
        <Main>
          <div className="">
            <Routes>
              <Route
                path="/"
                element={
                  <Fragment>
                    <h2 className="text-xl font-semibold">
                      {t("Personal details")}
                    </h2>
                    <div className="w-56">
                      <Input
                        css="mt-2"
                        label={t("First name")}
                        placeholder="Loading..."
                        value={currentUser.first_name}
                        disabled={true}
                      />
                      <Input
                        css="mt-2"
                        label={t("Last name")}
                        placeholder="Loading..."
                        value={currentUser.last_name}
                        disabled={true}
                      />
                      <Input
                        css="mt-2"
                        label={t("Email")}
                        placeholder="Loading..."
                        value={currentUser.email}
                        disabled={true}
                      />
                    </div>
                  </Fragment>
                }
              />
              <Route
                path="/language"
                element={
                  <Fragment>
                    <h2 className="text-xl font-semibold">{t("Language")}</h2>
                    <div className="mt-2">
                      <LanguageSelector colour="white" />
                    </div>
                  </Fragment>
                }
              />
              <Route
                path="/users"
                element={
                  currentUser.is_admin ? (
                    <Fragment>
                      <h2 className="text-xl font-semibold">{t("Users")}</h2>

                      <div className="mt-2 bg-white rounded border border-gray-200 text-sm">
                        <div className="px-3 py-2 grid grid-cols-11 text-xs text-gray-600 uppercase font-semibold leading-relaxed">
                          <div className="col-span-4">{t("Name")}</div>
                          <div className="col-span-4">{t("Email")}</div>
                          <div className="mx-auto col-span-2">{t("Admin")}</div>
                          <div className="mx-auto col-span-1">
                            {t("Remove")}
                          </div>
                        </div>
                        {orgUsers.map((user) => {
                          return (
                            <div
                              className="px-3 py-4 grid grid-cols-11 border-t border-gray-200"
                              key={user.data().uid}
                            >
                              <div className="col-span-4 font-semibold">
                                {user.data().first_name} {user.data().last_name}{" "}
                                {user.data().uid === currentUser.uid
                                  ? t("(me)")
                                  : null}
                              </div>
                              <div className="col-span-4">
                                {user.data().email}
                              </div>
                              <div className="flex items-center col-span-2">
                                {user.data().is_admin ? <Tick /> : null}
                              </div>
                              <div className="mx-auto col-span-1">
                                <Button
                                  colour="white"
                                  iconBefore="delete"
                                  size="small"
                                  disabled={user.data().uid === currentUser.uid}
                                  onClick={() => {
                                    setUserToRemove({
                                      uid: user.data().uid,
                                      first_name: user.data().first_name,
                                      last_name: user.data().last_name,
                                      org_id: user.data().org_id,
                                    });
                                    return setRemoveUserModalOpen(true);
                                  }}
                                />
                              </div>
                            </div>
                          );
                        })}
                      </div>

                      <div className="mt-4 flex items-center">
                        <Button
                          text={t("Add new user")}
                          colour="white"
                          onClick={() => {
                            if (orgUsers.length < userLimit) {
                              return setAddUserModalOpen(true);
                            } else {
                              return null;
                            }
                          }}
                          disabled={orgUsers.length >= userLimit ? true : false}
                          tooltip={
                            orgUsers.length >= userLimit
                              ? t("You've added all availble users")
                              : ""
                          }
                        />
                        {userLimit && (
                          <div className="ml-4 text-sm text-gray-500 italic">
                            {t("You've added")} {orgUsers.length}/{userLimit}{" "}
                            {t("available users.")}.
                          </div>
                        )}
                      </div>

                      {/* ADD NEW USER MODAL */}
                      <Modal
                        width="w-88"
                        title={t("Add new user")}
                        acceptButtonText={t("Add user")}
                        acceptDisabled={
                          firstName === "" ||
                          lastName === "" ||
                          !email.includes("@")
                            ? true
                            : false
                        }
                        show={addUserModalOpen}
                        onAccept={handleAddUser}
                        cancelDisabled={false}
                        onClose={() => {
                          setFirstName("");
                          setLastName("");
                          setEmail("");
                          setIsAdmin(false);
                          setAddUserModalOpen(false);
                        }}
                        working={addingUser}
                      >
                        <form
                          onSubmit={(e) => {
                            // handleFormSubmit(e);
                            console.log("Adding new user");
                          }}
                        >
                          <Input
                            css="mt-2"
                            label={t("First name")}
                            name="firstname"
                            type="text"
                            value={firstName}
                            placeholder={t("First name")}
                            onChange={(e) => {
                              setFirstName(e.target.value);
                            }}
                          />
                          <Input
                            css="mt-2"
                            label={t("Last name")}
                            name="lastname"
                            type="text"
                            value={lastName}
                            placeholder={t("Last name")}
                            onChange={(e) => {
                              setLastName(e.target.value);
                            }}
                          />
                          <Input
                            css="mt-2"
                            label={t("Email")}
                            name="email"
                            type="email"
                            value={email}
                            placeholder="name@email.com"
                            onChange={(e) => {
                              setEmail(e.target.value);
                            }}
                          />
                          <Input
                            css="mt-2"
                            label={t("Admin")}
                            name="isAdmin"
                            type="checkbox"
                            checked={isAdmin}
                            placeholder="admin"
                            onChange={(e) => {
                              setIsAdmin(e.target.checked);
                            }}
                            required={false}
                          />
                        </form>
                      </Modal>

                      {/* REMOVE USER MODAL */}
                      <Modal
                        width="w-88"
                        title={t("Remove user")}
                        acceptButtonText={t("Remove")}
                        show={removeUserModalOpen}
                        onAccept={handleRemoveUser}
                        cancelDisabled={false}
                        onClose={() => {
                          setUserToRemove({});
                          setRemoveUserModalOpen(false);
                        }}
                        working={removingUser}
                      >
                        <form
                          onSubmit={(e) => {
                            // handleFormSubmit(e);
                            console.log("Deleting user");
                          }}
                        >
                          <div>
                            {t("Are you sure you want to remove this user?")}
                          </div>
                          <span className="px-1 mt-4 inline-block bg-gray-200">
                            {userToRemove.first_name} {userToRemove.last_name}
                          </span>
                        </form>
                      </Modal>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <h2 className="text-xl font-semibold">{t("Users")}</h2>
                    </Fragment>
                  )
                }
              />
              <Route
                path="/usage"
                element={
                  currentUser.is_admin ? (
                    <Fragment>
                      <h2 className="text-xl font-semibold">{t("Usage")}</h2>

                      {usage !== undefined ? (
                        <Fragment>
                          <div className="mt-4">
                            <h3 className="font-semibold">
                              {t("Automatic Speech Recognition (ASR)")}
                            </h3>
                            <div className="flex flex-col w-full">
                              <div className="mt-2 h-4 relative">
                                <div
                                  style={{
                                    width: `${
                                      usage.asrPercentage >= 100
                                        ? 100
                                        : usage.asrPercentage
                                    }%`,
                                    zIndex: 1,
                                  }}
                                  className={`bg-equalplay-light h-4 absolute right-0 left-0 rounded
            ${
              usage.asrPercentage >= 100 ? "bg-red-600" : "bg-equalplay-light"
            }`}
                                ></div>
                                <div className="bg-gray-200 h-4 absolute right-0 left-0 rounded"></div>
                              </div>
                              <span className="mt-1 text-sm text-gray-600">
                                {usage.asrUsage} {t("of")} {usage.asr_limit}{" "}
                                {t("minutes")}
                              </span>
                            </div>
                          </div>

                          <div className="mt-4">
                            <h3 className="font-semibold">
                              {t("Sign language")}
                            </h3>
                            <div className="flex flex-col w-full">
                              <div className="mt-2 h-4 relative">
                                <div
                                  style={{
                                    width: `${
                                      usage.slPercentage >= 100
                                        ? 100
                                        : usage.slPercentage
                                    }%`,
                                    zIndex: 1,
                                  }}
                                  className={`bg-equalplay-light h-4 absolute right-0 left-0  rounded
            ${usage.slPercentage >= 100 ? "bg-red-600" : "bg-equalplay-light"}`}
                                ></div>
                                <div className="bg-gray-200 h-4 absolute right-0 left-0 rounded"></div>
                              </div>
                              <span className="mt-1 text-sm text-gray-600">
                                {usage.slUsage} {t("of")} {usage.sl_limit}{" "}
                                {t("minutes")}
                              </span>
                            </div>
                          </div>

                          <div className="mt-10 text-xs text-gray-600">
                            {t("Next billing cycle starts")}:{" "}
                            {t(moment.months(usage.billing_month - 1))}
                          </div>
                        </Fragment>
                      ) : (
                        <Spinner />
                      )}
                    </Fragment>
                  ) : (
                    <Fragment>
                      <h2 className="text-xl font-semibold">{t("Usage")}</h2>
                    </Fragment>
                  )
                }
              />
            </Routes>
          </div>
        </Main>
      </TwoColWithSidebar>
    </Fragment>
  );
}

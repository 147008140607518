import { addNotification } from "../components/Notification";
import { db } from "../components/auth/firebase";

import { t } from "../i18n/language";

function removeLang(array, action) {
 let newArray = array.slice().filter((item) => item !== action.lang);
 return newArray;
}

function addCue(array, action) {
 let newArray = array.slice();
 newArray.splice(action.index, 0, action.cue);
 return newArray.sort((a, b) => a.start - b.start);
}

function removeCue(array, action) {
 let newArray = array
  .slice()
  .filter((item) => item.id !== action.selected.selectedCue);
 return newArray.sort((a, b) => a.start - b.start);
}

function updateCue(array, action) {
 let newArray = array.slice().map((item) => {
  if (item.id !== action.id) {
   return item;
  } else {
   let newItem = {
    ...item,
    ...action.cue,
   };
   return newItem;
  }
 });
 return newArray;
}

function saveProject(videoId, notify, data) {
 db
  .collection("projects")
  .doc(videoId)
  .update(data[videoId])
  .then(function () {
   if (notify === true) {
    // addNotification(t("Changes saved"), t("Nice work!"), undefined);
   } else {
    console.log(`Changes saved in ${videoId}`);
   }
  })
  .catch(function (error) {
   console.error(error);
   addNotification(
    t("We couldn't save your changes"),
    t("Please try again"),
    "bg-red-600"
   );
  });

 return data;
}

function addRecentProject(videoId, uid, recent_projects, state) {
 db
  .collection("users")
  .doc(uid)
  .update({ recent_projects: [videoId, ...recent_projects.slice(0, 3)] })
  .then(function () {
   console.log(`Recent project added: ${videoId}`);
  })
  .catch(function (error) {
   console.error(error);
  });

 return state;
}

export default function projects(state = {}, action) {
 let id = action.videoId;
 let lang = action.lang;
 let track = action.track;
 let selected = action.selected;
 let array;

 switch (action.type) {
  case "ADD_PROJECT":
   return Object.assign({}, state, { ...action.project });

  case "SAVE_PROJECT":
   return saveProject(id, action.notify, state);

  case "ADD_RECENT_PROJECT":
   return addRecentProject(id, action.uid, action.recent_projects, state);

  case "ADD_LANG":
   if (state[id][lang]) {
    return state;
   } else {
    return {
     ...state,
     [id]: {
      ...state[id],
      languages: [...state[id].languages, lang],
      [lang]: {
       label: action.label,
       subtitles: { order: 1, cues: [] },
       captions: { order: 2, cues: [] },
       chapters: { order: 3, cues: [] },
       descriptive: { order: 4, cues: [] },
      },
     },
    };
   }

  case "REMOVE_LANG":
   return {
    ...state,
    [id]: {
     ...state[id],
     languages: [...removeLang(state[id].languages, action)],
     [lang]: null,
    },
   };

  case "ADD_TRACK":
   return {
    ...state,
    [id]: {
     ...state[id],
     [lang]: {
      ...state[id][lang],
      [track]: { ...state[id][lang][track], cues: [] },
     },
    },
   };

  case "REMOVE_TRACK":
   if (!state[id][lang]) {
    return state;
   } else {
    if (!state[id][lang][track]) {
     return state;
    } else {
     return {
      ...state,
      [id]: {
       ...state[id],
       [lang]: {
        ...state[id][lang],
        [track]: {
         ...state[id][lang][track],
         cues: [],
        },
       },
      },
     };
    }
   }

  case "ADD_CUE":
   array = state[id][selected.selectedLang][selected.selectedTrack].cues;
   return {
    ...state,
    [id]: {
     ...state[id],
     [selected.selectedLang]: {
      ...state[id][selected.selectedLang],
      [selected.selectedTrack]: {
       ...state[id][selected.selectedLang][selected.selectedTrack],
       cues: [...addCue(array, action)],
      },
     },
    },
   };

  case "REMOVE_CUE":
   array = state[id][selected.selectedLang][selected.selectedTrack].cues;
   return {
    ...state,
    [id]: {
     ...state[id],
     [selected.selectedLang]: {
      ...state[id][selected.selectedLang],
      [selected.selectedTrack]: {
       ...state[id][selected.selectedLang][selected.selectedTrack],
       cues: [...removeCue(array, action)],
      },
     },
    },
   };

  case "UPDATE_CUE":
   array = state[id][selected.selectedLang][selected.selectedTrack].cues;
   return {
    ...state,
    [id]: {
     ...state[id],
     [selected.selectedLang]: {
      ...state[id][selected.selectedLang],
      [selected.selectedTrack]: {
       ...state[id][selected.selectedLang][selected.selectedTrack],
       cues: [...updateCue(array, action)],
      },
     },
    },
   };

  case "ADD_ASR":
   return {
    ...state,
    [id]: {
     ...state[id],
     asr_triggered: true,
    },
   };

  default:
   return state;
 }
}

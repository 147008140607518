import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { SelectLanguage } from "../Select";
import Button from "../Button";
import Modal from "../Modal";
import FileUploader from "../FileUploader";
import {
  bcIngest,
  bcCustomFieldUpdate,
  bcDeleteAudioTrack,
} from "../utils/brightcoveRequest";
import Warning from "../Warning";
import BCStatusChange from "./BCStatusChange";
import { t } from "../../i18n/language";
import { addNotification } from "../Notification";

export default function Languages({
  type,
  customfield,
  videoId,
  deliveryType,
}) {
  const [tracks, setTracks] = useState([]);
  const [addModalIsOpen, setAddModalIsOpen] = useState(false);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [deletingTrack, setDeletingTrack] = useState(false);
  const [addingLanguage, setAddingLanguage] = useState(false);

  // FORM
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [label, setLabel] = useState("");

  const selectedAccount = useSelector((state) => state.user.selectedAccount);

  const addLanguageToVideo = (path) => {
    setAddingLanguage(true);
    const url = path;
    const language = selectedLanguage;
    const variant = "descriptive";

    const obj = {
      audio_tracks: {
        merge_with_existing: true,
        masters: [
          {
            url,
            language,
            variant,
          },
        ],
      },
    };

    bcIngest(selectedAccount, videoId, obj).then((res) => {
      // IF THE INGEST WORKED
      if (res.status === 200) {
        bcCustomFieldUpdate(selectedAccount, videoId, "audio_description", {
          lang: language,
          label: label,
          status: "draft",
        });
        setAddingLanguage(false);
        setAddModalIsOpen(false);

        // IF THE INGEST FAILED
      } else {
        // MODAL SHOWING SOMETHING WENT WRONG
        setAddingLanguage(false);
        setAddModalIsOpen(false);
        return addNotification(
          t("Something went wrong"),
          t("Please try again in a few moments"),
          "bg-red-600"
        );
      }
    });
  };

  const handleTrackDelete = () => {
    setDeletingTrack(true);

    bcDeleteAudioTrack(
      selectedAccount,
      videoId,
      `${tracks[0].lang}_descriptive`
    ).then((res) => {
      //  setDeletingTrack(false);
      //  setAddModalIsOpen(false);
      // return window.location.reload();

      bcCustomFieldUpdate(
        selectedAccount,
        videoId,
        "audio_description",
        tracks[0],
        true
      );
    });

    setTimeout(() => {
      setDeleteModalIsOpen(false);
      setDeletingTrack(false);
    }, 3000);
  };

  useEffect(() => {
    //  let tracks;
    if (customfield) {
      const parsed = JSON.parse(customfield);
      if (type === "audio_description") {
        setTracks(parsed.audio_description);
      } else if (type === "sign") {
        setTracks(parsed.sign);
      }
    }
    // eslint-disable-next-line
  }, [customfield]);
  return (
    <div className="ml-8 w-1/2">
      <h2 className="text-gray-600">{t("Descriptive track")}</h2>

      {deliveryType !== "dynamic_origin" ? (
        <Warning title={t("Dynamic Delivery issue")} customCss="mt-2">
          This video is not enabled for Dynamic Delivery. This is needed for
          multiple audio tracks. Please retranscode to enable Dynamic Delivery
          in your{" "}
          <a
            href={`https://studio.brightcove.com/products/videocloud/media/videos/${videoId}`}
            target="_blank"
            className="underline"
            rel="noreferrer"
          >
            Brightcove account
          </a>
          .
        </Warning>
      ) : tracks.length === 0 ? (
        <div className="mt-3 text-gray-400 italic text-sm">
          {t("No tracks added")}
        </div>
      ) : (
        tracks.map((track, i) => {
          return (
            <div
              className="mt-6 grid grid-cols-6 gap-2 items-center justify-between"
              key={i}
            >
              <h3 className="w-20 font-semibold col-span-2 whitespace-no-wrap">
                {track.label}{" "}
                {/* {track.lang ? (
         <span className="text-xs font-normal">
          {" "}
          <br />({track.lang})
         </span>
        ) : null} */}
              </h3>
              <div className="w-full col-span-3">
                {/* <Select
         selected={track.status}
         onSelect={choice => {
          updateStatus(choice);
         }}
        /> */}
                <BCStatusChange
                  videoId={videoId}
                  type={type}
                  lang={tracks[0].lang}
                  label={tracks[0].label}
                />
              </div>
              <div className="flex ">
                {type === "sl" ? (
                  <div className="ml-4">
                    <Button
                      text=""
                      iconBefore="settings"
                      colour="white"
                      size="small"
                    />
                  </div>
                ) : null}
                <div className="ml-4">
                  <Button
                    text=""
                    iconBefore="delete"
                    colour="white"
                    size="small"
                    onClick={() => setDeleteModalIsOpen(true)}
                  />
                </div>
              </div>
            </div>
          );
        })
      )}
      <div className="mt-6">
        {deliveryType !== "dynamic_origin" ? null : tracks.length >=
          1 ? null : (
          <Button
            text={t("Add track")}
            colour="white"
            onClick={() => {
              setAddModalIsOpen(true);
            }}
          />
        )}

        {/* UPLOAD MODAL */}
        <Modal
          title={t("Upload a new file")}
          width="w-88"
          acceptButtonText={t('"Add"')}
          acceptShow={false}
          show={addModalIsOpen}
          onClose={() => {
            setAddModalIsOpen(false);
          }}
          onAccept={addLanguageToVideo}
          working={addingLanguage}
        >
          <div>
            <h2>{t("Language")}</h2>
            <div className="mt-2">
              <SelectLanguage
                selected={(selected) => {
                  setSelectedLanguage(JSON.parse(selected).code.split("-")[0]);
                  setLabel(JSON.parse(selected).label);
                }}
              />
            </div>
            <h2 className="mt-4">{t("File")}</h2>
            <div className="mt-2">
              <FileUploader
                disabled={selectedLanguage === "" ? true : false}
                disabledText={t("Choose language first.")}
                videoId={videoId}
                pathToUploadedFile={(path) => {
                  return addLanguageToVideo(path);
                }}
              />
            </div>
          </div>
        </Modal>

        {/* DELETE MODAL */}
        <Modal
          title={t("Delete")}
          show={deleteModalIsOpen}
          cancelDisabled={false}
          onClose={() => {
            setDeleteModalIsOpen(false);
          }}
          onAccept={() => handleTrackDelete()}
          acceptButtonText={t("Delete")}
          working={deletingTrack}
        >
          <div>{t("Are you sure you want to delete this track?")}</div>
        </Modal>
      </div>
    </div>
  );
}

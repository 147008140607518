export default function videos(state = [], action) {
 switch (action.type) {
  case "ADD_VIDEO":
   return Object.assign({}, state, action.video);

  case "UPDATE_STATUS":
   let id = action.video;
   return {
    ...state,
    [id]: {
     ...state[id],
     custom_fields: { ...state[id].custom_fields, equalplay: action.equalplay },
    },
   };

  default:
   return state;
 }
}

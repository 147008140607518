import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import * as editorActions from "../../actions/editor-actions";
import { t } from "../../i18n/language";

export default function Cue({
  id,
  start,
  end,
  text,
  index,
  track,
  emsPerSecond,
  emsPerSecondArray,
  prevCue = { end: null },
}) {
  const videoId = useParams().id;
  const selectedCue = useSelector(
    (state) => state.projects_user_specific[videoId].selectedCue
  );

  return (
    <button
      className={`${
        selectedCue === id ? "border-gray-700 shadow-lg" : "border-gray-400"
      } absolute cursor-pointer bg-white border rounded focus:outline-none z-50`}
      style={{
        width: `${(end - start) * emsPerSecondArray[emsPerSecond].seconds}em`,
        marginLeft: `${start * emsPerSecondArray[emsPerSecond].seconds}em`,
      }}
      onMouseDown={(e) => {
        if (selectedCue !== id) {
          editorActions.selectCue(videoId, id);
        }
      }}
      id={id}
      data-index={index}
      data-text={text}
      data-cue={true}
      data-track={track}
    >
      <div className="flex items-center relative pointer-events-none">
        <div
          // id="l"

          className={`${
            selectedCue === id ? "border-gray-700 shadow-lg" : "border-gray-400"
          } 
     ${
       prevCue.end !== null && prevCue.end > start
         ? "text-red-200"
         : "text-white"
     }
     py-3 w-3 h-full absolute  left-0 cursor-colresize pointer-events-auto border-r`}
          // data-index={index}
          // data-text={text}
          data-cue={true}
          data-handle="l"
          // data-track={track}
        >
          L
        </div>
        <div
          className={`px-3 py-1 w-full h-12 text-left overflow-hidden text-sm ${
            prevCue.end !== null && prevCue.end > start ? "bg-red-200 " : ""
          }`}
          style={{ textOverflow: "ellipsis" }}
          // data-index={index}
          // data-text={text}
          // data-cue={true}
          // data-track={track}
        >
          <span className="pl-3 pr-3 inline-block">
            {text.replace("↵", "\n")}
            {prevCue.end !== null && prevCue.end > start ? (
              <span className="text-xxs text-red-600 uppercase">
                <br />
                {t("Overlapping cue")}
              </span>
            ) : (
              ""
            )}
          </span>
        </div>
        <div
          // id="r"

          className={`${
            selectedCue === id ? "border-gray-700 shadow-lg" : "border-gray-400"
          }
     ${
       prevCue.end !== null && prevCue.end > start
         ? "text-red-200"
         : "text-white"
     }
     py-3 w-3 h-full absolute right-0 text-white cursor-colresize pointer-events-auto border-l `}
          // data-index={index}
          // data-text={text}
          data-handle="r"
          data-cue={true}
          // data-track={track}
        >
          R
        </div>
      </div>
    </button>
  );
}

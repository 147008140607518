// https://github.com/meikidd/iso-639-1/blob/master/src/data.js

export const langCodes = [
 {
  code: "om",
  label: "Afaan Oromoo",
 },
 {
  code: "aa",
  label: "Afaraf",
 },
 {
  code: "af",
  label: "Afrikaans",
 },
 {
  code: "ak",
  label: "Akan",
 },
 {
  code: "an",
  label: "aragonés",
 },
 {
  code: "ig",
  label: "Asụsụ Igbo",
 },
 {
  code: "gn",
  label: "Avañe'ẽ",
 },
 {
  code: "ae",
  label: "avesta",
 },
 {
  code: "ay",
  label: "aymar aru",
 },
 {
  code: "az",
  label: "azərbaycan dili",
 },
 {
  code: "bm",
  label: "bamanankan",
 },
 {
  code: "jv",
  label: "basa Jawa",
 },
 {
  code: "su",
  label: "Basa Sunda",
 },
 {
  code: "bi",
  label: "Bislama",
 },
 {
  code: "bs",
  label: "bosanski jezik",
 },
 {
  code: "br",
  label: "brezhoneg",
 },
 {
  code: "ca",
  label: "català",
 },
 {
  code: "cs",
  label: "čeština",
 },
 {
  code: "ch",
  label: "Chamoru",
 },
 {
  code: "ny",
  label: "chiCheŵa",
 },
 {
  code: "sn",
  label: "chiShona",
 },
 {
  code: "co",
  label: "corsu",
 },
 {
  code: "cy",
  label: "Cymraeg",
 },
 {
  code: "da",
  label: "dansk",
 },
 {
  code: "se",
  label: "Davvisámegiella",
 },
 {
  code: "de",
  label: "Deutsch",
 },
 {
  code: "dv",
  label: "Dhivehi",
 },
 {
  code: "nv",
  label: "Diné bizaad",
 },
 {
  code: "et",
  label: "eesti",
 },
 {
  code: "na",
  label: "Ekakairũ Naoero",
 },
 {
  code: "en",
  label: "English",
 },
 {
  code: "es",
  label: "Español",
 },
 {
  code: "eo",
  label: "Esperanto",
 },
 {
  code: "eu",
  label: "euskara",
 },
 {
  code: "ee",
  label: "Eʋegbe",
 },
 {
  code: "to",
  label: "faka Tonga",
 },
 {
  code: "mg",
  label: "fiteny malagasy",
 },
 {
  code: "fo",
  label: "føroyskt",
 },
 {
  code: "fr",
  label: "Français",
 },
 {
  code: "fy",
  label: "Frysk",
 },
 {
  code: "ff",
  label: "Fulfulde",
 },
 {
  code: "ga",
  label: "Gaeilge",
 },
 {
  code: "gv",
  label: "Gaelg",
 },
 {
  code: "sm",
  label: "gagana fa'a Samoa",
 },
 {
  code: "gd",
  label: "Gàidhlig",
 },
 {
  code: "gl",
  label: "galego",
 },
 {
  code: "ki",
  label: "Gĩkũyũ",
 },
 {
  code: "ho",
  label: "Hiri Motu",
 },
 {
  code: "hr",
  label: "hrvatski jezik",
 },
 {
  code: "io",
  label: "Ido",
 },
 {
  code: "rw",
  label: "Ikinyarwanda",
 },
 {
  code: "rn",
  label: "Ikirundi",
 },
 {
  code: "idn",
  label: "Indonesian",
 },
 {
  code: "ia",
  label: "Interlingua",
 },
 {
  code: "ie",
  label: "Interlingue",
 },
 {
  code: "ik",
  label: "Iñupiaq",
 },
 {
  code: "nd",
  label: "isiNdebele",
 },
 {
  code: "nr",
  label: "isiNdebele",
 },
 {
  code: "xh",
  label: "isiXhosa",
 },
 {
  code: "zu",
  label: "isiZulu",
 },
 {
  code: "is",
  label: "Íslenska",
 },
 {
  code: "it",
  label: "Italiano",
 },
 {
  code: "pl",
  label: "język polski",
 },
 {
  code: "mh",
  label: "Kajin M̧ajeļ",
 },
 {
  code: "kl",
  label: "kalaallisut",
 },
 {
  code: "kr",
  label: "Kanuri",
 },
 {
  code: "kw",
  label: "Kernewek",
 },
 {
  code: "kg",
  label: "Kikongo",
 },
 {
  code: "sw",
  label: "Kiswahili",
 },
 {
  code: "ht",
  label: "Kreyòl ayisyen",
 },
 {
  code: "kj",
  label: "Kuanyama",
 },
 {
  code: "ku",
  label: "Kurdî",
 },
 {
  code: "la",
  label: "latine",
 },
 {
  code: "lv",
  label: "latviešu valoda",
 },
 {
  code: "lb",
  label: "Lëtzebuergesch",
 },
 {
  code: "lt",
  label: "lietuvių kalba",
 },
 {
  code: "li",
  label: "Limburgs",
 },
 {
  code: "ln",
  label: "Lingála",
 },
 {
  code: "lg",
  label: "Luganda",
 },
 {
  code: "hu",
  label: "magyar",
 },
 {
  code: "mt",
  label: "Malti",
 },
 {
  code: "nl",
  label: "Nederlands",
 },
 {
  code: "no",
  label: "Norsk",
 },
 {
  code: "nb",
  label: "Norsk bokmål",
 },
 {
  code: "nn",
  label: "Norsk nynorsk",
 },
 {
  code: "oc",
  label: "occitan",
 },
 {
  code: "hz",
  label: "Otjiherero",
 },
 {
  code: "ng",
  label: "Owambo",
 },
 {
  code: "pt",
  label: "Português",
 },
 {
  code: "ty",
  label: "Reo Tahiti",
 },
 {
  code: "ro",
  label: "Română",
 },
 {
  code: "rm",
  label: "rumantsch grischun",
 },
 {
  code: "qu",
  label: "Runa Simi",
 },
 {
  code: "sc",
  label: "sardu",
 },
 {
  code: "za",
  label: "Saɯ cueŋƅ",
 },
 {
  code: "st",
  label: "Sesotho",
 },
 {
  code: "tn",
  label: "Setswana",
 },
 {
  code: "sq",
  label: "Shqip",
 },
 {
  code: "ss",
  label: "SiSwati",
 },
 {
  code: "sk",
  label: "Slovenčina",
 },
 {
  code: "sl",
  label: "Slovenski jezik",
 },
 {
  code: "so",
  label: "Soomaaliga",
 },
 {
  code: "fi",
  label: "Suomi",
 },
 {
  code: "sv",
  label: "Svenska",
 },
 {
  code: "mi",
  label: "Te reo Māori",
 },
 {
  code: "vi",
  label: "Tiếng Việt",
 },
 {
  code: "lu",
  label: "Tshiluba",
 },
 {
  code: "ve",
  label: "Tshivenḓa",
 },
 {
  code: "tr",
  label: "Türkçe",
 },
 {
  code: "tk",
  label: "Türkmen",
 },
 {
  code: "tw",
  label: "Twi",
 },
 {
  code: "fj",
  label: "Vakaviti",
 },
 {
  code: "vo",
  label: "Volapük",
 },
 {
  code: "wa",
  label: "walon",
 },
 {
  code: "tl",
  label: "Wikang Tagalog",
 },
 {
  code: "wo",
  label: "Wollof",
 },
 {
  code: "ts",
  label: "Xitsonga",
 },
 {
  code: "sg",
  label: "yângâ tî sängö",
 },
 {
  code: "yo",
  label: "Yorùbá",
 },
 {
  code: "el",
  label: "Ελληνικά",
 },
 {
  code: "av",
  label: "авар мацӀ",
 },
 {
  code: "ab",
  label: "аҧсуа бызшәа",
 },
 {
  code: "ba",
  label: "башҡорт теле",
 },
 {
  code: "be",
  label: "беларуская мова",
 },
 {
  code: "bg",
  label: "български език",
 },
 {
  code: "os",
  label: "ирон æвзаг",
 },
 {
  code: "kv",
  label: "коми кыв",
 },
 {
  code: "ky",
  label: "Кыргызча",
 },
 {
  code: "kk",
  label: "қазақ тілі",
 },
 {
  code: "mk",
  label: "македонски јазик",
 },
 {
  code: "mn",
  label: "Монгол хэл",
 },
 {
  code: "ce",
  label: "нохчийн мотт",
 },
 {
  code: "ru",
  label: "Русский",
 },
 {
  code: "sr",
  label: "српски језик",
 },
 {
  code: "tt",
  label: "татар теле",
 },
 {
  code: "tg",
  label: "тоҷикӣ",
 },
 {
  code: "uz",
  label: "Ўзбек",
 },
 {
  code: "uk",
  label: "Українська",
 },
 {
  code: "cv",
  label: "чӑваш чӗлхи",
 },
 {
  code: "cu",
  label: "ѩзыкъ словѣньскъ",
 },
 {
  code: "ka",
  label: "ქართული",
 },
 {
  code: "hy",
  label: "Հայերեն",
 },
 {
  code: "yi",
  label: "ייִדיש",
 },
 {
  code: "he",
  label: "עברית",
 },
 {
  code: "ug",
  label: "ئۇيغۇرچە‎",
 },
 {
  code: "ur",
  label: "اردو",
 },
 {
  code: "ar",
  label: "اللغة العربية",
 },
 {
  code: "ps",
  label: "پښتو",
 },
 {
  code: "fa",
  label: "فارسی",
 },
 {
  code: "ms",
  label: "هاس ملايو‎",
 },
 {
  code: "ha",
  label: "هَوُسَ",
 },
 {
  code: "ti",
  label: "ትግርኛ",
 },
 {
  code: "am",
  label: "አማርኛ",
 },
 {
  code: "ks",
  label: "कश्मीरी",
 },
 {
  code: "ne",
  label: "नेपाली",
 },
 {
  code: "pi",
  label: "पाऴि",
 },
 {
  code: "bh",
  label: "भोजपुरी",
 },
 {
  code: "mr",
  label: "मराठी",
 },
 {
  code: "sa",
  label: "संस्कृतम्",
 },
 {
  code: "sd",
  label: "सिन्धी",
 },
 {
  code: "hi",
  label: "हिन्दी",
 },
 {
  code: "as",
  label: "অসমীয়া",
 },
 {
  code: "bn",
  label: "বাংলা",
 },
 {
  code: "pa",
  label: "ਪੰਜਾਬੀ",
 },
 {
  code: "gu",
  label: "ગુજરાતી",
 },
 {
  code: "or",
  label: "ଓଡ଼ିଆ",
 },
 {
  code: "ta",
  label: "தமிழ்",
 },
 {
  code: "te",
  label: "తెలుగు",
 },
 {
  code: "kn",
  label: "ಕನ್ನಡ",
 },
 {
  code: "ml",
  label: "മലയാളം",
 },
 {
  code: "si",
  label: "සිංහල",
 },
 {
  code: "th",
  label: "ไทย",
 },
 {
  code: "lo",
  label: "ພາສາ",
 },
 {
  code: "bo",
  label: "བོད་ཡིག",
 },
 {
  code: "dz",
  label: "རྫོང་ཁ",
 },
 {
  code: "my",
  label: "ဗမာစာ",
 },
 {
  code: "km",
  label: "ខេមរភាសា",
 },
 {
  code: "iu",
  label: "ᐃᓄᒃᑎᑐᑦ",
 },
 {
  code: "oj",
  label: "ᐊᓂᔑᓈᐯᒧᐎᓐ",
 },
 {
  code: "cr",
  label: "ᓀᐦᐃᔭᐍᐏᐣ",
 },
 {
  code: "ko",
  label: "한국어",
 },
 {
  code: "ii",
  label: "ꆈꌠ꒿ Nuosuhxop",
 },
 {
  code: "zh",
  label: "中文",
 },
 {
  code: "ja",
  label: "日本語",
 },
];

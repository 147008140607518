import React, { Fragment, useState, useEffect } from "react";
import { useSelector } from "react-redux";

import Button from "../Button";
import Cue from "./Cue";
import { secondsToHms } from "../utils/timeToTime";
import * as editorActions from "../../actions/editor-actions";
import { t } from "../../i18n/language";

const zoomArray = [
  { fontSize: 100, dragRate: 16 },
  { fontSize: 80, dragRate: 16 },
  { fontSize: 70, dragRate: 16 },
  { fontSize: 60, dragRate: 16 },
  { fontSize: 50, dragRate: 16 },
];

const emsPerSecondArray = [
  {
    seconds: 20,
    multiple: 0.5,
  },
  {
    seconds: 10,
    multiple: 1,
  },
  {
    seconds: 5,
    multiple: 2,
  },
  {
    seconds: 2.5,
    multiple: 4,
  },
  {
    seconds: 1.25,
    multiple: 8,
  },
];

function isOdd(num) {
  return num % 2;
}

export default function Timeline({
  videoId,
  currentTime,
  videoDuration = 50,
  player,
}) {
  const [activeCue, setActiveCue] = useState(null);
  const [startX, setStartX] = useState(0);
  const [startMargin, setStartMargin] = useState(0);
  const [handle, setHandle] = useState(null);
  const [startWidth, setStartWidth] = useState(0);
  const [zoomLevel, setZoomLevel] = useState(2);
  const [emsPerSecond, setEmsPerSecond] = useState(2);

  const timelineLenthArray = Array.from(Array(videoDuration + 1), (x, i) => i);

  const video = useSelector((state) => state.projects[videoId]);

  const selectedLang = useSelector(
    (state) => state.projects_user_specific[videoId].selectedLang
  );
  const selectedTrack = useSelector(
    (state) => state.projects_user_specific[videoId].selectedTrack
  );
  const selectedCue = useSelector(
    (state) => state.projects_user_specific[videoId].selectedCue
  );

  const tracks = useSelector((state) => state.projects[videoId][selectedLang]);

  const handleZoomOut = () => {
    setZoomLevel(zoomLevel + 1);
    setEmsPerSecond(emsPerSecond + 1);
  };

  const handleZoomIn = () => {
    setZoomLevel(zoomLevel - 1);
    setEmsPerSecond(emsPerSecond - 1);
  };

  const handleMouseDown = (e) => {
    // IF THE TRACK IS NOT SELECTED THEN SELET IT

    if (e.target.dataset.cue !== "true") return null;

    // editorActions.selectTrack(videoId, e.target.dataset.track);

    let target;
    if (e.target.dataset.handle === "l") {
      setHandle("l");
      target = e.target.parentElement.parentElement;
    } else if (e.target.dataset.handle === "r") {
      setHandle("r");
      target = e.target.parentElement.parentElement;
    } else {
      target = e.target;
    }

    if (selectedTrack !== target.dataset.track) {
      editorActions.selectTrack(videoId, target.dataset.track);
    }

    // [Moving calculations from handleMove to decrease iterations 2022-04-07]
    target.payload = {
      previousCueEnd:
        parseInt(target.dataset.index) !== 0
          ? tracks[target.dataset.track].cues[
              parseInt(target.dataset.index) - 1
            ].end
          : 0,
      nextCueStart:
        parseInt(target.dataset.index) + 1 ===
        tracks[target.dataset.track].cues.length
          ? videoDuration + 1
          : tracks[target.dataset.track].cues[
              parseInt(target.dataset.index) + 1
            ].start,
    };

    // console.log("handleMouseDown", target, target.payload);
    setActiveCue(target);
    setStartX(e.clientX);
    setStartMargin(parseFloat(target.style.marginLeft));
    setStartWidth(parseFloat(target.style.width));
  };

  const handleMouseMove = (e) => {
    if (activeCue) {
      //console.log("handleMouseMove", activeCue.payload);
      const { previousCueEnd, nextCueStart } = activeCue.payload;

      //  return console.log("selected track", tracks[selectedTrack]);
      if (handle === "r") {
        // SHOULD NOT BE ABLE TO MOVE PAST NEXT CUE OR END OF VIDEO
        // let nextCueStart;
        // // CHECK IF CUE IS THE LAST CUE
        // if (
        //   parseInt(activeCue.dataset.index) + 1 ===
        //   tracks[selectedTrack].cues.length
        // ) {
        //   nextCueStart = videoDuration + 1;
        // } else {
        //   nextCueStart =
        //     tracks[selectedTrack].cues[parseInt(activeCue.dataset.index) + 1]
        //       .start;
        // }

        const availableWidth =
          (nextCueStart - 0.01) * emsPerSecondArray[emsPerSecond].seconds -
          parseFloat(activeCue.style.marginLeft);

        const draggedTime =
          startWidth + (e.clientX - startX) / zoomArray[zoomLevel].dragRate;

        window.requestAnimationFrame(() => {
          activeCue.style.width = `${
            draggedTime > availableWidth ? availableWidth : draggedTime
          }em`;
        });
        // activeCue.style.width = `${
        //   draggedTime > availableWidth ? availableWidth : draggedTime
        // }em`;
      } else if (handle === "l") {
        // let previousCueEnd = 0;
        // // CHECK IF CUE IS THE FIRST CUE
        // if (parseInt(activeCue.dataset.index) !== 0) {
        //   previousCueEnd =
        //     tracks[selectedTrack].cues[parseInt(activeCue.dataset.index) - 1]
        //       .end;
        // }

        const availableMargin =
          (previousCueEnd + 0.01) * emsPerSecondArray[emsPerSecond].seconds;

        const availbleWidth =
          startWidth +
          startMargin -
          (previousCueEnd + 0.01) * emsPerSecondArray[emsPerSecond].seconds;

        const draggedWidth =
          startWidth - (e.clientX - startX) / zoomArray[zoomLevel].dragRate;
        const draggedMargin =
          startMargin + (e.clientX - startX) / zoomArray[zoomLevel].dragRate;

        window.requestAnimationFrame(() => {
          activeCue.style.width = `${
            draggedWidth < availbleWidth ? draggedWidth : availbleWidth
          }em`;
          activeCue.style.marginLeft = `${
            draggedMargin > availableMargin ? draggedMargin : availableMargin
          }em`;
        });
        // activeCue.style.width = `${
        //   draggedWidth < availbleWidth ? draggedWidth : availbleWidth
        // }em`;
        // activeCue.style.marginLeft = `${
        //   draggedMargin > availableMargin ? draggedMargin : availableMargin
        // }em`;
      } else {
        // CHECK IF CUE IS THE FIRST CUE
        // let previousCueEnd = 0;
        // if (parseInt(activeCue.dataset.index) !== 0) {
        //   previousCueEnd =
        //     tracks[selectedTrack].cues[parseInt(activeCue.dataset.index) - 1]
        //       .end;
        // }
        // // CHECK IF CUE IS THE LAST CUE
        // let nextCueStart;
        // if (
        //   parseInt(activeCue.dataset.index) + 1 ===
        //   tracks[selectedTrack].cues.length
        // ) {
        //   nextCueStart = videoDuration + 1;
        // } else {
        //   nextCueStart =
        //     tracks[selectedTrack].cues[parseInt(activeCue.dataset.index) + 1]
        //       .start;
        // }

        const availableMinMargin =
          previousCueEnd * emsPerSecondArray[emsPerSecond].seconds;
        const availableMaxMargin =
          (nextCueStart - 0.0) * emsPerSecondArray[emsPerSecond].seconds -
          startWidth;
        const draggedMargin =
          startMargin + (e.clientX - startX) / zoomArray[zoomLevel].dragRate;

        // request frame improved when not inspecting the element
        window.requestAnimationFrame(() => {
          if (draggedMargin > startMargin) {
            activeCue.style.marginLeft = `${
              draggedMargin < availableMaxMargin
                ? draggedMargin
                : availableMaxMargin
            }em`;
          } else {
            activeCue.style.marginLeft = `${
              draggedMargin > availableMinMargin
                ? draggedMargin
                : availableMinMargin
            }em`;
          }
        });
        // CHECK WHAT DIRECTION THE CUE IS BEING DRAGGED
        // if (draggedMargin > startMargin) {
        //   activeCue.style.marginLeft = `${
        //     draggedMargin < availableMaxMargin
        //       ? draggedMargin
        //       : availableMaxMargin
        //   }em`;
        // } else {
        //   activeCue.style.marginLeft = `${
        //     draggedMargin > availableMinMargin
        //       ? draggedMargin
        //       : availableMinMargin
        //   }em`;
        // }
      }
      activeCue.payload.changed = startX !== e.clientX;
    }
  };

  const handleMouseUp = (e) => {
    if (activeCue && activeCue.payload.changed) {
      onCueTimeChange(e, activeCue);
    }

    setActiveCue(null);
    setStartX(0);
    setStartMargin(0);
    setStartWidth(0);
    setHandle(null);
  };

  const onCueTimeChange = (e, cue) => {
    console.log(
      parseFloat(cue.style.marginLeft) *
        emsPerSecondArray[emsPerSecond].multiple
    );
    let newStart =
      (parseFloat(cue.style.marginLeft) *
        emsPerSecondArray[emsPerSecond].multiple *
        100) /
      1000;
    let newDuration =
      (parseFloat(cue.style.width) *
        emsPerSecondArray[emsPerSecond].multiple *
        100) /
      1000;
    let newEnd = newStart + newDuration;
    console.log(
      `New start: ${newStart.toFixed(2)}s, New duration: ${newDuration.toFixed(
        2
      )}s, New end: ${newEnd.toFixed(2)}s`
    );
    // console.log(cue);
    editorActions.updateCue(
      videoId,
      cue.id,
      {
        start: parseFloat(newStart.toFixed(2)),
        end: parseFloat(newEnd.toFixed(2)),
        text: cue.dataset.text,
        id: cue.id,
      },
      { selectedLang, selectedTrack, selectedCue }
    );
  };

  let playhead = React.createRef();
  let timelineParent = React.createRef();

  useEffect(() => {
    // playhead.scrollIntoView({ inline: "center" });

    const parentRightX =
      timelineParent.getBoundingClientRect().x +
      timelineParent.getBoundingClientRect().width;
    const parentLeftX = timelineParent.getBoundingClientRect().x;

    const playheadPosition = playhead.getBoundingClientRect().x;

    if (
      playheadPosition > parentRightX - 50 ||
      playheadPosition < parentLeftX
    ) {
      playhead.scrollIntoView({ inline: "center" });
    }
    // eslint-disable-next-line
  }, [currentTime, zoomLevel]);

  return (
    <Fragment>
      <h2 className="text-gray-600 font-light text-sm">{t("Timeline")}</h2>
      <div
        className="mt-2 flex overflow-scroll bg-white rounded border border-gray-200"
        ref={(input) => {
          timelineParent = input;
        }}
        onMouseDown={(e) => {
          handleMouseDown(e);
        }}
        onMouseMove={(e) => {
          handleMouseMove(e);
        }}
        onMouseUp={(e) => {
          handleMouseUp(e);
        }}
        onMouseLeave={() => {
          //  setActiveCue(null);
        }}
      >
        <div className="w-1/8 mb-auto text-sm border-r border-r-gray-300 sticky left-0 bg-white z-90">
          <div className="w-32">
            <div className="h-6 sticky top-0 bg-white border-b border-gray-200"></div>

            {/* TRACK ACTIVATION BUTTONS */}
            {Object.entries(tracks)
              .sort(([, a], [, b]) => {
                let aCompare = a.order || 100;
                let bCompare = b.order || 100;

                //  console.log(aCompare, bCompare);

                return aCompare - bCompare;
              })
              .map(([key, value], i) => {
                if (key !== "label") {
                  return (
                    <button
                      key={key}
                      onClick={() => {
                        if (!value.cues) {
                          editorActions.addTrack(videoId, selectedLang, key);
                        }

                        return editorActions.selectTrack(videoId, key);
                      }}
                      className={`${
                        selectedTrack === key
                          ? "bg-gray-200 border-l-4 border-gray-500 font-medium"
                          : "border-b border-gray-200"
                      } w-full px-2 h-20 cursor-pointer items-baseline hover:bg-gray-200 focus:outline-none focus:shadow-outline`}
                    >
                      <span className="capitalize whitespace-no-wrap">
                        {t(key)} ({selectedLang.toUpperCase()})
                      </span>
                    </button>
                  );
                } else {
                  return (
                    <div
                      key={key}
                      className={`
           flex justify-center items-center w-full px-2 h-8 cursor-default border-b border-gray-200 bg-gray-100`}
                    >
                      <span className="capitalize whitespace-no-wrap">
                        {t("Waveform")}
                      </span>
                    </div>
                  );
                }
              })}
          </div>
        </div>
        <div
          className="flex flex-col w-auto mb-auto relative"
          style={{ fontSize: `100%` }}
        >
          {/* FULL PLAYHEAD */}
          <div
            className={`absolute mr-auto top-0 border-l border-gray-700 h-full z-80 select-none pointer-events-none`}
            style={{
              marginLeft: `${
                currentTime * emsPerSecondArray[emsPerSecond].seconds
              }em`,
              //  transition: ".2s margin-left linear"
            }}
          >
            {/* <span
       className="h-6 px-1 py-1 text-xs text-white bg-gray-700"
       style={{ marginLeft: "-21px", transition: ".2s margin-left linear" }}
      >
       {secondsToHms(currentTime)}
      </span> */}
          </div>
          {/* SECONDS ON TIMELINE */}
          <div
            className="h-6 flex sticky top-0 bg-white border-b border-gray-200 z-70 select-none cursor-pointer"
            onClick={(e) => {
              var rect = e.target.getBoundingClientRect();
              var x = e.clientX - rect.left; //x position within the element.
              var playheadTo =
                x / emsPerSecondArray[emsPerSecond].seconds / 1.6 / 10;
              player.currentTime(parseFloat(playheadTo.toFixed(1)));
              console.log(playheadTo);
            }}
          >
            <div
              className="absolute mr-auto top-0 h-full z-80 select-none pointer-events-none"
              style={{
                marginLeft: `${
                  currentTime * emsPerSecondArray[emsPerSecond].seconds
                }em`,
                // transition: ".2s margin-left linear"
              }}
            >
              <span
                className="h-6 px-1 py-1 text-xs text-white bg-gray-700"
                ref={(input) => {
                  playhead = input;
                }}
                style={{
                  marginLeft: "-21px",
                  // transition: ".2s margin-left linear"
                }}
              >
                {secondsToHms(currentTime)}
              </span>
            </div>
            {timelineLenthArray.map((second) => {
              if (second >= 0) {
                // Adjust seconds on zoom out
                if (emsPerSecond >= 4 && isOdd(second)) {
                  return null;
                }

                return (
                  <div
                    className="absolute mr-auto top-0 flex border-l border-gray-300 h-full pointer-events-none"
                    style={{
                      marginLeft: `${
                        second * emsPerSecondArray[emsPerSecond].seconds
                      }em`,
                    }}
                    key={second}
                  >
                    <span className="px-1 my-auto text-xxs text-gray-400">
                      {secondsToHms(second)}
                    </span>
                  </div>
                );
              } else {
                return null;
              }
            })}
          </div>
          {/* TRACKS FOR CUES */}
          {Object.entries(tracks)
            .sort(([, a], [, b]) => {
              let aCompare = a.order || 100;
              let bCompare = b.order || 100;

              //  console.log(aCompare, bCompare);

              return aCompare - bCompare;
            })
            .map(([key, value], trackIndex) => {
              const track = key;
              if (key !== "label") {
                return (
                  <div
                    key={trackIndex}
                    className={`${
                      selectedTrack === key ? "bg-gray-100" : "opacity-50"
                    } mx-auto flex h-20 ml-auto items-center select-none relative border-b border-gray-200 `}
                    style={{
                      width: `${
                        (videoDuration + 1) *
                        emsPerSecondArray[emsPerSecond].seconds
                      }em`,
                    }}
                  >
                    {/* // this caused severe lag on 2hour videos... to much in DOM? Background image? */}
                    {/* {timelineLenthArray.map((second) => {
                      return (
                        <div
                          className="absolute mr-auto top-0 border-l border-gray-200 h-full"
                          style={{
                            marginLeft: `${
                              second * emsPerSecondArray[emsPerSecond].seconds
                            }em`,
                          }}
                          key={second}
                        >
                          <div className="bg-green-300 inline-block"></div>
                        </div>
                      );
                    })} */}
                    {!value.cues
                      ? null
                      : value.cues.map((cue, index) => {
                          return (
                            <Cue
                              key={cue.id}
                              id={cue.id}
                              start={cue.start}
                              end={cue.end}
                              text={cue.text}
                              index={index}
                              track={track}
                              emsPerSecond={emsPerSecond}
                              emsPerSecondArray={emsPerSecondArray}
                              prevCue={value.cues[index - 1]}
                            />
                          );
                        })}
                  </div>
                );
              } else return null;
            })}

          {/* ASR WAVEFORM */}
          {video.asr && video.asr.waveform ? (
            <div
              className="h-8 bg-gray-100"
              style={{
                backgroundImage: `url('${video.asr.waveform}')`,
              }}
            ></div>
          ) : (
            <div className="h-8 bg-gray-100 flex">
              <span className="flex items-center px-4 text-gray-600 text-xs">
                {t("No waveform found. Generate one with ASR.")}
              </span>
            </div>
          )}
        </div>
      </div>
      <div className="mt-2 ml-4 flex justify-between">
        {/* <Button text="+ Add track" colour="white" size="small" /> */}

        <div className="mx-auto w-auto flex">
          <Button
            size="small"
            colour="white"
            iconBefore="zoomout"
            onClick={handleZoomOut}
            disabled={zoomLevel === zoomArray.length - 1 ? true : false}
          />
          <div className="px-2">
            <Button
              text={t("Reset")}
              size="small"
              colour="white"
              onClick={() => {
                setZoomLevel(2);
                setEmsPerSecond(2);
              }}
            />
          </div>
          <Button
            size="small"
            colour="white"
            iconBefore="zoomin"
            onClick={handleZoomIn}
            disabled={zoomLevel === 0 ? true : false}
          />
        </div>
      </div>
    </Fragment>
  );
}

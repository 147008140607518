export default function projects_user_specific(state = {}, action) {
 let id = action.videoId;
 let lang = action.lang;
 let track = action.track;

 switch (action.type) {
  case "ADD_PROJECT_USER":
   return Object.assign({}, state, { ...action.project });

  case "SELECT_LANG":
   return {
    ...state,
    [id]: {
     ...state[id],
     selectedLang: lang,
    },
   };

  case "SELECT_TRACK":
   return {
    ...state,
    [id]: {
     ...state[id],
     selectedTrack: track,
    },
   };

  case "SELECT_CUE":
   return {
    ...state,
    [id]: {
     ...state[id],
     selectedCue: action.id,
    },
   };

  default:
   return state;
 }
}

/** SVG green circle with tick */
const Tick = () => {
  return (
    <svg
      className={`h-4 w-4 mx-auto text-green-400`}
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M8 16A8 8 0 118 0a8 8 0 010 16zm0-1.6A6.4 6.4 0 108 1.6a6.4 6.4 0 000 12.8zM6.16 7.44L7.2 8.472l2.64-2.64a.8.8 0 011.12 1.136l-3.2 3.2a.8.8 0 01-1.12 0l-1.6-1.6a.8.8 0 011.12-1.136v.008z" />
    </svg>
  );
};

/** Cross for closing modal */
const Cross = () => {
  return (
    <svg
      className="w-6 h-6 text-gray-800"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M16.24 14.83a1 1 0 01-1.41 1.41L12 13.41l-2.83 2.83a1 1 0 01-1.41-1.41L10.59 12 7.76 9.17a1 1 0 011.41-1.41L12 10.59l2.83-2.83a1 1 0 011.41 1.41L13.41 12l2.83 2.83z" />
    </svg>
  );
};

/**
 * Link icon from Material Design
 * https://fonts.google.com/icons?selected=Material%20Symbols%20Outlined%3Alink%3AFILL%400%3Bwght%40400%3BGRAD%400%3Bopsz%4048
 */
const LinkIcon = () => {
  return (
    <svg
      className="w-6 h-6 text-gray-800"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
  <path
     d="M11 17H7q-2.075 0-3.537-1.463Q2 14.075 2 12t1.463-3.538Q4.925 7 7 7h4v2H7q-1.25 0-2.125.875T4 12q0 1.25.875 2.125T7 15h4Zm-3-4v-2h8v2Zm5 4v-2h4q1.25 0 2.125-.875T20 12q0-1.25-.875-2.125T17 9h-4V7h4q2.075 0 3.538 1.462Q22 9.925 22 12q0 2.075-1.462 3.537Q19.075 17 17 17Z"/>
    </svg>
  );
};

export { Tick, Cross, LinkIcon };

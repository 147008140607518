export function uploadBlob(videoId, firebase, blob, lang, kind) {
 const storageRef = firebase.storage().ref();

 let uploadTask = storageRef
  .child(
   `/${videoId}/text_tracks/${Math.floor(
    Date.now() / 1000
   )}-${lang}-${kind}.vtt`
  )
  .put(blob);

 let uploadPromise = new Promise((resolve, reject) => {
  // Register three observers:
  // 1. 'state_changed' observer, called any time the state changes
  // 2. Error observer, called on failure
  // 3. Completion observer, called on successful completion
  uploadTask.on(
   "state_changed",
   function(snapshot) {
    // Observe state change events such as progress, pause, and resume
    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
    var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    console.log("Upload is " + progress + "% done");

    switch (snapshot.state) {
     case firebase.storage.TaskState.PAUSED: // or 'paused'
      console.log("Upload is paused");
      break;
     case firebase.storage.TaskState.RUNNING: // or 'running'
      console.log("Upload is running");
      break;
     default:
      return console.log("all good");
    }
   },
   function(error) {
    // Handle unsuccessful uploads
    console.error(error);
   },
   function() {
    // Handle successful uploads on complete
    // For instance, get the download URL: https://firebasestorage.googleapis.com/...
    uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
     console.log("File available at", downloadURL);
     resolve(downloadURL);
    });
   }
  );
 });

 return uploadPromise;
}

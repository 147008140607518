import { useState } from "react";
import { useParams } from "react-router-dom";
import {
  getBcAccount,
  saveBcAccount,
  getBcAccountPlayers,
  getLatestBCPlugin,
  getLatestPlugin,
  updateBCPlayer,
} from "../utils/adminFunctions";

import Spinner from "../Spinner";
import Input from "../Input";
import Button from "../Button";
import Modal from "../Modal";
import { LinkIcon } from "../utils/svgs";
import { t } from "../../i18n/language";
import { getKey } from "../utils/helpers";

function BcAccount() {
  const [account, setAccount] = useState();
  const [name, setName] = useState();
  const [playerId, setPlayerId] = useState();
  const { bcid } = useParams();
  const [players, setPlayers] = useState();
  const [updateModal, setUpdateModal] = useState();
  const [updating, setUpdating] = useState(false);

  const testedPlayerVersion = "6.66.6";

  const handleSave = () => {
    saveBcAccount({
      account_id: account.account_id || bcid,
      name,
      player_id: playerId,
    })
      .then(() => {
        setAccount(null);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getPlayersWithPlugin = () => {
    if (!players) return [];
    const playersWithPlugin = [];
    players.forEach((player) => {
      const plugins = player.branches.master.configuration.plugins;
      let plugin = plugins && plugins.find((plug) => plug.name === "equalplay");
      if (plugin) {
        playersWithPlugin.push({ name: player.name, id: player.id, plugin });
        // console.log(player.name, player.id, plugin);
      }
    });
    return playersWithPlugin;
  };

  const getPlayers = (account) => {
    getBcAccountPlayers(account)
      .then((response) => {
        if (response.currentTokenExpires !== account.currentTokenExpires) {
          console.info("new token as well");
          let oldAccount = { ...account };
          oldAccount.currentTokenExpires = response.currentTokenExpires;
          oldAccount.currenToken = response.currentToken;
          setAccount(oldAccount);
        }
        setPlayers(response.players.items);
        console.log("Got players");
      })
      .catch((err) => {
        console.error("Error getting players", err);
      });
  };

  const filterPlayer = (id) => {
    if (!players || !id) {
      return null;
    }
    const player = players.find((p) => p.id === id);
    return player;
  };

  const updatePlayer = async (id) => {
    const scripts = [];
    const stylesheets = [];

    // const latestPlugin = await getLatestBCPlugin();
    const latestPlugin = await getLatestPlugin();
    latestPlugin.css && stylesheets.push(latestPlugin.css);
    latestPlugin.js && scripts.push(latestPlugin.js);

    const player = filterPlayer(id);
    if (!player || !latestPlugin) {
      throw new Error("Missing details for player update");
    }
    const config = { ...player.branches.master.configuration };
    config.player.template.version = testedPlayerVersion;
    config.player.template.locked = true;

    const plugin = config.plugins.find((p) => p.name === "equalplay");
    if (!plugin) {
      throw new Error("Cant find plugin");
    }
    // plugin.scripts = latestPlugin.scripts;
    // plugin.stylesheets = latestPlugin.stylesheets;
    plugin.scripts = scripts;
    plugin.stylesheets = stylesheets;

    // return console.log(
    //   "update player with config:",
    //   config,
    //   scripts,
    //   stylesheets
    // );

    return await updateBCPlayer(account, id, config);
  };

  const selectedPlayer = filterPlayer(updateModal);

  if (!account) {
    getBcAccount(bcid)
      .then((account) => {
        setAccount(account);
        setName(account?.name);
        setPlayerId(account?.player_id);
        // console.log(account);
        getPlayers(account);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  return (
    <>
      <div className="w-56">
        <h2 className="text-xl font-semibold">{bcid}</h2>
        {!account && <Spinner />}
        {account && (
          <>
            <Input
              css="mt-2"
              label={`Account name`}
              placeholder="Name the account"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
              disabled={false}
            />
            <Input
              css="mt-2"
              label={`Player ID`}
              placeholder="ID of Brightcove player"
              value={playerId}
              onChange={(e) => {
                setPlayerId(e.target.value);
              }}
              disabled={false}
            />
            <Button
              text={t("Save")}
              colour="white"
              onClick={() => {
                handleSave();
              }}
              disabled={name === account.name && playerId === account.player_id}
            />
            {/* <Button
              text={"Tests"}
              colour="white"
              onClick={() => {
                updatePlayer("27");
              }}
              disabled={false}
            /> */}
          </>
        )}
      </div>
      <div>
        <h2 className="text-xl font-semibold">Players with plugin:</h2>
        {!players && <Spinner />}
        <div className="mt-2 bg-white rounded border border-gray-200 text-sm">
          <div className="px-3 py-2 grid grid-cols-12 text-xs text-gray-600 uppercase font-semibold leading-relaxed">
            <div className="col-span-3">{t("Name")}</div>
            <div className="col-span-3">{"Player ID"}</div>
            <div className="col-span-1 mx-auto">{"Update"}</div>
            <div className="col-span-5 mx-auto">{"Version"}</div>
          </div>
          {players && (
            <>
              {getPlayersWithPlugin().map((player) => {
                // console.log(player);
                return (
                  <div
                    className="px-3 py-4 grid grid-cols-12 border-t border-gray-200"
                    key={getKey()}
                  >
                    <div className="col-span-3 font-semibold">
                      {player.name}
                    </div>
                    <div className="col-span-3">{player.id}</div>
                    <div className="col-span-1 mx-auto">
                      <button
                        type="button"
                        onClick={() => {
                          setUpdateModal(player.id);
                        }}
                      >
                        <LinkIcon />
                      </button>
                    </div>
                    <div className="col-span-5 mx-auto">
                      <p>{player.plugin.scripts.toString()}</p>
                      <p>{player.plugin.stylesheets.toString()}</p>
                    </div>
                  </div>
                );
              })}
            </>
          )}
        </div>
      </div>
      <Modal
        width={"w-96"}
        show={updateModal ? true : false}
        title={"Update player"}
        subtitle={`"${selectedPlayer ? selectedPlayer.name : "unknown"}"`}
        onClose={() => {
          setUpdateModal(null);
        }}
        onAccept={() => {
          setUpdating(true);
          updatePlayer(updateModal)
            .then((update) => {
              console.log(update);
              setUpdating(false);
              setUpdateModal(null);
              setAccount(null);
            })
            .catch((err) => {
              setUpdating(false);
              console.error("Error updating player:", err);
            });
        }}
        working={updating}
      >
        {selectedPlayer &&
          (() => {
            const config = selectedPlayer.branches.master.configuration;
            return (
              <>
                <h3 className="font-semibold">Template</h3>
                <p>{config.player.template.name}</p>
                <p>{config.player.template.version}</p>
                <p>
                  Last tested version <strong>{testedPlayerVersion}</strong>
                </p>
              </>
            );
          })()}
      </Modal>
    </>
  );
}

export default BcAccount;

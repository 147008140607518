import React, { Fragment, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useFirestore } from "react-redux-firebase";

import Navbar from "./Navbar";
import Video from "../playground/VideoJS";
import Button from "./Button";
import Modal from "./Modal";
import { SelectLanguage } from "./Select";
import Notification, { addNotification } from "./Notification";
import TitleAndButtons from "./transcript-editor/TitleAndButtons";
import TextTracks from "./transcript-editor/TextTracks";
import QuickActions from "./transcript-editor/QuickActions";
import Timeline from "./transcript-editor/Timeline";
import Transcript from "./transcript-editor/Transcript";
import * as editorActions from "../actions/editor-actions";
import { bcCMSVideo, bcCustomFieldUpdate } from "./utils/brightcoveRequest";
import { t } from "../i18n/language";

export default function Editor() {
  const [docCreated, setDocCreated] = useState();
  const [userProjectAdded, setUserProjectAdded] = useState(false);

  // PLAYER
  const [player, setPlayer] = useState(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [videoDuration, setVideoDuration] = useState(100);

  // MODALS
  const [languagesModal, setLanguagesModal] = useState(false);
  const [languageToAdd, setLanguageToAdd] = useState("");

  const videoId = useParams().id;
  const firestore = useFirestore();

  const selectedAccount = useSelector((state) => state.user.selectedAccount);
  const stateVideos = useSelector((state) => state.videos);
  const currentUser = useSelector((state) => state.user);
  const currentProject = useSelector((state) => state.projects[videoId]);
  const currentProjectUserSpecific = useSelector(
    (state) => state.projects_user_specific[videoId]
  );

  const handleAccountChange = (account) => {
    const itemUpdates = {
      selectedAccount: { ...account },
      updatedAt: firestore.FieldValue.serverTimestamp(),
    };

    firestore.update(
      { collection: "users", doc: currentUser.uid },
      itemUpdates
    );

    setTimeout(() => {
      return window.location.reload();
    }, 1000);
  };

  //  CRETAE A PROJECT IF THE PROJECT DOES NOT EXIST
  useEffect(() => {
    if (videoId && currentUser.org_id && !userProjectAdded) {
      firestore
        .collection("projects")
        .doc(videoId)
        .get()
        .then((doc) => {
          if (doc.exists && doc.data().org_id !== currentUser.org_id)
            return addNotification(
              t("Insufficient permissions"),
              t("You don't have access to this project"),
              "bg-red-600"
            );
          if (doc.exists) {
            editorActions.addProjectUserSpecific(videoId);
            if (doc.data().languages[0]) {
              editorActions.selectLang(videoId, doc.data().languages[0]);
            } else {
              setLanguagesModal(true);
            }

            firestore.onSnapshot(
              { collection: "projects", doc: videoId },
              (res) => {
                if (userProjectAdded === false) {
                  editorActions.addProject(res.id, res.data());

                  setUserProjectAdded(true);
                }
              }
            );
          } else {
            // CREATE THE DOC
            firestore
              .collection("projects")
              .doc(videoId)
              .set(
                {
                  id: videoId,
                  languages: [],
                  org_id: currentUser.org_id,
                },
                { merge: true }
              )
              .then((res) => setDocCreated(true))
              .catch((err) => {
                console.error("firebase error2", err);
              });
          }
        })
        .catch((err) => {
          console.error("firebase error", err);
        });
    }

    // eslint-disable-next-line
  }, [videoId, currentUser, docCreated]);

  //  LOAD THE VIDEO FROM BC IF IT IS NOT ALREADY LOADED
  useEffect(() => {
    if (stateVideos[videoId]) {
      console.log("Video loaded from BC");
      //  editorActions.addRecentProject(
      //   videoId,
      //   currentUser.uid,
      //   currentUser.recent_projects
      //  );
    } else {
      if (selectedAccount) {
        bcCMSVideo(selectedAccount, videoId).then((res) => {
          if (res.data === "no results found") {
            return console.log("Video not found on BC");
          }
        });
      }
    }
    // eslint-disable-next-line
  }, [stateVideos, selectedAccount]);

  // ADD OPENED VIDEO ID TO USERS RECENT PROJECTS ARRAY
  useEffect(() => {
    if (
      videoId &&
      currentUser &&
      currentUser.uid &&
      currentUser.recent_projects
    ) {
      //  console.log(currentUser.recent_projects);
      if (
        currentUser.recent_projects[0] !== videoId &&
        currentUser.recent_projects[1] !== videoId &&
        currentUser.recent_projects[2] !== videoId
      ) {
        setTimeout(() => {
          editorActions.addRecentProject(
            videoId,
            currentUser.uid,
            currentUser.recent_projects
          );
        }, 1000);
      }
    }
    // eslint-disable-next-line
  }, [videoId, currentUser]);

  //  CHECK IF USER HAS SELECTED THE RIGHT BC ACCOUNT, IF NOT, SELECT IT FOR THEM
  useEffect(() => {
    if (currentProject) {
      if (
        parseInt(selectedAccount.account_id) ===
        parseInt(currentProject.bc_account_id)
      ) {
        return console.log(true);
      } else {
        const accountToSet = currentUser.bc_accounts.filter(
          (account) =>
            parseInt(account.account_id) ===
            parseInt(currentProject.bc_account_id)
        );
        if (accountToSet.length > 0) {
          return handleAccountChange(accountToSet[0]);
        }
      }
    }
  }, [currentProject, currentUser, selectedAccount]);

  return (
    <Fragment>
      <Navbar />
      <Notification />
      <div className="container mx-auto px-5 py-5">
        <TitleAndButtons
          videoId={videoId}
          languagesModal={(bool) => setLanguagesModal(bool)}
        />
        <div className="mt-4 grid grid-cols-6 gap-6">
          <div className="col-span-3">
            {selectedAccount ? (
              <Video
                videoId={videoId}
                player={(e) => {
                  setPlayer(e);
                  // console.log(e);
                }}
                currentTime={(time) => setCurrentTime(time)}
                videoDuration={(dur) => {
                  setVideoDuration(dur);
                }}
              />
            ) : null}
            {currentProject ? (
              <TextTracks videoId={videoId} player={player} />
            ) : null}
          </div>

          <div className="col-span-3 flex flex-col justify-between">
            {currentProject &&
            currentProjectUserSpecific.selectedLang &&
            currentProjectUserSpecific.selectedTrack ? (
              <QuickActions videoId={videoId} player={player} />
            ) : (
              <Fragment>
                <div>
                  <span className="bg-gray-200 text-gray-200 rounded-sm">
                    loading...
                  </span>
                  <div className="mt-2 px-5 py-5 w-96 rounded bg-gray-200 border border-gray-200"></div>
                </div>
                <div>
                  <span className="bg-gray-200 text-gray-200 rounded-sm">
                    loading...
                  </span>
                  <div className="h-32 mt-2 px-5 py-4 w-96 rounded bg-gray-200 border border-gray-200"></div>
                </div>
              </Fragment>
            )}
          </div>
          <div className="col-span-2 mt-2">
            {!currentProject ? (
              <Fragment>
                <span className="bg-gray-200 text-gray-200 rounded-sm">
                  Transcript loading...
                </span>
                <div className="mt-2 h-48 flex overflow-scroll bg-gray-200 rounded border border-gray-200"></div>
              </Fragment>
            ) : currentProjectUserSpecific.selectedLang === "" ? (
              <Fragment>
                <span className="bg-gray-200 text-gray-200 rounded-sm">
                  Transcript loading...
                </span>
                <div className="mt-2 h-48 flex overflow-scroll bg-gray-200 rounded border border-gray-200"></div>
              </Fragment>
            ) : (
              <Transcript videoId={videoId} player={player} />
            )}
          </div>
          <div className="col-span-4 mt-2">
            {!currentProject ? (
              <Fragment>
                <span className="bg-gray-200 text-gray-200 rounded-sm">
                  Timeline loading...
                </span>
                <div className="mt-2 h-48 flex overflow-scroll bg-gray-200 rounded border border-gray-200"></div>
              </Fragment>
            ) : currentProjectUserSpecific.selectedLang === "" ? (
              <Fragment>
                <span className="bg-gray-200 text-gray-200 rounded-sm">
                  Timeline loading...
                </span>
                <div className="mt-2 h-48 flex overflow-scroll bg-gray-200 rounded border border-gray-200"></div>
              </Fragment>
            ) : (
              <Timeline
                videoId={videoId}
                currentTime={currentTime}
                player={player}
                videoDuration={videoDuration}
              />
            )}
          </div>
        </div>
      </div>
      {/* LANGUAGE MODAL */}
      {!currentProject ? (
        ""
      ) : (
        <Modal
          title={t("Language selector")}
          width="w-72"
          show={languagesModal}
          acceptButtonText={t("Add")}
          onAccept={() => {
            // CHECK IF LANG ALREADY EXISTS

            if (currentProject.languages.includes(languageToAdd.code)) {
              editorActions.selectLang(videoId, languageToAdd.code);

              setLanguageToAdd("");
              return setLanguagesModal(false);
            }

            editorActions.addLang(
              videoId,
              languageToAdd.code,
              languageToAdd.label
            );

            bcCustomFieldUpdate(
              selectedAccount,
              videoId,
              "transcript",
              {
                status: "draft",
                lang: languageToAdd.code,
                label: languageToAdd.label,
              },
              false,
              false
            );

            setLanguageToAdd("");
            return setLanguagesModal(false);
          }}
          onClose={() => {
            setLanguageToAdd("");
            setLanguagesModal(false);
          }}
          acceptDisabled={languageToAdd === "" ? true : false}
          cancelDisabled={false}
        >
          <div className="flex flex-col">
            <h2>{t("Languages")}</h2>
            <div className="flex flex-col items-center">
              {currentProject.languages.length === 0 ? (
                <span className="mt-2 text-gray-600 text-sm self-start">
                  {t("No languages added.")}
                </span>
              ) : (
                currentProject.languages.map((lang) => {
                  return (
                    <div key={lang} className="mt-2 w-full">
                      <Button
                        text={`${
                          currentProject[lang].label
                        } (${lang.toUpperCase()})`}
                        colour={
                          currentProjectUserSpecific.selectedLang === lang
                            ? "green"
                            : "white"
                        }
                        cssClasses="w-full text-center"
                        onClick={() => {
                          editorActions.selectLang(videoId, lang);
                          return setLanguagesModal(false);
                        }}
                      />
                    </div>
                  );
                })
              )}
            </div>
            <div className="mt-8 border-t border-grey-200">
              <h2 className="mt-6 text-gray-900">{t("Add new")}</h2>
              <div className="mt-2">
                <SelectLanguage
                  selected={(selected) => {
                    if (selected !== "") {
                      setLanguageToAdd(JSON.parse(selected));
                    } else {
                      setLanguageToAdd("");
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </Modal>
      )}
    </Fragment>
  );
}

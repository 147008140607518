import { useState, useEffect } from "react";
import { getKey } from "../utils/helpers";
import { getPlugins, addPlugin } from "../utils/adminFunctions";

function Plugin() {
  const [newVersion, setNewVersion] = useState();
  const [plugins, setPlugins] = useState();

  useEffect(() => {
    if (!plugins) {
      getPlugins()
        .then((response) => {
          setPlugins(response);
        })
        .catch((err) => console.error(err));
    }
  }, [plugins]);

  const addNewVersion = () => {
    setNewVersion({ ver: "", date: new Date(), css: "", js: "", changes: [] });
  };

  const updateValue = (name, value) => {
    setNewVersion((old) => {
      switch (name) {
        case "ver":
          old.ver = value;
          break;
        case "date":
          old.date = new Date(value);
          break;
        case "css":
          old.css = value;
          break;
        case "js":
          old.js = value;
          break;
        default:
          console.warn(`Did not update ${name}`);
          break;
      }
      return { ...old };
    });
  };

  const addChange = () => {
    const oldChanges = [...newVersion.changes];
    oldChanges.push({ title: "", desc: "", key: getKey() });
    setNewVersion((old) => {
      const _new = { ...old };
      _new.changes = oldChanges;
      return _new;
    });
  };

  const deleteChange = (index) => {
    setNewVersion((old) => {
      old.changes.splice(index, 1);
      return { ...old };
    });
    // console.log("Delete change:", index);
  };

  const updateChange = ({ index, name, value }) => {
    setNewVersion((old) => {
      switch (name) {
        case "title":
          old.changes[index].title = value;
          break;
        case "desc":
          old.changes[index].desc = value;
          break;
        default:
          break;
      }
      return { ...old };
    });
    // console.log(newVersion);
  };

  const savePlugin = () => {
    if (!newVersion) {
      return;
    }
    const plugin = { ...newVersion };
    for (const change of plugin.changes) {
      delete change.key;
    }
    for (const value of Object.keys(plugin)) {
      if (plugin[value].length === 0) {
        delete plugin[value];
      }
    }
    addPlugin(plugin)
      .then((response) => {
        console.log(response);
        setNewVersion(null);
        setPlugins(null);
      })
      .catch((err) => console.error(err));
  };

  return (
    <>
      <h1 className="text-2xl font-semibold pb-3">Plugin</h1>
      {plugins &&
        plugins.map((ver) => {
          return (
            <div
              key={ver.ver}
              className="bg-white rounded border border-gray-200 text-sm p-4"
            >
              <div className="flex flex-row items-center justify-between">
                <span className="text-xl font-semibold">{ver.ver}</span>
                <span className="">
                  {ver.date.toDate().toLocaleDateString()}
                </span>
              </div>
              <div className="my-2">
                {ver.css && (
                  <a
                    href={ver.css}
                    className="p-1 m-1 rounded border border-equalplay-light bg-equalplay-light text-white hover:bg-equalplay-dark active:bg-gray-800 focus:outline-none focus:shadow-outline"
                  >
                    CSS
                  </a>
                )}
                {ver.js && (
                  <a
                    href={ver.js}
                    className="p-1 m-1 rounded border border-equalplay-light bg-equalplay-light text-white hover:bg-equalplay-dark active:bg-gray-800 focus:outline-none focus:shadow-outline"
                  >
                    JS
                  </a>
                )}
              </div>
              <ol className="list-disc list-outside mx-8">
                {ver.changes &&
                  ver.changes.map((change) => {
                    return (
                      <li key={getKey()}>
                        <h5 className="font-semibold">{change.title}</h5>
                        <p>{change.desc}</p>
                      </li>
                    );
                  })}
              </ol>
            </div>
          );
        })}

      {!newVersion && (
        <button
          type="button"
          onClick={addNewVersion}
          className="p-1 m-1 rounded border border-equalplay-light bg-equalplay-light text-white hover:bg-equalplay-dark active:bg-gray-800 focus:outline-none focus:shadow-outline"
        >
          Add new version
        </button>
      )}
      {newVersion && (
        <div className="bg-white rounded border border-gray-200 p-4 flex flex-col">
          <h5 className="font-semibold">Version number:</h5>
          <input
            type="text"
            placeholder="Version number"
            className="rounded-md border border-gray-400 pl-2"
            defaultValue={newVersion.ver}
            onChange={(ev) => {
              updateValue("ver", ev.target.value);
            }}
          />
          <h5 className="font-semibold">Publish date:</h5>
          <input
            type="date"
            className="rounded-md border border-gray-400 pl-2"
            defaultValue={newVersion.date.toISOString().slice(0, 10)}
            onChange={(ev) => {
              updateValue("date", ev.target.value);
            }}
          />
          <h5 className="font-semibold">CSS file:</h5>
          <input
            type="url"
            defaultValue={newVersion.css}
            className="rounded-md border border-gray-400 pl-2"
            placeholder="URL to CSS"
            onChange={(ev) => {
              updateValue("css", ev.target.value);
            }}
          />
          <h5 className="font-semibold">Javascript:</h5>
          <input
            type="url"
            defaultValue={newVersion.css}
            className="rounded-md border border-gray-400 pl-2"
            placeholder="URL to JS"
            onChange={(ev) => {
              updateValue("js", ev.target.value);
            }}
          />
          <h5 className="font-semibold">Changelog:</h5>
          {newVersion.changes.map((change, index) => {
            return (
              <div key={change.key} className="container mx-auto flex flex-col">
                <input
                  type="text"
                  defaultValue={change.title}
                  placeholder="Title..."
                  className="rounded-md border border-gray-400 pl-2"
                  onChange={(ev) => {
                    updateChange({
                      index,
                      name: "title",
                      value: ev.target.value,
                    });
                  }}
                />
                <textarea
                  placeholder="Description..."
                  className="rounded-md border border-gray-400 pl-2"
                  onChange={(ev) => {
                    updateChange({
                      index,
                      name: "desc",
                      value: ev.target.value,
                    });
                  }}
                />
                <div>
                  <button
                    type="button"
                    className="p-1 m-1 rounded border border-red-600 bg-red-600 text-white hover:bg-red-700 active:bg-red-800 focus:outline-none focus:shadow-outline"
                    onClick={() => {
                      deleteChange(index);
                    }}
                  >
                    Delete
                  </button>
                </div>
              </div>
            );
          })}
          <div>
            <button
              type="button"
              onClick={addChange}
              className="p-1 m-1 rounded border border-equalplay-light bg-equalplay-light text-white hover:bg-equalplay-dark active:bg-gray-800 focus:outline-none focus:shadow-outline"
            >
              Add to changelog
            </button>
          </div>
          <div className="flex flex-row">
            <button
              type="button"
              className="p-1 m-1 rounded border border-equalplay-light bg-equalplay-light text-white"
              onClick={() => {
                savePlugin();
              }}
            >
              Save
            </button>
            <button
              type="button"
              className="p-1 m-1 rounded border border-yellow-600 bg-yellow-600"
              onClick={() => {
                setNewVersion(null);
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      )}
    </>
  );
}

export default Plugin;

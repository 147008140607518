import React, { Fragment } from "react";
import { Link } from "react-router-dom";

export default function FeatureStatus({ status = null, linkTo }) {
 //  const [multiple, setMultiple] = useState(false);
 let colour;
 let path;

 if (status && status.draft > 0) {
  colour = "text-gray-600";
  path =
   "M3.442 8.235L11.448.229a.8.8 0 011.12 0l3.203 3.202a.8.8 0 010 1.12l-8.006 8.007a.8.8 0 01-.56.24H4.003a.8.8 0 01-.8-.8V8.794a.8.8 0 01.24-.56zm1.361 2.962h2.074l7.205-7.205-2.074-2.074-7.205 7.205v2.074zm8.006-1.602a.8.8 0 111.601 0V14.4A1.6 1.6 0 0112.81 16H1.6A1.601 1.601 0 010 14.399V3.19C0 2.31.72 1.59 1.601 1.59h4.804a.8.8 0 010 1.6H1.6V14.4H12.81V9.595z";
 } else if (status && status.processing > 0) {
  colour = "text-gray-600";
  path =
   "M8 16A8 8 0 118 0a8 8 0 010 16zm0-1.6A6.4 6.4 0 108 1.6a6.4 6.4 0 000 12.8zm.8-6.728l2.032 2.024a.804.804 0 01-1.136 1.136L7.44 8.56A.8.8 0 017.2 8V4.8a.8.8 0 011.6 0v2.872z";
 } else if (status && status.uploaded > 0) {
  colour = "text-gray-600";
  path =
   "M8 0a8 8 0 110 16A8 8 0 018 0zm0 1.6a6.4 6.4 0 100 12.8A6.4 6.4 0 008 1.6zm0 7.2a.8.8 0 01-.8-.8V4.8a.8.8 0 011.6 0V8a.8.8 0 01-.8.8zM8 12a.8.8 0 110-1.6.8.8 0 010 1.6z";
 } else if (status && status.disabled > 0) {
  colour = "text-gray-500";
  path =
   "M8 16A8 8 0 118 0a8 8 0 010 16zm0-1.6A6.4 6.4 0 108 1.6a6.4 6.4 0 000 12.8zM11.2 8a.8.8 0 01-.8.8H5.6a.8.8 0 010-1.6h4.8a.8.8 0 01.8.8z";
 } else if (status && status.approved > 0) {
  colour = "text-green-400";
  path =
   "M8 16A8 8 0 118 0a8 8 0 010 16zm0-1.6A6.4 6.4 0 108 1.6a6.4 6.4 0 000 12.8zM6.16 7.44L7.2 8.472l2.64-2.64a.8.8 0 011.12 1.136l-3.2 3.2a.8.8 0 01-1.12 0l-1.6-1.6a.8.8 0 011.12-1.136v.008z";
 } else {
  // If there are no tracks, show the add track icon
  colour = "text-gray-300";
  path =
   "M8 16A8 8 0 118 0a8 8 0 010 16zm0-1.6A6.4 6.4 0 108 1.6a6.4 6.4 0 000 12.8zm.8-7.2h1.6a.8.8 0 110 1.6H8.8v1.6a.8.8 0 11-1.6 0V8.8H5.6a.8.8 0 010-1.6h1.6V5.6a.8.8 0 011.6 0v1.6z";
 }

 //  useEffect(() => {
 //   if (status) {
 //    Object.values(status).map(obj => {
 //     if (obj > 1) {
 //      setMultiple(true);
 //     }
 //    });
 //   }
 //  }, [status]);

 return (
  <Link
   to={linkTo}
   className={`${colour} w-12 h-12 mx-auto hover:bg-gray-100 rounded-full flex items-center focus:outline-none hover:text-gray-700 focus:text-gray-900`}
  >
   <Fragment>
    <svg
     className={`h-4 w-4 mx-auto`}
     fill="currentColor"
     xmlns="http://www.w3.org/2000/svg"
    >
     <path d={path} />
     {/* <path d="M3.442 8.235L11.448.229a.8.8 0 011.12 0l3.203 3.202a.8.8 0 010 1.12l-8.006 8.007a.8.8 0 01-.56.24H4.003a.8.8 0 01-.8-.8V8.794a.8.8 0 01.24-.56zm1.361 2.962h2.074l7.205-7.205-2.074-2.074-7.205 7.205v2.074zm8.006-1.602a.8.8 0 111.601 0V14.4A1.6 1.6 0 0112.81 16H1.6A1.601 1.601 0 010 14.399V3.19C0 2.31.72 1.59 1.601 1.59h4.804a.8.8 0 010 1.6H1.6V14.4H12.81V9.595z" /> */}
    </svg>

    {/* {status ? (
     <Fragment>
      {status.draft > 1 ? status.draft : ""}
      {status.processing > 1 ? status.processing : ""}
      {status.uploaded > 1 ? status.uploaded : ""}
      {status.disabled > 1 ? status.disabled : ""}
      {status.approved > 1 ? status.approved : ""}
     </Fragment>
    ) : (
     ""
    )} */}
   </Fragment>
  </Link>
 );
}

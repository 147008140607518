import { toVTT } from "./parseVTT";

import { addNotification } from "../Notification";
import { t } from "../../i18n/language";

/**
 * @function vttBlob - Takes cues and creates a VTT file for download or upload
 * @param {object} cues - cues to be included in the file
 * @param {string} kind - Track kind - Captions, Subtitles, Descriptions, Chapters
 * @param {string} lang - Language of the VTT file
 */
export function vttBlob(cues, kind, lang) {
 const vtt = toVTT(cues, lang, kind);

 if (vtt === null) {
  addNotification(
   t("Overlapping cues"),
   t("Fix any overlapping cues before continuing"),
   "bg-red-600"
  );
  return null;
 }

 let blob = new Blob([vtt], {
  type: "text/plain;charset=utf-8",
 });

 let href = window.URL.createObjectURL(blob);

 return { href, blob };
}

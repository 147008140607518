import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import Select from "../Select";

import { bcCustomFieldUpdate } from "../utils/brightcoveRequest";

export default function BCStatusChange({
  videoId,
  type,
  lang,
  label,
  disabled,
}) {
  const [currentStatus, setCurrentStatus] = useState("");
  const selectedAccount = useSelector((state) => state.user.selectedAccount);
  const video = useSelector((state) => state.videos[videoId]);

  const handleChoice = (choice) => {
    setCurrentStatus("updating");
    bcCustomFieldUpdate(selectedAccount, videoId, type, {
      status: choice,
      lang: lang,
      label: label,
    });
  };

  useEffect(() => {
    if (video && video.custom_fields) {
      if (video.custom_fields.equalplay) {
        // console.log("equalplay:", JSON.parse(video.custom_fields.equalplay));
        let object = JSON.parse(video.custom_fields.equalplay);
        let filtered = object[type].filter((element) => element.lang === lang);
        // console.log(filtered);
        setCurrentStatus(filtered.length > 0 ? filtered[0].status : "");
      } else {
        console.log("no Equal Play custom field");
      }
    }
    // eslint-disable-next-line
  }, [video]);

  return (
    <Select
      selected={currentStatus}
      onSelect={(choice) => {
        handleChoice(choice);
      }}
      disabled={disabled}
    />
  );
}

BCStatusChange.propTypes = {
  videoId: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  lang: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
};

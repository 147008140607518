function PreviewLink({ videoId, accountId, playerId }) {
  if (!videoId || !accountId) {
    return null;
  }
  return (
    <div>
      <h2 className="text-gray-600">Review Link:</h2>
      <a
        href={`${process.env.PUBLIC_URL}/preview/${accountId}/${videoId}`}
        target="_blank"
        rel="noreferrer"
      >{`${process.env.PUBLIC_URL}/preview/${accountId}/${videoId}`}</a>
    </div>
  );
}

export default PreviewLink;

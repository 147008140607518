import { useState } from "react";
import {
  listBcAccounts,
  verifyBcAccount,
  saveBcAccount,
} from "../utils/adminFunctions";
import { Link } from "react-router-dom";

import { addNotification } from "../Notification";
import Button from "../Button";
import Modal from "../Modal";
import Input from "../Input";
import { t } from "../../i18n/language";
import { LinkIcon } from "../utils/svgs";
import { getKey } from "../utils/helpers";
import Spinner from "../Spinner";

function BcAccounts() {
  const [accounts, setAccounts] = useState();
  const [showCreate, setShowCreate] = useState(false);
  const [accountId, setAccountId] = useState();
  const [clientId, setClientId] = useState();
  const [clientSecret, setClientSecret] = useState();

  const verifyAccount = async () => {
    const verification = await verifyBcAccount({
      account_id: accountId,
      client_id: clientId,
      client_secret: clientSecret,
    });

    setShowCreate(false);

    if (verification[0]) {
      return addNotification("Error", verification[0], "bg-red-600");
    }

    try {
      await saveBcAccount(verification[1]);
    } catch (err) {
      return addNotification("Error", err.message, "bg-red-600");
    }

    return addNotification(
      "Success",
      `Brightcove account ${verification[1].account_id} verified!`,
      "bg-equalplay-light"
    );
  };

  if (!accounts) {
    listBcAccounts()
      .then((response) => {
        setAccounts(response);
      })
      .catch((err) => {
        console.error(err);
        return addNotification(
          t("Error"),
          `Can not read accounts, ${err.message}`,
          "bg-red-600"
        );
      });
  }

  return (
    <>
      <h2 className="text-xl font-semibold">{`Brightcove ${t(
        "Accounts"
      ).toLowerCase()}`}</h2>
      {!accounts && <Spinner />}
      <div className="mt-2 bg-white rounded border border-gray-200 text-sm">
        <div className="px-3 py-2 grid grid-cols-11 text-xs text-gray-600 uppercase font-semibold leading-relaxed">
          <div className="col-span-4">{t("Name")}</div>
          <div className="col-span-3">{"Brightcove ID"}</div>
          <div className="col-span-1 mx-auto">{"Link"}</div>
          <div className="col-span-2 mx-auto">{"Player ID"}</div>
        </div>
        {accounts &&
          accounts.map((account) => {
            return (
              <div
                className="px-3 py-4 grid grid-cols-11 border-t border-gray-200"
                key={getKey()}
              >
                <div className="col-span-4 font-semibold">
                  {account.name ? account.name : "<No name>"}
                </div>
                <div className="col-span-3">{account.account_id}</div>
                <div className="col-span-1 mx-auto">
                  <Link to={`/admin/bcaccounts/${account.account_id}`}>
                    <LinkIcon />
                  </Link>
                </div>
                <div className="col-span-2 mx-auto">{account.player_id}</div>
              </div>
            );
          })}
      </div>
      <div className="mt-4 flex items-center">
        <Button
          colour="white"
          text={"Add Brightcove account"}
          onClick={() => {
            setShowCreate(true);
          }}
          tooltip={"Add a new Brightcove account"}
        />
      </div>
      <Modal
        show={showCreate}
        title={t("Create client")}
        cancelDisabled={false}
        cancelButtonText={t("Cancel")}
        onClose={() => {
          console.info("closing modal");
          setShowCreate(false);
        }}
        acceptButtonText={t("Create")}
        onAccept={verifyAccount}
      >
        <Input
          css="mt-2"
          label={`${t("Account")} ID`}
          name="accountid"
          type="text"
          value={accountId}
          placeholder={`${t("Account")} ID`}
          onChange={(e) => {
            // setClientName(e.target.value);
            setAccountId(e.target.value);
          }}
        />
        <Input
          css="mt-2"
          label={`${t("Account")} client`}
          name="clientid"
          type="text"
          value={clientId}
          placeholder={`${t("Account")} client`}
          onChange={(e) => {
            setClientId(e.target.value);
          }}
        />
        <Input
          css="mt-2"
          label={`${t("Account")} secret`}
          name="clientsecret"
          type="text"
          value={clientSecret}
          placeholder={`${t("Account")} secret`}
          onChange={(e) => {
            setClientSecret(e.target.value);
          }}
        />
      </Modal>
    </>
  );
}

export default BcAccounts;

import React, { Fragment, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import Navbar from "./Navbar";
import TwoColWithSidebar, { Sidebar, Main } from "./layouts/TwoColWithSidebar";
import SidebarTips from "./layouts/SidebarTips";
import SidebarSection from "./layouts/SidebarSection";
import SidebarHeader from "./layouts/SidebarHeader";
import Video from "./layouts/Video";
import Button from "./Button";
import Modal from "./Modal";
import Notification from "./Notification";
import Warning from "./Warning";
import Spinner from "./Spinner";
import { Uploader } from "./utils/uploader";
import { newSignLanguageHook } from "./utils/slackWebhooks";

import emptyCustomField from "./utils/customField";
import {
  bcCMSCreateVideo,
  bcCMSVideo,
  bcCustomFieldUpdate,
  getBcVideo,
  setBcVideoEqpField,
} from "./utils/brightcoveRequest";
import { t } from "../i18n/language";
import Select from "./Select";

export default function SignLanguage() {
  const [video, setVideo] = useState();
  const [notFound, setNotFound] = useState(false);

  //  const [uploadTaskRef, setUploadTaskRef] = useState();
  const [progress, setProgess] = useState(0);
  const [fileName, setFileName] = useState("");
  const [uploaded, setUploaded] = useState(false);
  const [showUploading, setShowUploading] = useState(false);
  const [importError, setImportError] = useState(false);
  const [importErrorMessage, setImportErrorMessage] = useState("");
  const [onCompleteModal, setOnCompleteModal] = useState(false);

  const videoId = useParams().id;

  const user = useSelector((state) => state.user);
  const selectedAccount = useSelector((state) => state.user.selectedAccount);

  const resetUploader = () => {
    setProgess(0);
    setFileName("");
    setUploaded(false);
    setShowUploading(false);
    setOnCompleteModal(false);
  };

  useEffect(() => {
    if (selectedAccount) {
      getBcVideo({ accountId: selectedAccount.account_id, videoId })
        .then((video) => {
          setVideo(video);
        })
        .catch((err) => {
          console.log(err);
          setNotFound(true);
        });
    }
    // eslint-disable-next-line
  }, [selectedAccount]);

  return (
    <Fragment>
      <Navbar />
      <Notification />
      <TwoColWithSidebar>
        <Sidebar>
          <SidebarSection>
            <SidebarHeader text={t("Tips & tricks")} icon="tips" />
            <SidebarTips type="sl" />
          </SidebarSection>
        </Sidebar>
        <Main>
          <h1 className="text-xl font-semibold">{t("Sign language")}</h1>
          {!video && !notFound && (
            <div className="mt-4 flex">
              <Spinner />
            </div>
          )}
          {!video && notFound && `${t("Video not found")} / ${videoId}`}

          {video && (
            <div className="mt-4 flex">
              <Video title={video.name} videoId={videoId} />

              <div className="ml-6 max-w-xl">
                <h2 className="text-gray-600">{t("Chroma content")}</h2>
                <Warning
                  title={t("Heads up!")}
                  colour="orange"
                  customCss="mt-4"
                >
                  {t(
                    "We're still working on this feature. For now, upload your content below and we'll take it from there."
                  )}
                </Warning>
                {/* <input
         onChange={e => {
          const Upload = new Uploader(
           "https://europe-west1-equal-play.cloudfunctions.net/uploader"
          );
          Upload(e.target.files[0], { uid: "1234", bcid: "54321" });
         }}
         className="mt-10"
         type="file"
         accept="video/*"
         id="file"
         name="file"
        /> */}

                {/* UPLOADER */}
                <div className="mt-4">
                  {!showUploading ? (
                    <label
                      className="w-full h-16 inline-block flex justify-center items-center cursor-pointer text-gray-600 rounded border border-gray-400 border-dashed hover:border-gray-600 hover:text-gray-700 focus:shadow-inner"
                      htmlFor="file"
                    >
                      {t("Browse")}
                    </label>
                  ) : (
                    <div className="w-full px-4 py-2 flex flex-col text-gray-800 text-sm rounded border border-gray-400">
                      <div className="font-semibold text-gray-800">
                        {fileName}
                      </div>
                      <div className="mt-2 h-1 relative">
                        <div
                          style={{ width: `${progress}%` }}
                          className={`${
                            importError
                              ? "bg-red-500"
                              : uploaded
                              ? "bg-equalplay-light"
                              : "bg-gray-700"
                          } h-1 absolute right-0 left-0 z-10`}
                        ></div>
                        <div className="bg-gray-200 h-1 absolute right-0 left-0"></div>
                      </div>
                      <div className="mt-2 flex text-sm justify-between text-gray-700">
                        {importError ? (
                          importErrorMessage
                        ) : uploaded ? (
                          <div>{t("Uploaded!")}</div>
                        ) : (
                          <button
                            onClick={() => {
                              console.log("Trying to cancel");
                            }}
                            className="underline"
                          >
                            {t("Cancel")}
                          </button>
                        )}
                        <div>{importError ? "" : `${progress}%`}</div>
                      </div>
                    </div>
                  )}
                  <input
                    onChange={(e) => {
                      setShowUploading(true);
                      setFileName(e.target.files[0].name);
                      const Upload = new Uploader(
                        "https://europe-west1-equal-play.cloudfunctions.net/uploader"
                      );
                      Upload(
                        e.target.files[0],
                        { uid: "1234", bcid: "54321" },
                        (progress) => {
                          setProgess(progress.percent);
                          console.log(progress);
                        },
                        async (complete) => {
                          // ADD AN INJEST ON BC
                          const createVideoRes = await bcCMSCreateVideo(
                            selectedAccount,
                            `SL video for ${videoId}`,
                            {
                              tags: ["equalplay-alpha"],
                            }
                          );

                          // ADD EQUAL PLAY FIELD TO PROCESSING ON OG VIDEO
                          await bcCustomFieldUpdate(
                            selectedAccount,
                            videoId,
                            "sign",
                            {
                              id: createVideoRes.data.id,
                              label: "SSL",
                              status: "processing",
                            }
                          );

                          setUploaded(true);
                          setOnCompleteModal(true);
                          console.log(`done with this message:`, complete);

                          newSignLanguageHook(
                            user,
                            videoId,
                            complete.url,
                            complete.id
                          );

                          //  TO DO - Send us a message saying that a new file needs to be processed.
                        }
                      );
                    }}
                    hidden
                    className="mt-10"
                    type="file"
                    accept="video/*"
                    id="file"
                    name="file"
                  />
                </div>
                <SignLanguageStatus video={video} />
              </div>
            </div>
          )}
        </Main>
      </TwoColWithSidebar>

      {/* UPLOAD COMPLETE MODAL */}
      <Modal
        title={t("We'll take it from here")}
        acceptButtonText={t("Okay")}
        onAccept={resetUploader}
        onClose={resetUploader}
        show={onCompleteModal}
        width="w-88"
      >
        {t(
          "We will let you know via email when it's time to check back and approve this final video."
        )}
      </Modal>
    </Fragment>
  );
}

/** Show and change the status of SL */
function SignLanguageStatus({ video }) {
  const [eqp, setEqp] = useState();

  useEffect(() => {
    if (!eqp) {
      const eqpField =
        video?.custom_fields?.equalplay &&
        JSON.parse(video.custom_fields.equalplay);
      setEqp(eqpField);
    }
  }, [eqp, video]);

  const handleStatusChange = ({ id, status }) => {
    console.log(id, status);
    const newEqp = { ...eqp };
    newEqp["sign"].find((sl) => sl.id === id).status = status;

    setBcVideoEqpField({
      accountId: video.account_id,
      videoId: video.id,
      eqpField: newEqp,
    })
      .then((res) => {
        setEqp(newEqp);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="mt-4">
      {eqp && <h2 className="text-gray-600">{t("Sign languages:")}</h2>}
      {eqp &&
        eqp["sign"].map((sl, i) => {
          return (
            <div
              className="mt-6 grid grid-cols-6 gap-2 items-center justify-between"
              key={i}
            >
              <h3 className="w-20 font-semibold col-span-2 whitespace-no-wrap">
                {sl.label}
              </h3>
              <div className="w-full col-span-3">
                <Select
                  selected={sl.status}
                  onSelect={(value) => {
                    handleStatusChange({ id: sl.id, status: value });
                  }}
                />
              </div>
              <div className="ml-4">
                <Button
                  text=""
                  iconBefore="delete"
                  colour="white"
                  size="small"
                  onClick={() => {
                    console.log("deletebutton click");
                  }}
                  disabled
                />
              </div>
            </div>
          );
        })}
    </div>
  );
}

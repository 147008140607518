import React from "react";

//
//
// Rating: "A" should have…
// Subtitles OR Captions OR Transcript OR Audio Description
//
// Rating: "AA" should have…
// Subtitles OR Captions
// Transcript
// Audio Description
//
// Rating: "AAA" should have…
// Subtitles OR Captions
// Transcript
// Audio Description
// Sign Lang OR Extended Audio Description
//
//

export default function Rating({ subsAndCaps, tran, ad, sl }) {
 let rating = 0;

 if (subsAndCaps > 0) {
  rating = rating + 1;
 }

 if (tran > 0) {
  rating = rating + 2;
 }

 if (ad > 0) {
  rating = rating + 2;
 }

 if (sl > 0) {
  rating = rating + 3;
 }

 return (
  <div
   className={`${
    rating === 0 ? "text-gray-400" : "bg-gray-100 text-gray-700"
   } mx-auto flex justify-center items-center h-12 font-bold w-12 rounded-full text-sm`}
  >
   {rating === 0
    ? "-"
    : rating >= 1 && rating <= 4
    ? "A"
    : rating >= 5 && rating <= 7
    ? "AA"
    : "AAA"}
  </div>
 );
}

// To create language...
// 1) Copy and paste EN and other lang text into https://www.convertcsv.com/csv-to-json.htm
// 2) Choose 'CSV to JSON Array'
// 3) Open console and use the follow to convert from array to obj
//      const objFinal = Object.fromEntries(arrayFinal);
// 4) Copy and paste full object below: https://scottwhittaker.net/chrome-devtools/2016/02/29/chrome-devtools-copy-object.html

export const i18n = {
  en: {},
  sv: {
    Dashboard: "Dashboard",
    Videos: "Videor",
    Admin: "Admin",
    Accounts: "Konton",
    Account: "Konto",
    Settings: "Inställningar",
    Administration: "Administration",
    Logout: "Logga ut",
    Support: "Support",
    Link: "Länk",

    Messages: "Meddelanden",
    "Mark all read": "Markera alla som läst",
    "No messages to show": "Inga meddelanden att visa",
    "ASR process complete for your video":
      "ASR-processen för din video är klar",

    Email: "E-post",
    Password: "Lösenord",
    "Forgot password?": "Glömt lösenord?",
    Login: "Logga in",
    "Logging in...": "Loggar in...",
    "Please check your email or password.":
      "Kontrollera din e-post eller lösenord.",
    "Don’t have an account?": "Har du inget konto?",
    "Get started!": "Kom igång...",
    "Password reset": "Återställ lösenord",
    Reset: "Återställ",
    "Resetting...": "Återställer...",
    "Please check your email address and try again.":
      "Kontrollera din e-post och prova igen.",
    "We'll send you an email with the next steps.":
      "Kontrollera din e-post för nästa steg.",
    "Return to login": "Tillbaks till inloggningen",
    Updates: "Uppdateringar",
    "Recent projects": "Senaste projekt",
    "Import tracks from Brightcove": "Importera spår från Brightcove",
    "You can now import text tracks from your Brightcove account. Simply use the 'Import' option in the transcript editor.":
      'Du kan nu importera textspår från ditt Brightcove-konto. Använd valet "Importera" i transkriptionsredigeraren.',
    "New language: Swedish": "Nytt språk: Svenska",
    "The Equal Play dashboard now supports Swedish, head over to your account settings to change language.":
      "Dashboard i Equal Play stöder nu Svenska! Ändra språk i dina kontoinställningar.",
    "Try it now": "Pröva nu",
    Rating: "Bedömning",
    "Subtitles / Captions": "Undertexter",
    Transcription: "Transkription",
    "Audio description": "Syntolkning",
    "Sign language": "Teckenspråk",
    "See all videos": "Se alla videor",
    "No recent projects.": "Inga senaste projekt.",
    "Start one now...": "Starta en nu...",
    "Video not active": "Videon är inte aktiv",
    "Activate this video in your Brightcove account":
      "Aktivera denna video på ditt Brightcove-konto",
    "Shared video": "Delad video",
    "Shared videos cannot be edited on this account":
      "Delade videor kan inte redigeras på detta kontot",

    "Accessibility rating": "Tillgänglighetsstatus",
    "Rating:": "Status",
    "You should have at least one of the following...":
      "Du borde ha minst en av följande...",
    "You should have all of the following...": "Du borde ha dessa följande...",
    Close: "Stäng",
    Filters: "Filter",
    "Sort by": "Sortera efter",
    "Last updated": "Senast uppdaterad",
    "Published date": "Publiceringsdatum",
    Name: "Namn",
    Tags: "Tagg",
    "Search videos...": "Sök",
    Previous: "Föregående",
    Next: "Nästa",
    "No tags added": "Inga taggar är tillagda",
    "No results found!": "Hittade inga resultat",
    Preview: "Förhandsvisning",
    Personal: "Personligt",
    Users: "Användare",
    "Personal details": "Personuppgifter",
    "First name": "Namn",
    "Last name": "Efternamn",
    "(me)": "(jag)",
    "Add new user": "Lägg till ny användare",
    "You've added 3/10 available users.":
      "Du har lagt till 3/10 tillgängliga användare.",
    "You've added": "Du har lagt till",
    "available users.": "tillgängliga användare",

    Create: "Skapa",
    Remove: "Ta bort",
    "Remove user": "Ta bort användare",
    "Are you sure you want to remove this user?":
      "Är du säker på att du vill ta bort denna användare?",
    "User removed!": "Användare borttagen!",
    "has been removed.": "har blivit borttagen.",
    "We couldn't remove the user right now. Please try again.":
      "Just nu kunde inte användaren tas bort, försök igen senare.",
    Cancel: "Ångra",
    Abort: "Avbryt",

    Clients: "Kunder",
    "Create client": "Skapa kund",
    "Client name": "Namn på kunden",
    "User Limit": "Användare",

    Usage: "Användning",
    "Next billing cycle starts": "Nästa faktureringsperiod börjar",
    of: "av",
    minutes: "minuter",
    January: "Januari",
    February: "Februari",
    March: "Mars",
    April: "April",
    May: "Maj",
    June: "Juni",
    July: "Juli",
    August: "Augusti",
    September: "September",
    October: "Oktober",
    November: "November",
    December: "December",

    "Add user": "Lägg till användare",
    "User added": "Ny användare inlagd",
    "has been added as a user. They will receive an email shortly.":
      "har lagts till som ny användare. Kontrollera användarens e-post.",
    "Language selector": "Välj språk",
    Languages: "Språk",
    "No languages added.": "Inga språk tillagda.",
    "Add new": "Lägg till ny",
    Add: "Lägg till",
    Language: "Språk",
    "Not selected": "Ej vald",
    Publish: "Publicera",
    "Publish new version": "Publicera ny version",
    Save: "Spara",
    "More options": "Fler alternativ",
    "Quick actions": "Snabbval",
    "Add new cue": "Lägg till cue",
    "Play + Add new cue": "Play + lägg till cue",
    "Edit cue": "Redigera cue",
    "Pause + edit cue": "Pausa och redigera cue",
    "Replay cue": "Replay cue",
    "Delete cue": "Ta bort cue",
    "Previous cue": "Föregående cue",
    "Next cue": "Nästa cue",
    "Cue editor": "Cue-redigerare",
    In: "in",
    Out: "ut",
    Duration: "Längd",
    Line: "Rad",
    "New cue": "Ny cue",
    Transcript: "Transkription",
    Timeline: "Tidslinje",
    Subtitles: "Undertexter",
    subtitles: "undertexter",
    Captions: "Captions",
    captions: "captions",
    Chapters: "Kapitel",
    chapters: "kapitel",
    Descriptive: "Beskrivning",
    descriptive: "beskrivning",
    "Changes saved": "Ändringar sparade",
    "Nice work!": "Bra jobbat!",
    "Overlapping cue": "Överlappande cue",
    "Overlapping cues": "Överlappande cues",
    "Fix any overlapping cues before continuing":
      "Rätta till alla överlappande cues innan du fortsätter",
    Waveform: "Vågform",
    "No waveform found. Generate one with ASR.":
      "Ingen vågform hittades. Skapa en med ASR.",

    "Automatic Speech Recognition (ASR)": "Automatic Speech Recognition (ASR)",
    "Import ASR": "Importera ASR",
    "Rerun ASR": "Gör om ASR",
    "Import as Subtitles": "Importera som undertext",
    "Import as Captions": "Importera som Captions",
    "ASR is generated by a deep-learning neural network. This means that accuracy isn't always spot on, but the service improves over time. ASR is intended as a starting point for captioning, from there you can correct and improve it!":
      "ASR genereras av artificiell intelligens, detta betyder att pricksäkerheten inte alltid är 100% men förbättras över tid. ASR är tänkt som en utgångspunkt som sedan kan rättas och förbättras! ",
    "Generate ASR": "Starta ASR processen",
    "Generating ASR. Please check back later. In the meantime you can close this window.":
      "Skapar ASR. Vänligen återkom senare. Under tiden du stänga ner denna rutan.",
    "Can not publish transcript without any published captions or subtitles":
      "Kan inte publicera transkription utan publicerade undertexter eller captions",
    Done: "Färdigt",
    "Import/Export": "Import/Export",
    "Import track": "Importera spår",
    "Export track": "Exportera spår",
    Delete: "Ta bort",
    Export: "Exportera",
    Download: "Ladda ner",
    "Download .vtt": "Ladda ner .vtt",
    "Delete language": "Ta bort språk",
    "Delete text track": "Ta bort textspår",
    "Track type to import": "Spårtyp att importera",
    "Heads up!": "OBS!",
    "Importing a track of this type will overwrite the cues on your current track.":
      "När du importerar denna typ av media så skriver du över befintlig data",
    "Importing ASR will overwrite the current cues on the text track":
      "När du importerar ASR skriver du över eventuell befintlig text på textspåret",
    "Import from Brightcove": "Importera från Brightcove",
    "No tracks found in your account.": "Inga spår hittades i ditt konto.",
    Or: "Eller",
    Upload: "Ladda upp",
    "Upload a .vtt file from your computer.": "Ladda upp en .vtt fil",
    File: "Fil",
    Browse: "Välj fil",
    Uploading: "Laddar",
    "Uploaded!": "Laddat klart!",
    Import: "Importera",
    "We've found these tracks in your Brightcove account.":
      "De här spåren hittade vi på ditt Brightcove-konto.",
    "Are you sure you want to delete this language?":
      "Är du säker på att du vill radera följande språk?",
    "Are you sure you want to delete this text track?":
      "Är du säker på att du vill radera följande textspår?",
    "Are you sure you want to delete this cue?":
      "Är du säker på att du vill radera denna cue?",
    "Changes updated!": "Förändring klar!",
    "Status updated": "Status uppdaterad",
    "It can take a while to see these changes in your Brightcove account.":
      "Det kan ta en stund för uppdateringarna att bli klara. Vänta ett par minutes.",
    "Tips & tricks": "Tips och tricks",
    Length: "Längd",
    "The descriptive track should be the same length and in sync with the original video's audio.":
      "Syntolkningsspåret bör vara samma längd som orginalvideon och ljudet bör vara synkat.",
    Content: "Innehåll",
    "Your audio description file should contain all audio from the video, not just the audio descriptive cues.":
      "Ditt syntolkningsspår ska innehålla allt originalljud och syntolken, inte bara syntolkningen.",

    "Descriptive track": "Syntolkning",
    "Dynamic Delivery issue": 'Problem med "Dynamic delivery".',
    "This video is not enabled for Dynamic Delivery. This is needed for multiple audio tracks. Please retranscode to enable Dynamic Delivery in your Brightcove account.":
      'Videon har inte "Dynamic delivery" aktiverat. Detta krävs för stöd av multipla ljudspår. Vänligen transkodera om videon i ditt Brightcove-konto.',
    "This video is not enabled for Dynamic Delivery. This is needed for importing text tracks. Please retranscode to enable Dynamic Delivery in your":
      "Videon har inte 'Dynamic delivery' aktiverat. Detta krävs för stöd av multipla ljudspår. Vänligen transkodera om videon i ditt",
    account: "konto",
    "No tracks added": "Inga spår har lagts till",
    "Add track": "Lägg till spår",
    "Upload a new file": "Ladda upp ny fil",
    Choose: "Välj",
    "Choose language first.": "Välj språk först.",
    Approve: "Godkänn",
    Approved: "Godkänt",
    Draft: "Utkast",
    Processing: "Bearbetar",
    Inactive: "Inaktiv",
    Updating: "Uppdaterar",
    "Are you sure you want to delete this track?":
      "Är du säker på att du vill radera detta spår?",
    "Chroma content": "Chroma key material",
    "We're still working on this feature. For now, upload your content below and we'll take it from there.":
      "Vi jobbar fortfarande på denna funktion. Under tiden, så ladda upp ditt material så fixar vi resten.",
    "We will let you know via email when it's time to check back and approve this final video.":
      "Vi låter dig veta via epost när det är dags godkänna din färdiga video. ",
    Lighting: "Ljussättning",
    "Make sure the background is evenly lit and that no green light is leaking onto the talent.":
      "Säkerställ att du har en jämn ljussättning på green screen och att inget grönt ljus återspeglas.",
    "The sign language video should be the same length and in sync with the original video.":
      "Teckentolksvideon bör matcha originalvideon i längd för att synkas.",
    "Resolution and FPS": "Upplösning och FPS",
    "Upload 720p or higher and with the same FPS as the original video.":
      "Ladda upp i minst 720p och med samma uppdateringsfrekvens som originalvideon.",
    Framing: "Placering",
    "Frame the interpreter from the hips and up.":
      "Beskär ditt objekt från midjan och upp.",
    Composition: "Bildkomposition",
    "Try not to use more than 50% of the image width and keep the talent centered.":
      "Försök att inte använda mer än 50% materialets bredd och se till att tolken är centrerad.",
    "Insufficient permissions": "Ej behörig",
    "You don't have access to this project":
      "Du har inte behörighet till detta projekt",
    "There seems to be a connection issue":
      "Det verkar vara ett problem med anslutningen",
    "Please try again in a few moments":
      "Vänligen försök igen om en liten stund",
    Error: "Tekniskt fel",
    "We couldn't add the user right now. Please try again.":
      "Vi kunder tyvärr inte lägga till användaren just nu. Försök igen.",
    "Something went wrong": "Något gick fel",
    "Oops!": "Ojdå!",
    "Looks like that didn't work. Please try again.":
      "Hmmm, tyvärr så fungerade inte detta. Vänligen försök igen",
    "We couldn't save your changes": "Vi kunde inte spara dina ändringar",
    "Please try again": "Vänligen försök igen",
    "We'll take it from here": "Vi gör resten av arbetet",
    Okay: "OK",
    Chroma: "Chroma key",
    "Chroma colour": "Chroma färg",
    "Choose colour...": "Välj färg...",
    Mask: "Mask",
    "Your mask": "Din maskning",
    "Add mask...": "Lägg till mask",
    Process: "Bearbeta",
    "Page does not exist.": "Sidan finns inte.",
    "Didn't find what you were looking for? Feel free to":
      "Hittade du inte det du sökte? Tveka inte att",
    "reach out over email": "höra av dig via E-post",
    "Transcript editor": "Transkriptionseditor",
  },
};

import React, { Fragment, useState } from "react";
import { useFirebase } from "react-redux-firebase";
import { Link } from "react-router-dom";

import Button from "../Button";
import Input from "../Input";
import logo from "../../img/equalplay_logo_green.svg";
import { t } from "../../i18n/language";

export default function Reset() {
 const [email, setEmail] = useState("");
 const [buttonText, setButtonText] = useState(t("Reset"));
 const [successMessage, setSuccessMessage] = useState("");
 const [errorMessage, setErrorMessage] = useState("");

 const firebase = useFirebase();

 const onClick = () => {
  return null;
 };

 const onSubmit = (e) => {
  e.preventDefault();

  console.log("reseting password for:", email);
  setButtonText(t("Resetting..."));

  firebase
   .resetPassword(email)
   .then((res) => {
    setErrorMessage("");
    return setSuccessMessage("Sending");
   })
   .catch((err) => {
    setButtonText(t("Reset"));
    return setErrorMessage(t("Please check your email address and try again."));
   });
 };

 return (
  <div className="bg-equalplay-dark h-screen">
   <div className="flex flex-col h-screen items-center justify-center">
    <div className="flex items-center">
     <img className="w-16 h-16" src={logo} alt="Equal Play Logo" />
     <span className="ml-4 text-white text-3xl font-equal-play ">
      Equal Play
     </span>
    </div>

    <form
     onSubmit={onSubmit}
     className="mt-6 px-8 py-10 bg-white w-72 rounded-lg shadow-lg"
    >
     <h2 className="font-semibold">{t("Password reset")}</h2>
     {successMessage === "" ? (
      <Fragment>
       {/* Email input */}
       <div className="mt-4">
        <Input
         label={t("Email")}
         name="email"
         type="email"
         placeholder="me@email.com"
         onChange={(e) => {
          setEmail(e.target.value);
         }}
         value={email}
        />
       </div>
       <div className="mt-6 flex justify-end">
        <Button onClick={onClick} text={buttonText} />
       </div>
      </Fragment>
     ) : (
      <Fragment>
       <span className="mt-2">
        {t("We'll send you an email with the next steps.")}
       </span>
       <div className="mt-4">
        <Link to={process.env.PUBLIC_URL + "/login"} className="underline">
         {t("Return to login")}
        </Link>
       </div>
      </Fragment>
     )}
     {errorMessage !== "" ? (
      <span className="mt-2 italic text-sm text-red-500 inline-block">
       {errorMessage}
      </span>
     ) : null}
    </form>
   </div>
  </div>
 );
}

import React, { Fragment, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import Navbar from "./Navbar";
import TwoColWithSidebar, { Sidebar, Main } from "./layouts/TwoColWithSidebar";
import SidebarTips from "./layouts/SidebarTips";
import SidebarSection from "./layouts/SidebarSection";
import SidebarHeader from "./layouts/SidebarHeader";
import Video from "./layouts/Video";
import Languages from "./layouts/Languages";
import Spinner from "./Spinner";
import Notification from "./Notification";
import { t } from "../i18n/language";

import emptyCustomField from "./utils/customField";
import { bcCMSVideo } from "./utils/brightcoveRequest";

export default function AudioDescription() {
 const [bcQuery, setBcQuery] = useState("");
 const videoId = useParams().id;

 const selectedAccount = useSelector((state) => state.user.selectedAccount);
 const stateVideos = useSelector((state) => state.videos);

 //  console.log(stateVideos)

 useEffect(() => {
  if (stateVideos[videoId]) {
   setBcQuery(stateVideos[videoId]);
  } else {
   if (selectedAccount) {
    bcCMSVideo(selectedAccount, videoId).then((res) => {
     if (res.data === "no results found") {
      setBcQuery(res.data);
     }
    });
   }
  }
  // eslint-disable-next-line
 }, [stateVideos, selectedAccount]);

 return (
  <Fragment>
   <Navbar />
   <Notification />
   <TwoColWithSidebar>
    <Sidebar>
     <SidebarSection>
      <SidebarHeader text={t("Tips & tricks")} icon="tips" />
      <SidebarTips type="ad" />
     </SidebarSection>
    </Sidebar>
    <Main>
     <h1 className="text-xl font-semibold">{t("Audio description")}</h1>
     {bcQuery === "" ? (
      <div className="mt-4 flex">
       <Spinner />
      </div>
     ) : bcQuery === "no results found" ? (
      "Video not found"
     ) : (
      <div className="mt-4 flex">
       <Video title={bcQuery.name} videoId={videoId} />
       <Languages
        type="audio_description"
        deliveryType={bcQuery.delivery_type}
        customfield={
         bcQuery.custom_fields.equalplay
          ? bcQuery.custom_fields.equalplay
          : JSON.stringify(emptyCustomField)
        }
        videoId={videoId}
       />
      </div>
     )}
    </Main>
   </TwoColWithSidebar>
  </Fragment>
 );
}

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useFirebase } from "react-redux-firebase";

import Select from "../Select";
import Button from "../Button";
import { bcAddTextTrack, bcCMSVideo } from "../utils/brightcoveRequest";
import { addNotification } from "../Notification";
import * as editorActions from "../../actions/editor-actions";
import { vttBlob } from "../utils/vttBlob";
import { uploadBlob } from "../utils/uploadFile";
import { t } from "../../i18n/language";

export default function BCTextTrackStatus({ videoId, trackType, closeModal }) {
  const [currentTextTracks, setCurrentTextTracks] = useState([]);
  const [pendingChoice, setPendingChoice] = useState("");

  let trackTypeBC = trackType === "descriptive" ? "descriptions" : trackType; // We call these 'descriptive' but BC calls them 'descriptions'

  const account = useSelector((state) => state.user.selectedAccount);
  const video = useSelector((state) => state.videos[videoId]);
  const project = useSelector((state) => state.projects[videoId]);
  const project_user_specific = useSelector(
    (state) => state.projects_user_specific[videoId]
  );

  const firebase = useFirebase();

  const handlePublishTrack = (choice) => {
    let remove = false;

    if (choice !== "approved") {
      remove = true;
    }

    // TO DO Skip upload when user chooses 'disable' or 'draft'

    let blob = vttBlob(
      project[project_user_specific.selectedLang][trackType].cues,
      trackType,
      project_user_specific.selectedLang
    );

    if (blob === null) {
      return console.log("Issue to fix");
    }

    uploadBlob(
      videoId,
      firebase,
      blob.blob,
      project_user_specific.selectedLang,
      trackType
    ).then(async (url) => {
      await bcAddTextTrack(
        account,
        videoId,
        {
          url: url,
          srclang: project_user_specific.selectedLang,
          kind: trackTypeBC,
          label: project[project_user_specific.selectedLang].label,
          default: false,
        },
        remove
      ).then(async (res) => {
        await bcCMSVideo(account, videoId);
        console.log(res);
        setPendingChoice(choice);
        return addNotification(
          t("Status updated"),
          t(
            "It can take a while to see these changes in your Brightcove account."
          )
        );
      });
    });
  };

  useEffect(() => {
    if (video) {
      setCurrentTextTracks(video.text_tracks || []);
    }
  }, [video]);

  if (!project[project_user_specific.selectedLang][trackType].cues) {
    return (
      <Button
        text="Add"
        colour="white"
        size="small"
        onClick={() => {
          editorActions.addTrack(
            videoId,
            project_user_specific.selectedLang,
            trackType
          );
          return closeModal();
        }}
      />
    );
  } else {
    return (
      <div className="flex flex-col">
        <Select
          selected={
            pendingChoice !== ""
              ? pendingChoice
              : currentTextTracks.filter(
                  (t) =>
                    t.kind === trackTypeBC &&
                    t.srclang === project_user_specific.selectedLang
                ).length > 0
              ? "approved"
              : "draft"
          }
          onSelect={(choice) => {
            setPendingChoice("updating");
            handlePublishTrack(choice);
          }}
        />
        <Button
          text={t("Publish new version")}
          colour="white"
          size="small"
          cssClasses="mt-2"
          iconBefore="publish"
          onClick={() => {
            console.log("should pub new version");
            setPendingChoice("updating");
            handlePublishTrack("approved");
          }}
        />
      </div>
    );
  }
}

import { useState } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";

import { getUser, listBcAccounts, updateUser } from "../utils/adminFunctions";
import { getKey } from "../utils/helpers";
import { t } from "../../i18n/language";
import { LinkIcon } from "../utils/svgs";
import Spinner from "../Spinner";
import Button from "../Button";
import Modal from "../Modal";

function User() {
  const [user, setUser] = useState();
  const [bcAccounts, setBcAccounts] = useState([]);
  const [allAccounts, setAllAccounts] = useState([]);
  const [showAccountModal, setShowAccountModal] = useState(false);
  const { uid } = useParams();

  const addAccount = ({ name, account_id }) => {
    const oldAccounts = [...bcAccounts];

    const index = oldAccounts.findIndex(
      (account) => account.account_id === account_id
    );

    if (index < 0) {
      // New
      oldAccounts.push({ name, account_id });
    } else {
      // Exists already, just update name (might have changed)
      oldAccounts[index].name = name;
    }
    oldAccounts.sort((a, b) => a.name.localeCompare(b.name));

    setBcAccounts(oldAccounts);
    setShowAccountModal(false);
    // console.log("Add an account", name, account_id, index);
  };

  const saveAccounts = () => {
    updateUser({ uid, bc_accounts: bcAccounts })
      .then((response) => {
        console.log(response);
        setUser(null);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  if (!user) {
    Promise.all([getUser(uid), listBcAccounts()])
      .then((data) => {
        // console.log(data);

        data[1].sort((a, b) => a.name.localeCompare(b.name));
        setUser(data[0]);
        setBcAccounts(data[0].bc_accounts ? data[0].bc_accounts : []);
        setAllAccounts(data[1]);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  return (
    <>
      <h1 className="text-2xl font-semibold pb-3">User</h1>
      {!user && <Spinner />}
      {user && (
        <>
          <h2 className="text-xl font-semibold">Details</h2>
          <h4 className="text-sm">
            First / <span className="italic">Last</span> name
          </h4>
          <p>
            {user.first_name} <span className="italic">{user.last_name}</span>{" "}
          </p>
          <h4 className="text-sm">{t("Email")}</h4>
          <p>{user.email}</p>

          <h4 className="text-sm">UID:</h4>
          <p>{user.uid}</p>

          {user.is_admin && (
            <h4 className="font-semibold">Account administrator</h4>
          )}
          {user.is_manager && (
            <h4 className="font-semibold">Dashboard manager</h4>
          )}
          <h2 className="text-xl font-semibold">Brightcove accounts</h2>
          <div className="mt-2 bg-white rounded border border-gray-200 text-sm">
            <div className="px-3 py-2 grid grid-cols-10 text-xs text-gray-600 uppercase font-semibold leading-relaxed">
              <div className="col-span-4">{t("Name")}</div>
              <div className="col-span-4">{"Brightcove ID"}</div>
              <div className="col-span-1 mx-auto">{t("Link")}</div>
              <div className="col-span-1 mx-auto">{t("Delete")}</div>
            </div>
            {bcAccounts.length === 0 && (
              <div className="px-3 py-4 grid grid-cols-10 border-t border-gray-200">
                <div className="col-span-full font-semibold">
                  No accounts for this user...
                </div>
              </div>
            )}
            {bcAccounts.map((account) => {
              return (
                <div
                  key={getKey()}
                  className="px-3 py-4 grid grid-cols-10 border-t border-gray-200"
                >
                  <div className="col-span-4 font-semibold">{account.name}</div>
                  <div className="col-span-4">{account.account_id}</div>
                  <div className="col-span-1 mx-auto">
                    <Link to={`/admin/bcaccounts/${account.account_id}`}>
                      <LinkIcon />
                    </Link>
                  </div>
                  <div className="col-span-1 mx-auto">
                    <Button
                      colour="white"
                      iconBefore="delete"
                      size="small"
                      onClick={() => {
                        const newAccounts = [...bcAccounts].filter(
                          (acc) => acc.account_id !== account.account_id
                        );
                        setBcAccounts(newAccounts);
                      }}
                    />
                  </div>
                </div>
              );
            })}
          </div>
          <div className="mt-4 flex space-x-0.5 items-center">
            <Button
              colour="white"
              text={"Add Brightcove account"}
              onClick={() => {
                setShowAccountModal(true);
              }}
              tooltip={"Add a new Brightcove account to user"}
            />
            <Button
              colour="yellow"
              text={"Save account list"}
              onClick={saveAccounts}
            />
          </div>
        </>
      )}
      <Modal
        show={showAccountModal}
        width="w-90"
        title="Add Account"
        subtitle="Connect Brightcove account to user"
        acceptShow={false}
        cancelDisabled={false}
        cancelButtonText={t("Abort")}
        onClose={() => {
          setShowAccountModal(false);
        }}
        acceptButtonText={""}
        onAccept={() => {}}
      >
        <div className="flex flex-col gap-y-0.5">
          {allAccounts.map((account) => {
            return (
              <Button
                key={getKey()}
                text={account.name}
                tooltip={`ID: ${account.account_id}`}
                onClick={() => {
                  addAccount({
                    account_id: account.account_id,
                    name: account.name,
                  });
                }}
              />
            );
          })}
        </div>
      </Modal>
    </>
  );
}

export default User;

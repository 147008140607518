import React from "react";

export default function Warning({
 title,
 colour = "red",
 customCss = "",
 children
}) {
 return (
  <div
   className={`${customCss} ${
    colour === "red"
     ? "bg-red-100  border-red-500 text-red-600"
     : "bg-orange-100  border-orange-500 text-orange-600"
   } px-4 py-2 rounded border`}
  >
   <h3 className="text-sm font-semibold">{title}</h3>
   <p className="mt-1 text-xs leading-relaxed">{children}</p>
  </div>
 );
}

import React, { Fragment, useState } from "react";
import { useSelector } from "react-redux";

import store from "../auth/firebase";
import { t } from "../../i18n/language";

export default function SidebarFilters({ sort }) {
 const user = useSelector((state) => state.user);

 return (
  <Fragment>
   <div
    className="flex flex-col py-2 pb-4"
    onChange={(e) => {
     sort(e.target.value);
    }}
   >
    <h3 className="text text-gray-600 uppercase text-xs font-bold">
     {t("Sort by")}
    </h3>
    <label className="mt-2 inline-flex items-center">
     <input
      defaultChecked
      type="radio"
      name="accountType"
      value="-updated_at"
     />
     <span className="ml-2 text-sm text-gray-600">{t("Last updated")}</span>
    </label>
    <label className="mt-2 inline-flex items-center">
     <input type="radio" name="accountType" value="-published_at" />
     <span className="ml-2 text-sm text-gray-600">{t("Published date")}</span>
    </label>
    <label className="mt-2 inline-flex items-center">
     <input type="radio" name="accountType" value="name" />
     <span className="ml-2 text-sm text-gray-600">{t("Name")}</span>
    </label>
   </div>
   {/* <div className="flex flex-col py-2 pb-4 border-t border-gray-200">
    <h3 className="text text-gray-600 uppercase text-xs font-bold">
     Accessibility rating
    </h3>
    <label className="mt-2 inline-flex items-center">
     <input
      defaultChecked
      type="checkbox"
      name="accountType"
      value="personal"
     />
     <span className="ml-2 text-sm text-gray-600">AAA</span>
    </label>
    <label className="mt-2 inline-flex items-center">
     <input
      defaultChecked
      type="checkbox"
      name="accountType"
      value="personal"
     />
     <span className="ml-2 text-sm text-gray-600">AA</span>
    </label>
    <label className="mt-2 inline-flex items-center">
     <input
      defaultChecked
      type="checkbox"
      name="accountType"
      value="personal"
     />
     <span className="ml-2 text-sm text-gray-600">A</span>
    </label>
    <label className="mt-2 inline-flex items-center">
     <input
      defaultChecked
      type="checkbox"
      name="accountType"
      value="personal"
     />
     <span className="ml-2 text-sm text-gray-600">-</span>
    </label>
   </div> */}
   <div className="flex flex-col py-2 pb-4 border-t border-gray-200">
    <h3 className="text text-gray-600 uppercase text-xs font-bold">
     {t("Tags")}
    </h3>
    {user.default_filter_tags && user.default_filter_tags.length > 0 ? (
     user.default_filter_tags.map((tag) => {
      return (
       <label key={tag.name} className="mt-2 inline-flex items-center">
        <input
         defaultChecked={tag.default_checked}
         type="checkbox"
         name={tag.name}
         value={tag.name}
         onChange={(e) => {
          //   console.log(e.target.checked, e.target.value);
          store.dispatch({
           type: "TOGGLE_FILTER",
           tag: { name: e.target.value, default_checked: e.target.checked },
          });
         }}
        />
        <span className="ml-2 text-sm text-gray-600">{tag.name}</span>
       </label>
      );
     })
    ) : (
     <span className="mt-2 ml-2 text-sm text-gray-600">
      {t("No tags added")}
     </span>
    )}
    {/* <label className="mt-2 inline-flex items-center">
     <input type="checkbox" name="accountType" value="personal" />
     <span className="ml-2 text-sm text-gray-600">Equal Play</span>
    </label>
    <label className="mt-2 inline-flex items-center">
     <input type="checkbox" name="accountType" value="personal" />
     <span className="ml-2 text-sm text-gray-600">Internal</span>
    </label> */}
   </div>
  </Fragment>
 );
}

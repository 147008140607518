import React from "react";
import { Provider } from "react-redux";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useLocation,
  Outlet,
} from "react-router-dom";
import store, { rrfProps } from "./components/auth/firebase";
import { isLoaded, isEmpty } from "react-redux-firebase";
import { useSelector } from "react-redux";
import { ReactReduxFirebaseProvider } from "react-redux-firebase";

import AudioDescription from "./components/AudioDescription";
import SignLanguage from "./components/SignLanguage";
import SignLanguageEditor from "./components/SignLanguageEditor";
import Editor from "./components/Editor";
import Dashboard from "./components/Dashboard";
import Login from "./components/auth/Login";
import Reset from "./components/auth/Reset";
import Videos from "./components/Videos";
import Settings from "./components/Settings";
import Support from "./components/Support";
import Admin from "./components/admin/Admin";
import PreviewPage from "./components/PreviewPage";

// import dashboardLanguage from "./i18n/language";

function App() {
  return (
    <Provider store={store}>
      <ReactReduxFirebaseProvider {...rrfProps}>
        <BrowserRouter>
          <AuthIsLoaded>
            <div className="bg-gray-100 min-h-screen antialiased text-gray-900">
              <Routes>
                <Route
                  path={process.env.PUBLIC_URL + "/login"}
                  element={<Login />}
                ></Route>
                <Route
                  path={process.env.PUBLIC_URL + "/reset"}
                  element={<Reset />}
                ></Route>
                <Route
                  path={process.env.PUBLIC_URL + "/support/*"}
                  element={<Support />}
                ></Route>
                <Route
                  path={process.env.PUBLIC_URL + "/preview/:accountId/:videoId"}
                  element={<PreviewPage />}
                ></Route>
                <Route element={<PR />}>
                  <Route
                    path={process.env.PUBLIC_URL + "/"}
                    element={<Dashboard />}
                  />
                  <Route
                    path={process.env.PUBLIC_URL + "/settings/*"}
                    element={<Settings />}
                  />
                  <Route
                    path={process.env.PUBLIC_URL + "/sign-language/:id"}
                    element={<SignLanguage />}
                  />
                  <Route
                    path={process.env.PUBLIC_URL + "/sign-language-editor/:id"}
                    element={<SignLanguageEditor />}
                  />
                  <Route
                    path={process.env.PUBLIC_URL + "/audio-description/:id"}
                    element={<AudioDescription />}
                  />
                  <Route
                    path={process.env.PUBLIC_URL + "/editor/:id"}
                    element={<Editor />}
                  />
                  <Route
                    path={process.env.PUBLIC_URL + "/videos"}
                    element={<Videos />}
                  />
                  <Route
                    path={process.env.PUBLIC_URL + "/admin/*"}
                    element={<Admin />}
                  />
                </Route>
              </Routes>
            </div>
          </AuthIsLoaded>
        </BrowserRouter>
      </ReactReduxFirebaseProvider>
    </Provider>
  );
}

// function PrivateRoute({ children, ...rest }) {
//   const auth = useSelector((state) => state.firebase.auth);

//   return (
//     <Route
//       {...rest}
//       render={({ location }) =>
//         isLoaded(auth) && !isEmpty(auth) ? (
//           children
//         ) : (
//           <Navigate
//             to={{
//               pathname: `${process.env.PUBLIC_URL}/login`,
//               state: { from: location },
//             }}
//           />
//         )
//       }
//     />
//   );
// }

/** Private routes */
function PR() {
  const auth = useSelector((state) => state.firebase.auth);
  const loaded = isLoaded(auth) && !isEmpty(auth);
  const location = useLocation();

  return loaded ? (
    <Outlet />
  ) : (
    <Navigate to="/login" state={{ from: location.pathname }} />
  );
}

function AuthIsLoaded({ children }) {
  const auth = useSelector((state) => state.firebase.auth);
  if (!isLoaded(auth)) return <div></div>;
  return children;
}

export default App;

const initialState = {
 tab: "chroma",
};

export default function signLanguageEditor(state = initialState, action) {
 switch (action.type) {
  case "SELECT_TAB":
   return { ...state, tab: action.tab };

  case "CHOOSING_CHROMA_COLOUR":
   return { ...state, choosingChroma: action.choosingChroma };

  case "SELECT_CHROMA_COLOUR":
   return {
    ...state,
    chromaColour: action.chromaColour,
   };

  case "LOADING_IMAGE":
   return { ...state, loadingImage: action.loadingImage };

  case "ADDING_MASK":
   return { ...state, addingMask: action.addingMask };

  case "MASK_ADDED":
   return { ...state, maskAdded: action.maskAdded };

  case "MASK_SHAPE":
   return { ...state, maskShape: action.maskShape };

  case "MASK_APPLIED":
   return { ...state, maskApplied: action.maskApplied };

  default:
   return state;
 }
}

import React, { useEffect } from "react";
import { useSelector } from "react-redux";

export default function Video({ title, width = "w-1/2", videoId }) {
  let accountId = "";
  const user = useSelector((state) => state.user);
  if (user) {
    if (user.selectedAccount) {
      if (user.selectedAccount.account_id)
        accountId = user.selectedAccount.account_id;
      //    console.log(user.selectedAccount.account_id);
    }
  }

  const player_id = useSelector((state) => state.user.player_id);

  useEffect(() => {
    if (accountId !== "") {
      const script = document.createElement("script");

      script.src = `https://players.brightcove.net/${accountId}/${player_id}_default/index.min.js`;
      script.async = true;
      script.defer = true;

      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
  }, [accountId, player_id]);
  return (
    <div className={width}>
      <h2 className="text-gray-600">{title}</h2>
      <div className="mt-2 bg-white rounded border border-gray-200">
        {accountId === "" ? (
          "loading..."
        ) : (
          <video-js
            data-account={accountId}
            data-player={player_id}
            data-embed="default"
            controls=""
            data-video-id={videoId}
            data-playlist-id=""
            data-application-id="Equal Play"
            data-show-draft="true"
            class="vjs-fluid"
          >
            <span className="h-0 inline-block pt-16x9 bg-gray-100"></span>
          </video-js>
        )}
      </div>
    </div>
  );
}

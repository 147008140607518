const secondsToHms = d => {
 d = Number(d);

 var h = Math.floor(d / 3600);
 var m = Math.floor((d % 3600) / 60);
 var s = Math.floor((d % 3600) % 60);

 if (d >= 3600) {
  return (
   ("0" + h).slice(-2) + ":" + ("0" + m).slice(-2) + ":" + ("0" + s).slice(-2)
  );
 } else {
  return ("0" + m).slice(-2) + ":" + ("0" + s).slice(-2);
 }
};

const msToHms = ms => {
 ms = Number(ms);
 var s = ms / 1000;
 var h = parseInt(s / 3600); // 3,600 s in 1 hour
 s = s % 3600; // s remaining after extracting hours
 var m = parseInt(s / 60); // 60 s in 1 minute
 s = s % 60;

 return `${h > 0 ? `${h}:` : ""}${m <= 9 ? `0${m}` : m}:${
  Math.floor(s) <= 9 ? `0${Math.floor(s)}` : Math.floor(s)
 }`;

 //  var h = Math.floor(d / 3600);
 //  var m = Math.floor((d % 3600) / 60);
 //  var s = Math.floor((d % 3600) % 60);

 //  if (d >= 3600) {
 //   return (
 //    ("0" + h).slice(-2) + ":" + ("0" + m).slice(-2) + ":" + ("0" + s).slice(-2)
 //   );
 //  } else {
 //   return ("0" + m).slice(-2) + ":" + ("0" + s).slice(-2);
 //  }
};

export { secondsToHms, msToHms };

import React from "react";

import Button from "../Button";
import dashboardLanguage, { handleLanguageChange } from "../../i18n/language";

export default function LanguageSelector({ colour = "green" }) {
 return (
  <div className="text-white">
   <Button
    colour={colour}
    text="English"
    cssClasses={`px-2 py-1 rounded transition duration-150 ease-in-out hover:bg-equalplay-light ${
     dashboardLanguage() === "en"
      ? ""
      : colour === "green"
      ? "bg-equalplay-dark"
      : "bg-gray-100"
    }`}
    onClick={() => handleLanguageChange("en")}
   />
   <Button
    colour={colour}
    text="Svenska"
    cssClasses={`ml-3 px-2 py-1 rounded transition duration-150 ease-in-out hover:bg-equalplay-light ${
     dashboardLanguage() === "sv"
      ? ""
      : colour === "green"
      ? "bg-equalplay-dark"
      : "bg-gray-100"
    }`}
    onClick={() => handleLanguageChange("sv")}
   />
  </div>
 );
}

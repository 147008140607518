import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore"; // <- needed if using firestore
import "firebase/compat/performance";
import "firebase/compat/functions"; // <- needed if using httpsCallable
import "firebase/compat/storage";
import "firebase/compat/analytics";
import { createStore, combineReducers } from "redux";
import { firebaseReducer } from "react-redux-firebase";
import { createFirestoreInstance, firestoreReducer } from "redux-firestore"; // <- needed if using firestore

import userReducer from "../../reducers/userReducer";
import projectsReducer from "../../reducers/projectsReducer";
import videosReducer from "../../reducers/videosReducer";
import projectsUserSpecificReducer from "../../reducers/projectsUserSpecificReducer";
import notificationsReducer from "../../reducers/notificationsReducer";
import signLanguageEditor from "../../reducers/signLanguageEditorReducer";

const fbConfigStaging = {
  apiKey: "AIzaSyAqGkhc1RaTNtaO_MBupG-9mZJKEInj-yU",
  authDomain: "wallen-media-portal.firebaseapp.com",
  databaseURL: "https://wallen-media-portal.firebaseio.com",
  projectId: "wallen-media-portal",
  storageBucket: "wallen-media-portal.appspot.com",
  messagingSenderId: "552969247368",
  appId: "1:552969247368:web:433aa12f27c9ed57c7dd3d",
  measurementId: "G-0PGPQCP9XK",
};

const fbConfig = {
  apiKey: "AIzaSyA7368DetGdKSJJA4wObfM703FGqagxE1I",
  authDomain: "equal-play.firebaseapp.com",
  databaseURL: "https://equal-play.firebaseio.com",
  projectId: "equal-play",
  storageBucket: "equal-play.appspot.com",
  messagingSenderId: "592558219068",
  appId: "1:592558219068:web:9b639403eb6f5053d30839",
  measurementId: "G-9D9BTLFJ39",
};

// react-redux-firebase config
const rrfConfig = {
  //  userProfile: "users"
  useFirestoreForProfile: true, // Firestore for Profile instead of Realtime DB
};

// Initialize firebase instance
firebase.initializeApp(
  process.env.REACT_APP_ENV === "production" ? fbConfig : fbConfigStaging
  // process.env.REACT_APP_ENV === "production" ? fbConfig : fbConfig
);

// Initialize other services on firebase instance
export const db = firebase.firestore(); // <- needed if using firestore
export const firebaseFunctions = firebase.functions(); // <- needed if using httpsCallable
firebase.storage();
firebase.performance();
firebase.analytics();

// Add firebase to reducers
const rootReducer = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer, // <- needed if using firestore
  user: userReducer,
  videos: videosReducer,
  notifications: notificationsReducer,
  projects: projectsReducer,
  projects_user_specific: projectsUserSpecificReducer,
  sign_language_editor: signLanguageEditor,
});

// Create store with reducers and initial state
const initialState = {};

const store = createStore(
  rootReducer,
  initialState,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance, // <- needed if using firestore
};

export const functionBaseUrl = process.env.REACT_APP_ENV === "production" ? "https://europe-west1-equal-play.cloudfunctions.net/" : "https://europe-west1-wallen-media-portal.cloudfunctions.net/";

export default store;

import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router";
import { useFirebase } from "react-redux-firebase";
import { Link } from "react-router-dom";

import Button from "../Button";
import Input from "../Input";
import logo from "../../img/equalplay_logo_green.svg";
import { t } from "../../i18n/language";
import LanguageSelector from "../settings/LanguageSelector";

import { useSelector } from "react-redux";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [buttonText, setButtonText] = useState(t("Login"));
  const [errorMessage, setErrorMessage] = useState("");

  const [userUid, setUserUid] = useState("");
  const [authenticated, setAuthenticated] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useSelector((state) => {
    // console.log("Auth state:", state);
    // console.log(state.firebase.auth, userUid);
    // console.log("Match?:", state.firebase.auth?.uid === userUid);
    if (state.firebase.auth?.uid === userUid) {
      if (!authenticated) {
        setAuthenticated(true);
      }
    }
  });

  useEffect(() => {
    if (authenticated) {
      if (location.state?.from) {
        // console.info("From in state:", location.state);
        navigate(process.env.PUBLIC_URL + location.state.from);
      } else {
        navigate(process.env.PUBLIC_URL + "/");
      }
    }
  }, [authenticated]);

  const firebase = useFirebase();

  const onClick = () => {
    return null;
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setButtonText(t("Logging in..."));
    firebase
      .login({
        email,
        password,
      })
      .then((res) => {
        setErrorMessage("");
        if (res?.user?.user?.uid) {
          setUserUid(res.user.user.uid);
          setButtonText(t("Login"));
        }

        // Changed the timeout to a redux selector

        // console.log("res", res);

        // setTimeout(() => {
        //   setButtonText(t("Login"));

        //   //  if (history.location.state) {
        //   //   return history.push(
        //   //    process.env.PUBLIC_URL + history.location.state.from.pathname
        //   //   );
        //   //  } else {
        //   //   return history.push(process.env.PUBLIC_URL + "/");
        //   //  }
        //   if (location.state?.from) {
        //     // console.info("From in state:", location.state);
        //     return navigate(process.env.PUBLIC_URL + location.state.from);
        //   } else {
        //     return navigate(process.env.PUBLIC_URL + "/");
        //   }
        // }, 1000);
      })
      .catch((err) => {
        console.log("Login error:", err);
        setButtonText(t("Login"));
        setUserUid("");
        return setErrorMessage(t("Please check your email or password."));
      });
  };

  return (
    <div className="bg-equalplay-dark h-screen relative">
      <div className="absolute px-4 py-3 right-0 text-sm text-white top-0">
        <LanguageSelector />
      </div>
      <div className="flex flex-col h-screen items-center justify-center">
        <div className="flex items-center">
          <img className="w-16 h-16" src={logo} alt="Equal Play Logo" />
          <span className="ml-4 text-white text-3xl font-equal-play ">
            Equal Play
          </span>
        </div>
        <form
          onSubmit={onSubmit}
          className="mt-6 px-8 py-10 bg-white w-72 rounded-lg shadow-lg"
        >
          {/* Email input */}
          <div>
            <Input
              label={t("Email")}
              name="email"
              type="email"
              placeholder="me@email.com"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              value={email}
            />
          </div>
          {/* Password input */}
          <div className="mt-5">
            <Input
              label={t("Password")}
              name="password"
              type="password"
              placeholder="••••••••"
              autoComplete="current-password"
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              value={password}
            />
          </div>
          <div className="mt-6 flex justify-between items-center">
            {/* eslint-disable-next-line */}
            <Link
              to={process.env.PUBLIC_URL + "/reset"}
              className="text-xs text-gray-600 hover:underline"
            >
              {t("Forgot password?")}
            </Link>
            <Button onClick={onClick} text={buttonText} />
          </div>
          {errorMessage !== "" ? (
            <span className="mt-2 italic text-sm text-red-500 inline-block">
              {errorMessage}
            </span>
          ) : null}
        </form>
        <div className="mt-4 text-white text-xs">
          {t("Don’t have an account?")} {/* eslint-disable-next-line */}
          {/* <a
            href={`https://equalplay.eu/${dashboardLanguage()}`}
            className="underline"
          > */}
          <a href={`https://equalplay.eu/`} className="underline">
            {t("Get started!")}
          </a>
        </div>
      </div>
    </div>
  );
}

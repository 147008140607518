/**
 *
 * @param {[]} capCues - Cues from the caption/subtitle track (capCues preferred)
 * @param {[]} descCues - Cues from the descriptive track
 * @param {[]} chapCues - Cues from the chapter track
 * @param {string} [langCode] - Language code 'sv-SE'
 * @param {string} [CSSPrefx=equalplay] - Prefix for classes, 'equalplay-transcript-seekpoint'
 *
 * @example
 * generateTranscript([], null, null, 'sv-SE', 'equalplay')
 * @returns {HTMLElement} <article>
 */
function generateTranscript(
  capCues = [],
  descCues = [],
  chapCues = [],
  langCode,
  CSSPrefx
) {
  var cssPre = CSSPrefx || "equalplay";
  //  var capCues = capCues || [],
  //   descCues = descCues || [],
  //   chapCues = chapCues || [];

  //  console.log(
  //   "capCues",
  //   capCues,
  //   "descCues",
  //   descCues,
  //   "chapCues",
  //   chapCues,
  //   langCode
  //  );

  var container = document.createElement("article");
  container.className = cssPre + "-transcript";
  if (langCode) {
    container.setAttribute("lang", langCode.substr(0, 2));
  }

  var nextChapter = 0;
  var nextCap = 0;
  var nextDesc = 0;

  var addChapter = function (target, content) {
    // var c = document.createElement('span');
    // c.dataset.start = content.start;
    // c.dataset.end = content.end;
    // c.innerText = content.text.trim();
    // c.className = 'vjs-equalplay-transcript-seekpoint';

    // var h = document.createElement('h4');
    // h.appendChild(c);
    // target.appendChild(h);
    var c = document.createElement("h4");
    c.dataset.start = content.start;
    c.dataset.end = content.end;
    c.innerText = content.text.trim();
    c.className = cssPre + "-transcript-seekpoint";

    target.appendChild(c);
  };
  var addDescription = function (target, content) {
    //Clear line breaks
    var text = content.text.trim().replace(/\r?\n|\r/g, " ") + " ";

    //Clear starting and trailing '-' (Malmö standard?)
    if (text.charAt(0) === "-") {
      text = text.substr(1, text.length);
    }
    if (text.charAt(text.length - 2) === "-") {
      text = text.substr(0, text.length - 2) + " ";
    }

    var c = document.createElement("span");
    c.dataset.start = content.start;
    c.dataset.end = content.end;

    //clear tags with replace below...
    c.innerText = text.replace(/(<([^>]+)>)/gi, "");
    c.className = cssPre + "-transcript-seekpoint";

    target.appendChild(c);
  };
  //Fix för att kunna lägga flera syntolkningar i samma låda
  var descriptionContainer = {
    inDescription: false,
    container: null,
    createContainer: function () {
      this.container = document.createElement("div");
      this.container.className = cssPre + "-transcript-description";
      this.inDescription = true;
      return this.container;
    },
  };

  var addCaption = function (target, content) {
    //remove linebreaks...
    var text = content.text.trim().replace(/\r?\n|\r/g, " ") + " ";

    //regex for all tags except starting with b, i, c (this includes 'bold' as well as 'b')
    // <\s?\/?(?!b)(?!i)(?!c)\w*\b[^>]*>   UPDATE: removing C
    text = text.replace(/<\s?\/?(?!b)(?!i)\w*\b[^>]*>/gi, "");

    //opening and closing b
    text = text.replace(/<\s?\/b\w*\b[^>]*>/gi, "</strong>");
    text = text.replace(/<\s?b\w*\b[^>]*>/gi, "<strong>");

    //opening and closing i
    text = text.replace(/<\s?\/i\w*\b[^>]*>/gi, "</em>");
    text = text.replace(/<\s?i\w*\b[^>]*>/gi, "<em>");

    //audio in brackets? [weird sound]
    if (text.match(/\[(.*?)\]/gi)) {
      var soundCues = text.match(/\[(.*?)\]/gi);
      //console.log(soundCues);
      for (var i = 0; i < soundCues.length; i++) {
        //console.log(soundCues[i].replace('[', '').replace(']', ''));
        var reg = new RegExp(
          "\\[" + soundCues[i].replace("[", "").replace("]", "") + "\\]",
          "ig"
        );
        //console.log(reg);
        text = text.replace(
          reg,
          '<br/><span class="sound">' + soundCues[i] + "</span><br/>"
        );
      }
    }

    //Speaker (Speaker Name)
    if (text.match(/\((.*?)\)/gi)) {
      var speakerCues = text.match(/\((.*?)\)/gi);
      //console.log(soundCues);
      for (var j = 0; j < speakerCues.length; j++) {
        //console.log(soundCues[j].replace('[', '').replace(']', ''));
        try {
          var reg2 = new RegExp(
            "\\(" + speakerCues[j].replace("(", "").replace(")", "") + "\\)",
            "ig"
          );
          //console.log(reg);
          text = text
            .replace(
              reg2,
              '<br/><span class="speaker">' + speakerCues[j] + "</span>"
            )
            .replace("(", "")
            .replace(")", ": ");
        } catch (e) {
          console.info("Transcript error:", e);
        }
      }
    }

    //Remove starting and trailing '-'
    if (text.charAt(0) === "-") {
      text = text.substr(1, text.length);
    }
    if (text.charAt(text.length - 2) === "-") {
      text = text.substr(0, text.length - 2) + " ";
    }

    //Create element
    var c = document.createElement("span");
    c.dataset.start = content.start;
    c.dataset.end = content.end;
    c.className = cssPre + "-transcript-seekpoint";
    c.innerHTML = text;
    c.dataset.id = content.id;

    target.appendChild(c);
  };

  var firstStart;

  while (
    nextChapter < chapCues.length ||
    nextDesc < descCues.length ||
    nextCap < capCues.length
  ) {
    if (
      nextChapter < chapCues.length &&
      nextDesc < descCues.length &&
      nextCap < capCues.length
    ) {
      // they all three have content
      firstStart = Math.min(
        chapCues[nextChapter].start,
        descCues[nextDesc].start,
        capCues[nextCap].start
      );
    } else if (nextChapter < chapCues.length && nextDesc < descCues.length) {
      // chapCues & descCues have content
      firstStart = Math.min(
        chapCues[nextChapter].start,
        descCues[nextDesc].start
      );
    } else if (nextChapter < chapCues.length && nextCap < capCues.length) {
      // chapCues & capCues have content
      firstStart = Math.min(
        chapCues[nextChapter].start,
        capCues[nextCap].start
      );
    } else if (nextDesc < descCues.length && nextCap < capCues.length) {
      // descCues & capCues have content
      firstStart = Math.min(descCues[nextDesc].start, capCues[nextCap].start);
    } else {
      firstStart = null;
    }
    if (firstStart !== null) {
      if (
        typeof chapCues[nextChapter] !== "undefined" &&
        chapCues[nextChapter].start === firstStart
      ) {
        if (descriptionContainer.inDescription) {
          descriptionContainer.inDescription = false;
          container.appendChild(descriptionContainer.container);
        }
        addChapter(container, chapCues[nextChapter]);
        nextChapter += 1;
      } else if (
        typeof descCues[nextDesc] !== "undefined" &&
        descCues[nextDesc].start === firstStart
      ) {
        if (descriptionContainer.inDescription) {
          addDescription(descriptionContainer.container, descCues[nextDesc]);
        } else {
          addDescription(
            descriptionContainer.createContainer(),
            descCues[nextDesc]
          );
        }

        //addDescription(container, descCues[nextDesc]);
        nextDesc += 1;
      } else {
        if (descriptionContainer.inDescription) {
          descriptionContainer.inDescription = false;
          container.appendChild(descriptionContainer.container);
        }
        addCaption(container, capCues[nextCap]);
        nextCap += 1;
      }
    } else {
      if (nextChapter < chapCues.length) {
        if (descriptionContainer.inDescription) {
          descriptionContainer.inDescription = false;
          container.appendChild(descriptionContainer.container);
        }
        addChapter(container, chapCues[nextChapter]);
        nextChapter += 1;
      } else if (nextDesc < descCues.length) {
        if (descriptionContainer.inDescription) {
          addDescription(descriptionContainer.container, descCues[nextDesc]);
        } else {
          addDescription(
            descriptionContainer.createContainer(),
            descCues[nextDesc]
          );
        }
        //addDescription(container, descCues[nextDesc]);
        nextDesc += 1;
      } else if (nextCap < capCues.length) {
        if (descriptionContainer.inDescription) {
          descriptionContainer.inDescription = false;
          container.appendChild(descriptionContainer.container);
        }
        addCaption(container, capCues[nextCap]);
        nextCap += 1;
      }
    }
  }
  //If the lastcue was a new description
  if (descriptionContainer.inDescription) {
    descriptionContainer.inDescription = false;
    container.appendChild(descriptionContainer.container);
  }

  return container;
}

export { generateTranscript };

import randomatic from "randomatic";

/** Returns a random key,
 *  Currently randomatic:
 *  https://www.npmjs.com/package/randomatic
 */
const getKey = (length) => {
  return randomatic("Aa0", length || 8);
};

export { getKey };

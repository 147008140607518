import { useSelector } from "react-redux";
import { Navigate, Routes, Route } from "react-router-dom";

import Navbar from "../Navbar";
import Notification from "../Notification";
import TwoColWithSidebar, { Sidebar, Main } from "../layouts/TwoColWithSidebar";
import SidebarSection from "../layouts/SidebarSection";
import SidebarHeader from "../layouts/SidebarHeader";
import SidebarLink from "../layouts/SidebarLink";
import { t } from "../../i18n/language";

import Organizations from "./Organizations";
import BcAccounts from "./BcAccounts";
import BcAccount from "./BcAccount";
import Users from "./Users";
import User from "./User";
import Plugin from "./Plugin";

function Admin() {
  const currentUser = useSelector((state) => state.user);
  const isManager = currentUser?.is_manager;

  if (currentUser.uid && !currentUser.is_manager) {
    // there is a user, but it's not an acoount manager...
    return <Navigate to="/" />;
  }

  return (
    <>
      <Navbar />
      <Notification />
      {isManager && (
        <>
          <TwoColWithSidebar>
            <Sidebar>
              <SidebarSection>
                <SidebarHeader text={t("Settings")} icon="settings" />
                <div className="mt-4 flex flex-col items-start">
                  <SidebarLink title={t("Accounts")} to={`/admin/accounts`} />
                  <SidebarLink
                    title={`Brightcove ${t("Accounts")}`}
                    to={`/admin/bcaccounts`}
                  />
                  <SidebarLink title={t("Users")} to={`/admin/users`} />
                  <SidebarLink title={t("Plugin")} to={`/admin/plugin`} />
                </div>
              </SidebarSection>
            </Sidebar>
            <Main>
              <Routes>
                <Route
                  path="/"
                  element={
                    <h1 className="text-2xl font-semibold pb-3">
                      Dashboard administration
                    </h1>
                  }
                />
                <Route path="/accounts" element={<Organizations />} />
                <Route path="/bcaccounts" element={<BcAccounts />} />
                <Route path="/bcaccounts/:bcid" element={<BcAccount />} />
                <Route path="/users" element={<Users />} />
                <Route path="/users/:uid" element={<User />} />
                <Route path="/plugin" element={<Plugin />} />
              </Routes>
            </Main>
          </TwoColWithSidebar>
        </>
      )}
    </>
  );
}

export default Admin;

import React, { useState } from "react";
import { useFirebase } from "react-redux-firebase";

import { parseVTT } from "./utils/parseVTT";
import { t } from "../i18n/language";

export default function FileUploader({
 type,
 videoId,
 pathToUploadedFile,
 disabled,
 disabledText,
 importedFile,
}) {
 const [uploadTaskRef, setUploadTaskRef] = useState();
 const [progress, setProgess] = useState(0);
 const [fileName, setFileName] = useState("");
 const [uploaded, setUploaded] = useState(false);
 const [showUploading, setShowUploading] = useState(false);
 const [importError, setImportError] = useState(false);
 const [importErrorMessage, setImportErrorMessage] = useState("");

 const firebase = useFirebase();

 const cancelUpload = () => {
  uploadTaskRef.cancel();
  setProgess(0);
  setFileName("");
  setUploaded(false);
  setShowUploading(false);
  setUploadTaskRef();
 };

 const startUpload = (firebase, file) => {
  const storageRef = firebase.storage().ref();
  setFileName(file.name);
  setShowUploading(true);

  // var uploadTask = storageRef.child('images/rivers.jpg').put(file);
  let uploadTask = storageRef
   .child(`/${videoId}/${Math.floor(Date.now() / 1000)} - ${file.name}`)
   .put(file);
  setUploadTaskRef(uploadTask);

  // Register three observers:
  // 1. 'state_changed' observer, called any time the state changes
  // 2. Error observer, called on failure
  // 3. Completion observer, called on successful completion
  uploadTask.on(
   "state_changed",
   function (snapshot) {
    // Observe state change events such as progress, pause, and resume
    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
    var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    console.log("Upload is " + progress + "% done");
    setProgess(Math.floor(progress));
    switch (snapshot.state) {
     case firebase.storage.TaskState.PAUSED: // or 'paused'
      console.log("Upload is paused");
      break;
     case firebase.storage.TaskState.RUNNING: // or 'running'
      console.log("Upload is running");
      break;
     default:
      return console.log("all good");
    }
   },
   function (error) {
    // Handle unsuccessful uploads
    console.error(error);
   },
   function () {
    // Handle successful uploads on complete
    // For instance, get the download URL: https://firebasestorage.googleapis.com/...
    uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
     //  console.log("File available at", downloadURL);
     pathToUploadedFile(downloadURL);
     setUploaded(true);
    });
   }
  );
 };

 const parseTextTrackFile = (file) => {
  setFileName(file.name);
  setShowUploading(true);
  setImportError(false);
  setImportErrorMessage("");
  var text = new Response(file)
   .text()
   .then((res) => {
    setUploaded(true);
    setProgess(100);
    return parseVTT(res, true);
   })
   .then((res) => {
    return importedFile(res);
   })
   .catch((err) => {
    setImportError(true);
    setImportErrorMessage(`VTT Error: ${err.message}`);
   });
  return text;
 };

 return (
  <div>
   {disabled ? (
    <label className="w-full h-16 inline-block flex justify-center items-center text-gray-400 rounded border border-gray-300 border-dashed">
     {disabledText}
    </label>
   ) : !showUploading ? (
    <label
     className="w-full h-16 inline-block flex justify-center items-center cursor-pointer text-gray-600 rounded border border-gray-400 border-dashed hover:border-gray-600 hover:text-gray-700 focus:shadow-inner"
     htmlFor="file"
    >
     {t("Browse")}
    </label>
   ) : (
    <div className="w-full px-4 py-2 flex flex-col text-gray-800 text-sm rounded border border-gray-400">
     <div className="font-semibold text-gray-800">{fileName}</div>
     <div className="mt-2 h-1 relative">
      <div
       style={{ width: `${progress}%` }}
       className={`${
        importError
         ? "bg-red-500"
         : uploaded
         ? "bg-equalplay-light"
         : "bg-gray-700"
       } h-1 absolute right-0 left-0 z-10`}
      ></div>
      <div className="bg-gray-200 h-1 absolute right-0 left-0"></div>
     </div>
     <div className="mt-2 flex text-sm justify-between text-gray-700">
      {importError ? (
       importErrorMessage
      ) : uploaded ? (
       <div>{t("Uploaded!")}</div>
      ) : (
       <button onClick={cancelUpload} className="underline">
        {t("Cancel")}
       </button>
      )}
      <div>{importError ? "" : `${progress}%`}</div>
     </div>
    </div>
   )}
   <input
    onChange={(e) => {
     if (type === "vtt") {
      parseTextTrackFile(e.target.files[0]);
     } else {
      startUpload(firebase, e.target.files[0]);
     }
    }}
    hidden
    className="mt-10"
    type="file"
    accept={type === "vtt" ? ".vtt" : "audio/*"}
    id="file"
    name="file"
   />
  </div>
 );
}

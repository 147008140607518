import React, { Fragment } from "react";

export default function SidebarSection(props) {
 return (
  <div className="px-4 py-2 mb-auto bg-white border border-gray-200 rounded">
   {props.children.map((child, i) => {
    return <Fragment key={i}>{child}</Fragment>;
   })}
  </div>
 );
}

import { useState } from "react";
import { listClients, createClient } from "../utils/adminFunctions";

import { addNotification } from "../Notification";
import Button from "../Button";
import Modal from "../Modal";
import Input from "../Input";
import { t } from "../../i18n/language";
import { Tick } from "../utils/svgs";
import Spinner from "../Spinner";

function Organizations() {
  const [orgs, setOrgs] = useState();
  const [showCreate, setShowCreate] = useState(false);
  const [clientName, setClientName] = useState();
  const [userLimit, setUserLimit] = useState(10);

  // console.log("Orgs load,", orgs, userLimit);

  const handleCreateClient = () => {
    // console.log("Create client", clientName, userLimit);
    createClient({ name: clientName, user_limit: userLimit })
      .then((clientId) => {
        console.log("New client,", clientId);
        setShowCreate(false);
        setOrgs(null);
        return addNotification(
          "Client created",
          `${clientName} created, ${clientId}`,
          "bg-equalplay-light"
        );
      })
      .catch((err) => {
        setShowCreate(false);
        return addNotification(
          t("Error"),
          `${clientName} created, ${err.message}`,
          "bg-red-600"
        );
      });
  };

  if (!orgs) {
    listClients()
      .then((clients) => {
        setOrgs(clients);
      })
      .catch((err) => {
        console.error(err);
        return addNotification(
          t("Error"),
          `${err.message}`,
          "bg-red-600"
        );
      });
  }

  return (
    <>
      <h2 className="text-xl font-semibold">{t("Clients")}</h2>
      {!orgs && <Spinner />}
      <div className="mt-2 bg-white rounded border border-gray-200 text-sm">
        <div className="px-3 py-2 grid grid-cols-11 text-xs text-gray-600 uppercase font-semibold leading-relaxed">
          <div className="col-span-4">{t("Name")}</div>
          <div className="col-span-4">{"Database ID"}</div>
          <div className="col-span-2 flex items-center">{t("User Limit")}</div>
          <div className="col-span-1 mx-auto">{"Has BC"}</div>
        </div>
        {orgs &&
          orgs.map((org) => {
            return (
              <div
                className="px-3 py-4 grid grid-cols-11 border-t border-gray-200"
                key={org.org_id}
              >
                <div className="col-span-4 font-semibold">{org.name}</div>
                <div className="col-span-4">{org.org_id}</div>
                <div className="col-span-2 flex items-center">
                  {org.user_limit}
                </div>
                <div className="mx-auto col-span-1">
                  {org.bc_accounts && <Tick />}
                </div>
              </div>
            );
          })}
      </div>
      <div className="mt-4 flex items-center">
        <Button
          colour="white"
          text={"Add Client"}
          onClick={() => {
            // console.info("Add new client button clicked");
            setShowCreate(true);
          }}
          tooltip={"Add a new client"}
        />
      </div>
      <Modal
        show={showCreate}
        title={t("Create client")}
        cancelDisabled={false}
        cancelButtonText={t("Cancel")}
        onClose={() => {
          // console.info("closing modal");
          setShowCreate(false);
        }}
        acceptButtonText={t("Create")}
        onAccept={handleCreateClient}
      >
        <Input
          css="mt-2"
          label={t("Client name")}
          name="clientname"
          type="text"
          value={clientName}
          placeholder={t("Client name")}
          onChange={(e) => {
            setClientName(e.target.value);
          }}
        />
        <Input
          css="mt-2"
          label={t("User Limit")}
          name="userlimit"
          type="text"
          value={userLimit}
          placeholder={t("User Limit")}
          onChange={(e) => {
            const val = parseInt(e.target.value);
            Number.isNaN(val) ? setUserLimit(10) : setUserLimit(val);
          }}
        />
      </Modal>
    </>
  );
}

export default Organizations;

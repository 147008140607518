import React, { Fragment } from "react";
import { Link, useLocation } from "react-router-dom";
import PropTypes from "prop-types";

export default function SidebarLink({ title, to }) {
 const location = useLocation();

 return (
  <Fragment>
   <Link
    to={to}
    className={`${
     location.pathname === to ? "text-gray-900 font-semibold" : "text-gray-700"
    } px-3 mb-4 focus:outline-none hover:text-gray-900 focus:text-gray-900 text-sm`}
    // onClick={() => onClick(label)}
   >
    {title}
   </Link>
  </Fragment>
 );
}

SidebarLink.propTypes = {
 title: PropTypes.string.isRequired,
 to: PropTypes.string.isRequired,
};

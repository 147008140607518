import store from "../components/auth/firebase";

export const selectTab = (tab) => {
 return store.dispatch({
  type: "SELECT_TAB",
  tab,
 });
};

export const choosingChromaColour = (bool) => {
 return store.dispatch({
  type: "CHOOSING_CHROMA_COLOUR",
  choosingChroma: bool,
 });
};

export const selectChromaColour = (chromaColour) => {
 return store.dispatch({ type: "SELECT_CHROMA_COLOUR", chromaColour });
};

export const loadingImage = (bool) => {
 return store.dispatch({ type: "LOADING_IMAGE", loadingImage: bool });
};

export const addingMask = (bool) => {
 return store.dispatch({ type: "ADDING_MASK", addingMask: bool });
};

export const maskAdded = (bool) => {
 return store.dispatch({ type: "MASK_ADDED", maskAdded: bool });
};

export const maskShape = (mask) => {
 return store.dispatch({ type: "MASK_SHAPE", maskShape: mask });
};

export const maskApplied = (bool) => {
 return store.dispatch({ type: "MASK_APPLIED", maskApplied: bool });
};

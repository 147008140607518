import React, { Fragment, useState, useEffect } from "react";
import { useSelector } from "react-redux";

export default function TextTracks({ videoId, player }) {
  // const [tracksRemoved, setTracksRemoved] = useState(false);
  const [remoteTextTrack, setRemoteTextTrack] = useState();

  const selectedLang = useSelector(
    (state) => state.projects_user_specific[videoId].selectedLang
  );
  const selectedTrack = useSelector(
    (state) => state.projects_user_specific[videoId].selectedTrack
  );
  // const selectedCue = useSelector(
  //   (state) => state.projects_user_specific[videoId].selectedCue
  // );

  const tracks = useSelector((state) => state.projects[videoId][selectedLang]);

  // REMOTE TEXT TRACKS
  // const addActiveRemoteTextTrack = (track) => {
  //   if (!track) {
  //     console.info("No track to add:", track);
  //     return;
  //   }

  //   console.log("Adding track:", track);

  //   const t = player.addRemoteTextTrack(
  //     {
  //       id: `${selectedLang}_${track}`,
  //       kind: track,
  //       srclang: selectedLang,
  //       label: tracks.label,
  //       default: true,
  //     },
  //     true
  //   );

  //   console.log("This is T:", t);
  //   // CHECK IF TRACK HAS ANY CUES
  //   if (tracks[selectedTrack].cues && tracks[selectedTrack].cues.length > 0) {
  //     tracks[track].cues.map((cue) => {
  //       // ADD VTT FOR SUPPORTED BROWSERS
  //       const c = new VTTCue(cue.start, cue.end, cue.text);
  //       c.id = cue.id;
  //       c.size = 100;
  //       return t.track.addCue(c);
  //     });
  //   }

  //   setRemoteTextTrack(t.track);
  //   setTracksRemoved(false);

  //   return (t.track.mode = "showing");
  // };

  // REMOVE ALL TEXT TRACKS ON LOAD
  // const removeRemoteTextTracks = () => {
  //   player.textTracks().tracks_.map((track, i) => {
  //     console.log("Text tracks", track, i);
  //     if (track.kind !== "metadata") {
  //       return player.textTracks().removeTrack(track);
  //     }
  //   });

  //   player.remoteTextTracks().tracks_.map((track) => {
  //     console.log("Remote Text Tracks", track);
  //     return player.removeRemoteTextTrack(track);
  //   });

  //   //Don't really agree to this approach since it reloads the player an insane amount of times.. but the old track removing threw errors so...
  //   //Removing the last track throws an error, but could probably keep all metadata tracks anyways
  //   //
  //   if (!remoteTextTrack) {
  //     return addActiveRemoteTextTrack(selectedTrack);
  //   }

  //   // let remoteTracks = player.remoteTextTracks(),
  //   //   tracksToRemove = [];
  //   // for (let i = 0; i < remoteTracks.length; i++) {
  //   //   if (remoteTracks[i].kind !== "metadata") {
  //   //     if (remoteTracks[i] !== remoteTextTrack) {
  //   //       //No need to remove the active track...
  //   //       tracksToRemove.push(i);
  //   //     }
  //   //   }
  //   // }
  //   // if (tracksToRemove.length > 0) {
  //   //   for (let i = tracksToRemove.length - 1; i >= 0; i--) {
  //   //     player.removeRemoteTextTrack(
  //   //       player.remoteTextTracks()[tracksToRemove[i]]
  //   //     );
  //   //   }
  //   // }

  //   //console.log("Remove theese", tracksToRemove);

  //   // if (!remoteTextTrack) {
  //   //   return addActiveRemoteTextTrack(kind);
  //   // }
  //   // if (remoteTextTrack && remoteTextTrack.kind !== kind) {
  //   //   return addActiveRemoteTextTrack(kind);
  //   // }
  // };

  // useEffect(() => {
  //   console.info("Effect: player, selectedLang, selectedTrack");
  //   if (player) {
  //     console.info("Player changed, remove all tracks...");
  //     removeRemoteTextTracks();
  //     //removeAllTextTracks(player);
  //   }
  //   // eslint-disable-next-line
  // }, [player, selectedLang, selectedTrack, selectedCue]);

  // useEffect(() => {
  //   console.log("Effect: selectedCue", selectedCue);
  // }, [selectedCue]);

  // useEffect(() => {
  //   console.log("Effect: tracks", tracks);
  // }, [tracks]);

  // useEffect(() => {
  //   //UPDATE REMOTE TEXT TRACK ON CUE CHANGES
  //   console.info("Effect: tracks, remoteTextTrack, selectedLang");
  //   if (
  //     remoteTextTrack &&
  //     remoteTextTrack.cues_ &&
  //     selectedLang &&
  //     tracks[selectedTrack] &&
  //     tracks[selectedTrack].cues
  //   ) {
  //     remoteTextTrack.cues_.map((cue) => {
  //       if (cue.id !== selectedCue) {
  //         return null;
  //       } else {
  //         const refCue = tracks[selectedTrack].cues.filter(
  //           (cue) => cue.id === selectedCue
  //         )[0];
  //         cue.startTime = refCue ? refCue.start : 0;
  //         cue.endTime = refCue ? refCue.end : 0;
  //         cue.text = refCue ? refCue.text : null;
  //         return cue;
  //       }
  //     });
  //     return player.textTrackDisplay.updateDisplay();
  //   }

  //   // eslint-disable-next-line
  // }, [tracks, remoteTextTrack, selectedLang]);

  // useEffect(() => {
  //   if (tracksRemoved) {
  //     console.info("Selected track changed to:", selectedTrack);
  //     addActiveRemoteTextTrack(selectedTrack);
  //   }

  //   return () => {
  //     // console.info("Selected track changed from:", selectedTrack);
  //     // removeTrackOfKind(selectedTrack);
  //   };
  // }, [selectedTrack, tracksRemoved]);

  // const removeAllTextTracks = (player) => {
  //   if (tracksRemoved) {
  //     console.info("Tracks already removed");
  //     return;
  //   }
  //   console.log("removeAllTextTracks", tracksRemoved);
  //   if (!player) {
  //     console.warn("No player:", player);
  //     return;
  //   }
  //   if (player.remoteTextTracks().tracks_.length === 0) {
  //     console.warn("Length is null:", player);
  //     return;
  //   }

  //   try {
  //     player.remoteTextTracks().tracks_.map((track) => {
  //       // console.log("Remote Text Tracks", track);
  //       return player.removeRemoteTextTrack(track);
  //     });
  //     player.textTracks().tracks_.map((track, i) => {
  //       // console.log("Text tracks", track, i);
  //       if (track.kind !== "metadata") {
  //         return player.textTracks().removeTrack(track);
  //       }
  //     });
  //   } catch (err) {
  //     console.warn("Caught an error,", err);
  //   }
  //   console.info(player.remoteTextTracks().tracks_);

  //   setTracksRemoved(true);
  // };

  // useEffect(() => {
  //   // Perhaps we dont need to remove all tracks, perhaps with multiple languages...
  //   console.info("Effect: player, ", player);

  //   return () => {
  //     console.info("Return - Effect: player, ");
  //   };
  // }, [player]);

  useEffect(() => {
    const setActivePreviewTrack = (kind) => {
      // console.info("Set active preview track:", kind);
      if (!player || !tracks) {
        return;
      }
      // fix for descriptive -> descriptions
      if (kind === "descriptive") {
        kind = "descriptions";
      }
      // First check if there already is a track of the same kind...
      // Added language as well (slice is for "sv-SE" -> "sv")
      let track = player
        .remoteTextTracks()
        .tracks_.filter(
          (track) =>
            track.kind === kind && track.language.slice(0, 2) === selectedLang
        )[0];

      if (!track) {
        track = player.addRemoteTextTrack(
          {
            id: `${selectedLang}_${kind}`,
            kind: kind,
            srclang: selectedLang,
            label: tracks.label,
            default: true,
          },
          true
        ).track;
      }

      // Hide all tracks...
      player.remoteTextTracks().tracks_.forEach((track) => {
        track.mode = "hidden";
      });
      track.mode = "showing";

      if (
        track.cues_.length > 0 &&
        track.cues_.length !== tracks[selectedTrack].cues.length
      ) {
        // On first load, an old BC track can still have cues, forcing them in like this
        // console.info("Cues mismatch....");
        track.cues_ = [];
        tracks[selectedTrack].cues.map((cue) => {
          // ADD VTT FOR SUPPORTED BROWSERS
          const c = new VTTCue(cue.start, cue.end, cue.text);
          c.id = cue.id;
          c.size = 100;
          return track.addCue(c);
        });
        player.textTrackDisplay.updateDisplay();
      }

      return setRemoteTextTrack(track);
      // return (track.mode = "showing");
    };
    // Selected track is triggerd before player exists
    // console.info("Effect: selectedTrack:", selectedTrack, player);
    if (player) {
      //console.log(player.remoteTextTracks().tracks_);
      console.info("Setting preview track to:", selectedTrack);
      setActivePreviewTrack(selectedTrack);
    }
    return () => {
      //console.info("Return - Effect: selectedTrack:", selectedTrack, player);
    };
  }, [selectedTrack, player, selectedLang, tracks]);

  // useEffect(() => {
  //   // This fires in the beginning, and again when the preview track is set...
  //   if (remoteTextTrack) {
  //     //console.info("Clearing cues", player);
  //     remoteTextTrack.cues_ = [];

  //     if (
  //       tracks &&
  //       tracks[selectedTrack].cues &&
  //       tracks[selectedTrack].cues.length > 0
  //     ) {
  //       tracks[
  //         remoteTextTrack.kind === "descriptions"
  //           ? "descriptive"
  //           : remoteTextTrack.kind
  //       ].cues.map((cue) => {
  //         // ADD VTT FOR SUPPORTED BROWSERS
  //         const c = new VTTCue(cue.start, cue.end, cue.text);
  //         c.id = cue.id;
  //         c.size = 100;
  //         return remoteTextTrack.addCue(c);
  //       });
  //     }
  //   }
  //   console.info("Effect: remoteTextTrack:", remoteTextTrack);
  // }, [remoteTextTrack]);

  // Dont need this effect since the whole track is updated...
  // useEffect(() => {
  //   console.log("Effect: selectedCue", selectedCue);
  //   if (selectedCue && remoteTextTrack) {
  //     console.info("Is cue in track?");
  //     let exist = remoteTextTrack.cues_.filter(
  //       (cue) => cue.id === selectedCue
  //     )[0];
  //     console.log(exist ? "Yes" : "Nope");
  //   }
  // }, [selectedCue]);

  useEffect(() => {
    // Seems a bit drastic to clear all cues on every update
    // console.log("Effect: tracks", tracks);
    if (remoteTextTrack) {
      remoteTextTrack.cues_ = [];

      if (
        tracks &&
        tracks[selectedTrack].cues &&
        tracks[selectedTrack].cues.length > 0
      ) {
        tracks[
          remoteTextTrack.kind === "descriptions"
            ? "descriptive"
            : remoteTextTrack.kind
        ].cues.map((cue) => {
          // ADD VTT FOR SUPPORTED BROWSERS
          const c = new VTTCue(cue.start, cue.end, cue.text);
          c.id = cue.id;
          c.size = 100;
          return remoteTextTrack.addCue(c);
        });
      }

      player.textTrackDisplay.updateDisplay();
    }
  }, [tracks, remoteTextTrack]);

  // Added lang logic in preview track instead
  // useEffect(() => {
  //   console.log("Effect: selectedLang", selectedLang);
  // }, [selectedLang]);

  return <Fragment></Fragment>;
}

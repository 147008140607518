/**
 * @param {String} url - The url to the cloud function
 * @return {Upload} Upload - the returned function
 */
function Uploader(url) {
 //Just to remove the trailing slash if there is one...
 var baseUrl = url.replace(/^(.+?)\/*?$/, "$1");

 var fileInterface = function(file, id, chunks) {
  let numberOfChunks = chunks || 32;
  var file = file;
  var parts = [];
  var chunkSize = (file.size / numberOfChunks - 1) >> 0;
  var index = 1;
  var start = 0;
  var end = chunkSize;
  this.getSegment = function() {
   let chunk = file.slice(start, end);
   let filename = id + "-part-" + index;
   if (chunk.size > 0) {
    parts.push(filename);
   }

   start = end;
   end = start + chunkSize;
   index++;
   return { chunk: chunk, filename: filename, index: index - 1 };
  };
  this.getParts = function() {
   return parts;
  };
 };

 function createFileWorker() {
  var fn = function(e) {
   var data = e.data;
   var url = data.url;
   var filename = data.filename;
   var index = data.index;

   var xhr = new XMLHttpRequest();
   //xhr.open('PUT', url + '/' + filename, true);
   xhr.open("PUT", url, true);
   xhr.onreadystatechange = function() {
    if (xhr.readyState === XMLHttpRequest.DONE) {
     if (xhr.status === 200) {
      //console.log(xhr.responseText);
      postMessage({
       msg: "uploaded",
       filename: filename,
       transfered: data.chunk.size,
       index: index,
       response: this.responseText
      });
     }
     if (xhr.status >= 400 && xhr.status < 500) {
      postMessage({
       msg: "error",
       filename: filename,
       response: this.responseText,
       chunk: data.chunk
      });
     }
    }
   };
   xhr.upload.onprogress = function(e) {
    postMessage({
     msg: "progress",
     index: index,
     transfered: e.loaded,
     bytes: data.chunk.size
    });
   };

   xhr.send(data.chunk);
  };

  var blob = new Blob(["self.onmessage = ", fn.toString()], {
   type: "text/javascript"
  });
  var url = URL.createObjectURL(blob);
  return new Worker(url);
 }

 /**
  * @function Upload
  * @param {File} file - The file to upload
  * @param {Object} userData - Object with uid and bcid
  * @param {Function} onProgress - Function that gets called on progress
  * @param {Function} onComplete - Function that gets called on complete
  */
 function Upload(file, userData, onProgress, onComplete) {
  var progressFunction =
   onProgress ||
   function(p) {
    console.info(
     "Uploaded " + p.uploaded + " of " + p.total + ". " + p.percent + "%"
    );
   };
  var completeFunction =
   onComplete ||
   function(c) {
    console.info(c);
   };
  function registerUpload() {
   let fileObject = { name: file.name, size: file.size, mime: file.type };
   let postData = { user: userData, sourceFile: fileObject };

   let xhr = new XMLHttpRequest();
   xhr.open("POST", baseUrl + "/register", true);
   xhr.onreadystatechange = function() {
    if (xhr.readyState === XMLHttpRequest.DONE) {
     if (xhr.status === 200) {
      //console.log(xhr.responseText);
      doUpload(xhr.responseText);
     } else {
      console.error(xhr.responseText);
      console.error("Failed to upload file");
     }
    }
   };
   //xhr.send(JSON.stringify(fileObject));
   xhr.send(JSON.stringify(postData));
  }
  function doUploadOld(id) {
   console.info("Will upload " + file.name + " with the id: " + id);
   // console.info(file.size);
   var fileIO = new fileInterface(file, id);
   var maxWorkers = navigator.hardwareConcurrency || 6,
    workers = 0;

   var progress = {
    total: file.size,
    progress: new Array(32).fill(0),
    updateProgress: function(index, value) {
     this.progress[index] = value;
     this.logProgress();
    },
    logProgress: function() {
     let uploaded = this.progress.reduce(function(a, b) {
      return a + b;
     }, 0);
     console.info(uploaded + " / " + this.total);
    }
   };

   for (var i = 0; i < 4; i++) {
    workers++;
    var worker = createFileWorker();
    worker.number = workers;
    worker.id = id;
    worker.onmessage = function(e) {
     var data = e.data;
     //console.log(e.data);
     switch (data.msg) {
      case "uploaded":
       progress.updateProgress(data.index, data.transfered);
       var segment = fileIO.getSegment();
       if (segment.chunk.size > 0) {
        this.postMessage({
         chunk: segment.chunk,
         filename: segment.filename,
         url: baseUrl,
         index: segment.index
        });
       } else {
        console.info("No more data, terminating worker " + this.number);
        workers--;
        this.terminate();
        if (workers === 0) {
         console.info("No more workers");
         finalizeUpload(this.id, fileIO.getParts());
        }
       }
       break;
      case "error":
       console.error("Error " + data.response);
       break;
      case "progress":
       //console.info(data);
       progress.updateProgress(data.index, data.transfered);
       break;
      default:
       break;
     }
    };
    worker.onerror = function(e) {
     console.error(e);
    };
    var segment = fileIO.getSegment();
    if (segment.chunk.size > 0) {
     worker.postMessage({
      chunk: segment.chunk,
      filename: segment.filename,
      url: baseUrl,
      index: segment.index
     });
    } else {
     workers--;
     worker.terminate();
    }
   }
  }
  function doUpload(response) {
   var uploadData = JSON.parse(response);
   console.log(uploadData);
   var numberOfChunks = uploadData.urls.length;
   console.info("Will upload " + file.name + " with the id: " + uploadData.id);
   // console.info(file.size);
   var fileIO = new fileInterface(file, uploadData.id, numberOfChunks);
   var maxWorkers = navigator.hardwareConcurrency || 6,
    workers = 0;

   var progress = {
    total: file.size,
    progress: new Array(numberOfChunks).fill(0),
    updateProgress: function(index, value) {
     this.progress[index] = value;
     this.logProgress();
    },
    logProgress: function() {
     let uploaded = this.progress.reduce(function(a, b) {
      return a + b;
     }, 0);
     let percent = ((uploaded / this.total) * 100).toFixed(2);

     progressFunction({
      uploaded: uploaded,
      total: this.total,
      percent: percent
     });
     //console.info(uploaded + ' / ' + this.total);
    }
   };

   for (var i = 0; i < numberOfChunks; i++) {
    workers++;
    var worker = createFileWorker();
    worker.number = workers;
    worker.id = i;
    worker.onmessage = function(e) {
     var data = e.data;
     //console.log(e.data);
     switch (data.msg) {
      case "uploaded":
       progress.updateProgress(data.index, data.transfered);
       workers--;
       this.terminate();
       if (workers === 0) {
        //console.info('No more workers');
        let filenames = [];
        for (let i = 0; i < uploadData.urls.length; i++) {
         filenames.push(uploadData.urls[i].file);
        }
        finalizeUpload(uploadData.id, filenames);
       }
       break;
      case "error":
       console.error("Error " + data.response);
       break;
      case "progress":
       //console.info(data);
       progress.updateProgress(data.index, data.transfered);
       break;
      default:
       break;
     }
    };
    worker.onerror = function(e) {
     console.error(e);
    };
    var segment = fileIO.getSegment();
    if (segment.chunk.size > 0) {
     worker.postMessage({
      chunk: segment.chunk,
      filename: segment.filename,
      url: uploadData.urls[i].url,
      index: segment.index
     });
    } else {
     workers--;
     worker.terminate();
    }
   }
  }
  function finalizeUpload(id, parts) {
   let fileObject = {
    name: file.name,
    size: file.size,
    mime: file.type,
    parts: parts,
    id: id
   };
   let xhr = new XMLHttpRequest();
   xhr.open("POST", baseUrl + "/manifest", true);
   xhr.onreadystatechange = function() {
    if (xhr.readyState === XMLHttpRequest.DONE) {
     if (xhr.status === 200) {
      //console.log(xhr.responseText);
      completeFunction(JSON.parse(xhr.responseText));
     } else {
      console.error(xhr.responseText);
      console.error("Failed to submit manifest");
     }
    }
   };
   xhr.send(JSON.stringify(fileObject));
  }
  registerUpload();
 }
 return Upload;
}

export { Uploader };

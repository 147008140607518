import axios from "axios";

/** Sends a Slack Message with video details */
const newSignLanguageHook = async (user, videoId, uploadedUrl, uploadId) => {
  const { first_name, last_name, email } = user;
  const { account_id, name: account_name } = user.selectedAccount;

  console.log(first_name, last_name, email, account_id, account_name, uploadId);

  const data = {
    blocks: [
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: "*New Sign Language video uploaded!*",
        },
      },
      {
        type: "section",
        fields: [
          {
            type: "mrkdwn",
            text: `*Account Name:*\n${account_name}`,
          },
          {
            type: "mrkdwn",
            text: `*User:*\n${first_name} ${last_name}`,
          },
          {
            type: "mrkdwn",
            text: `*BC Account ID:*\n${account_id}`,
          },
          {
            type: "mrkdwn",
            text: `*BC Video ID:*\n${videoId}`,
          },
          {
            type: "mrkdwn",
            text: `*Upload ID:*\n${uploadId}`,
          },
        ],
      },
      {
        type: "divider",
      },
      {
        type: "actions",
        elements: [
          {
            type: "button",
            text: {
              type: "plain_text",
              emoji: true,
              text: "Download",
            },
            style: "primary",
            url: `${uploadedUrl}`,
          },
          {
            type: "button",
            text: {
              type: "plain_text",
              emoji: true,
              text: "Open BC",
            },
            url: "https://studio.brightcove.com/products/videocloud/home/",
          },
          {
            type: "button",
            text: {
              type: "plain_text",
              emoji: true,
              text: "Email user",
            },
            style: "danger",
            url: `mailto:${email}`,
          },
        ],
      },
    ],
  };

  const response = await axios({
    method: "post",
    url: `https://hooks.slack.com/services/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`,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: JSON.stringify(data),
  }).then((res) => res);

  console.log(response);
};

export { newSignLanguageHook };

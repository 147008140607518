import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { NavLink, Link } from "react-router-dom";
import { useFirebase } from "react-redux-firebase";
import { useFirestore } from "react-redux-firebase";
import { useSelector } from "react-redux";

import { addNotification } from "./Notification";
import { t } from "../i18n/language";

import logo from "../img/equalplay_logo_green.svg";
import store from "./auth/firebase";

export default function Navbar() {
  const [openMenu, setOpenMenu] = useState(false);
  const [openMessages, setOpenMessages] = useState(false);

  const firebase = useFirebase();
  const firestore = useFirestore();
  const history = useNavigate();

  const uid = useSelector((state) => state.firebase.auth.uid);
  const user = useSelector((state) => state.user);

  const handleAccountChange = (account) => {
    const itemUpdates = {
      selectedAccount: { ...account },
      updatedAt: firestore.FieldValue.serverTimestamp(),
    };

    firestore.update({ collection: "users", doc: uid }, itemUpdates);
    setOpenMenu(false);
  };

  const readMessage = async (id) => {
    return await firestore.update(`users/${user.uid}/messages/${id}`, {
      delivered: true,
    });
  };

  const handleMessageClick = async (id, bc_id) => {
    setOpenMessages(false);

    await readMessage(id);

    if (history.location.pathname.includes("editor")) {
      //  console.log("should go to:", bc_id);
      history.push(`/editor/${bc_id}`);
      return history.go(`/editor/${bc_id}`);
    } else {
      //  console.log("should push to:", bc_id);
      return history.push(`/editor/${bc_id}`);
    }
  };

  useEffect(() => {
    //console.info("useeffect...");
    if (uid && !user.uid) {
      // console.info("add snapshotlisteners here");
      const fetchUserData = firestore.onSnapshot(
        {
          collection: "users",
          where: ["uid", "==", `${uid}`],
        },
        (snapshot) => {
          if (snapshot.docs.length === 0) {
            addNotification(
              t("There seems to be a connection issue"),
              t("Please try again in a few moments"),
              "bg-red-600"
            );
            return fetchUserData();
          }
          // console.log("Got snapshot", snapshot);
          const userData = snapshot.docs[0].data();
          // console.log("Got userData", userData);

          firestore.onSnapshot(
            {
              collection: "bc_accounts",
              doc: `${userData.selectedAccount.account_id}`,
            },
            (snapshot) => {
              // console.log("Got bc snapshot:", snapshot);
              let bc_account_data = snapshot.data();
              // console.info("BC:", bc_account_data);

              return store.dispatch({
                type: "ADD_USER",
                user: {
                  ...userData,
                  player_id: bc_account_data.player_id || "default",
                  token: {
                    currentToken: bc_account_data.currentToken,
                    currentTokenExpires: bc_account_data.currentTokenExpires,
                  },
                },
              });
            }
          );

          firestore.onSnapshot(
            {
              collection: "users",
              doc: uid,
              subcollections: [{ collection: "messages" }],
              limit: 5,
              storeAs: "messages",
            },
            (snapshot) => {
              return snapshot.forEach((message) => {
                // console.log("message", message.data());
                return store.dispatch({
                  type: "ADD_MESSAGE",
                  message: { ...message.data(), id: message.id },
                });
              });
            }
          );
        }
      );

      return () => {
        // console.info("Remove snapshotlisteners here?");
        //fetchUserData();
      };
    }
    // eslint-disable-next-line
  }, [uid]);

  return (
    <header className="flex items-center h-10 bg-equalplay-dark text-white">
      <nav className="flex justify-between container mx-auto px-5 items-center relative">
        <div className="flex items-center">
          <NavLink to={process.env.PUBLIC_URL + "/"} className="flex">
            <div className="">
              <img className="w-6 h-6" src={logo} alt="Equal Play Logo" />
            </div>
            <span className="hidden md:inline-block ml-2 text-white font-equal-play">
              Equal Play
            </span>
          </NavLink>
          <NavLink
            end
            to={process.env.PUBLIC_URL + "/"}
            className={({ isActive }) =>
              isActive
                ? "px-2 py-1 ml-6 rounded text-sm bg-equalplay-light"
                : "px-2 py-1 ml-6 rounded text-sm hover:bg-equalplay-light"
            }
          >
            {t("Dashboard")}
          </NavLink>
          <NavLink
            to={process.env.PUBLIC_URL + "/videos"}
            className={({ isActive }) =>
              isActive
                ? "px-2 py-1 ml-2 rounded text-sm bg-equalplay-light"
                : "px-2 py-1 ml-2 rounded text-sm hover:bg-equalplay-light"
            }
          >
            {t("Videos")}
          </NavLink>
        </div>
        <div className="flex items-center">
          <NavLink
            to={process.env.PUBLIC_URL + "/support"}
            className={({ isActive }) =>
              isActive
                ? "px-2 py-1 ml-2 rounded text-sm bg-equalplay-light"
                : "px-2 py-1 ml-2 rounded text-sm hover:bg-equalplay-light"
            }
          >
            Support
          </NavLink>
          <div className="relative">
            {/* MESSAGES BUTTON */}
            <button
              className={`flex ml-2 px-1 py-1 text-sm rounded  focus:outline-none focus:shadow-outline ${
                openMessages ? "bg-equalplay-light" : ""
              } ${
                user.messages &&
                user.messages.filter((m) => {
                  return m.delivered === false;
                }).length > 0
                  ? "bg-equalplay-light"
                  : ""
              } hover:bg-equalplay-light`}
              onClick={() => setOpenMessages(!openMessages)}
            >
              <svg
                className="w-6 h-6"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
              </svg>
              {user.messages &&
              user.messages.filter((m) => {
                return m.delivered === false;
              }).length > 0 ? (
                <span className="pl-1">
                  {
                    user.messages.filter((m) => {
                      return m.delivered === false;
                    }).length
                  }
                </span>
              ) : (
                ""
              )}
            </button>

            {/* MESSAGES DROPDOWN */}
            {openMessages && (
              <div className="py-2 absolute top-0 right-0 mt-10 text-gray-900 shadow-md rounded-lg bg-white z-10">
                <div className="w-72 text-sm flex flex-col text-center">
                  <div className="px-4 text-xs font-light text-gray-700 flex justify-between">
                    <span className="font-semibold">{t("Messages")}</span>
                    <button
                      className="px-1 focus:outline-none focus:shadow-outline"
                      onClick={() => {
                        if (user.messages && user.messages.length > 0) {
                          user.messages.map((message) => {
                            return readMessage(message.id);
                          });
                        } else {
                          return null;
                        }
                      }}
                    >
                      {t("Mark all read")}
                    </button>
                  </div>
                  <span className="mt-2 mx-2 border border-t-1 border-gray-200"></span>
                  {!user.messages && (
                    <span className="px-4 py-2 mt-2 text-left text-xs text-gray-700">
                      {t("No messages to show")}.
                    </span>
                  )}
                  {user.messages
                    ? user.messages
                        .sort((a, b) => b.date.seconds - a.date.seconds)
                        .map((message) => {
                          // console.log(message);
                          switch (message.service) {
                            case "asr":
                              return (
                                <button
                                  key={message.job_id}
                                  className={`px-3 py-2 mt-1 text-left hover:bg-gray-200 cursor-pointer flex items-center transition-all duration-200 ${
                                    !message.delivered
                                      ? "bg-gray-100"
                                      : "bg-white"
                                  } focus:outline-none focus:shadow-outline`}
                                  onClick={() =>
                                    handleMessageClick(
                                      message.id,
                                      message.bc_id
                                    )
                                  }
                                >
                                  <span
                                    className={`${
                                      message.delivered
                                        ? "text-gray-500"
                                        : "text-green-400"
                                    }`}
                                  >
                                    <svg
                                      className="w-5 h-5"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M10 18.333a8.334 8.334 0 110-16.667 8.334 8.334 0 010 16.667zm0-1.666a6.667 6.667 0 100-13.335 6.667 6.667 0 000 13.335zm-1.917-7.25l1.084 1.075 2.75-2.75a.834.834 0 011.166 1.183L9.75 12.258a.833.833 0 01-1.167 0l-1.666-1.666a.833.833 0 011.166-1.184v.009z"
                                        fill="currentColor"
                                      />
                                    </svg>
                                  </span>
                                  <span
                                    className={`pl-4 ${
                                      !message.delivered && "font-semibold"
                                    }`}
                                  >
                                    {t("ASR process complete for your video")}:{" "}
                                    {message.bc_id}.
                                  </span>
                                </button>
                              );

                            default:
                              return null;
                          }
                        })
                    : null}
                </div>
              </div>
            )}
          </div>

          <button
            className={`flex ml-2 px-1 py-1 text-sm rounded ${
              openMenu ? "bg-equalplay-light" : ""
            } focus:outline-none focus:shadow-outline hover:bg-equalplay-light`}
            onClick={() => {
              setOpenMenu(!openMenu);
            }}
          >
            {user && user.selectedAccount ? (
              <div className="flex">
                <div className="hidden md:flex">
                  <span className="ml-2 font-bold">
                    {user.first_name} {user.is_admin ? "(Admin)" : null}
                  </span>
                  <span className="ml-1">| {user.selectedAccount.name}</span>
                </div>
                <svg className="w-6 h-6" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fill="currentColor"
                    d="M15.3 9.3a1 1 0 011.4 1.4l-4 4a1 1 0 01-1.4 0l-4-4a1 1 0 011.4-1.4l3.3 3.29 3.3-3.3v.01z"
                  />
                </svg>
              </div>
            ) : (
              <Link
                className="px-1"
                to={process.env.PUBLIC_URL + "/login"}
                onClick={() => {
                  firebase.logout().then((res) =>
                    store.dispatch({
                      type: "REMOVE_USER",
                    })
                  );
                }}
              >
                Login
              </Link>
            )}
          </button>
        </div>

        {/* ACCOUNTS DROPDOWN */}
        {openMenu ? (
          <div className="py-2 absolute top-0 right-0 mt-10 mr-5 text-gray-900 shadow-md rounded-lg bg-white z-10">
            <div className="text-sm flex flex-col text-center">
              <div className="text-xs font-light text-gray-700">
                {t("Accounts")}
              </div>
              {user.bc_accounts
                ? user.bc_accounts.map((account) => {
                    return (
                      <button
                        key={account.account_id}
                        className={`${
                          user.selectedAccount.name === account.name
                            ? "bg-gray-200 font-bold"
                            : ""
                        } mt-1 px-8 py-2 hover:bg-gray-200 transition-all duration-200`}
                        onClick={() => {
                          handleAccountChange(account);
                        }}
                      >
                        {account.name}
                      </button>
                    );
                  })
                : null}
              <span className="mt-1 mx-2 border border-t-1 border-gray-200"></span>
              <Link
                className="mt-1 px-4 py-2 hover:bg-gray-200"
                to={process.env.PUBLIC_URL + "/settings"}
                onClick={() => {
                  setOpenMenu(false);
                }}
              >
                {t("Settings")}
              </Link>
              {user && user.is_manager && (
                <>
                  <span className="mt-1 mx-2 border border-t-1 border-gray-200"></span>{" "}
                  <Link
                    className="mt-1 px-4 py-2 hover:bg-gray-200"
                    to={process.env.PUBLIC_URL + "/admin"}
                    onClick={() => {
                      setOpenMenu(false);
                    }}
                  >
                    {t("Administration")}
                  </Link>
                </>
              )}
              <span className="mt-1 mx-2 border border-t-1 border-gray-200"></span>
              <Link
                className="mt-1 px-4 py-2 hover:bg-gray-200 transition-all duration-200"
                to={process.env.PUBLIC_URL + "/login"}
                onClick={() => {
                  firebase
                    .auth()
                    .signOut()
                    .then(() => {
                      firebase.logout();
                      store.dispatch({
                        type: "REMOVE_USER",
                      });
                    });
                  // firebase.logout().then((res) =>
                  //   store.dispatch({
                  //     type: "REMOVE_USER",
                  //   })
                  // );
                }}
              >
                {t("Logout")}
              </Link>
            </div>
          </div>
        ) : null}
      </nav>
    </header>
  );
}

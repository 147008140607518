import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import randomize from "randomatic";
import { motion, AnimatePresence } from "framer-motion";
import store from "./auth/firebase";

const removeNotification = (id) => {
  store.dispatch({
    type: "REMOVE_NOTIFICATION",
    id,
  });
};

export default function Notification() {
  const notifications = useSelector((state) => state.notifications);

  return (
    <Fragment>
      {/* <button
    className="bg-gray-800 text-white"
    onClick={() => {
     addNotification("Sucessful notification", "This works, it really did!");
    }}
   >
    add one
   </button> */}
      <div className="fixed inset-0 mx-4 my-4 flex flex-col justify-end items-end z-1002 pointer-events-none">
        <ul>
          <AnimatePresence initial={false}>
            {notifications
              ? notifications.map((n, i) => {
                  return (
                    <motion.li
                      layout
                      initial={{ opacity: 0, y: 50, scale: 0.3 }}
                      animate={{ opacity: 1, y: 0, scale: 1 }}
                      exit={{
                        opacity: 0,
                        scale: 0.5,
                        transition: { duration: 0.2 },
                      }}
                      key={i}
                      id={n.id}
                      className={`${n.bgColour} w-56 px-4 py-3 mx-4 my-4 text-white bg-white shadow rounded`}
                    >
                      <div className="text-sm font-semibold">{n.title}</div>
                      <p className="text-sm">{n.body}</p>
                    </motion.li>
                  );
                })
              : null}
          </AnimatePresence>
        </ul>
      </div>
    </Fragment>
  );
}

/**
 * Add a notification.
 * @function addNotification
 * @param {string} title - Title of the notification.
 * @param {string} body - Body text of the notification.
 * @param {integar} id - ID to itentify the notification.
 * @param {srting} bgColour - BG Colour of the notification. 'bg-equalplay-light' for success, 'bg-red-600' for failure.
 */
export const addNotification = (
  title,
  body,
  bgColour = "bg-equalplay-light",
  id = randomize("Aa0", 10)
) => {
  const newNotification = {
    id,
    title: title,
    body: body,
    bgColour: bgColour,
  };
  store.dispatch({
    type: "ADD_NOTIFICATION",
    item: { ...newNotification },
  });

  setTimeout(() => {
    removeNotification(id);
  }, 8000);
};

import React, { useEffect, useState } from "react";
import videojs from "video.js";
import { useSelector } from "react-redux";

export default function VideoJS({
  videoId,
  videoDuration,
  play,
  handleTracks,
  currentTime,
  player,
}) {
  const [videoCanPlay, setVideoCanPlay] = useState(false);

  const account_id = useSelector(
    (state) => state.user.selectedAccount.account_id
  );
  const player_id = useSelector((state) => state.user.player_id);

  let videoPlayer;

  useEffect(() => {
    if (videoCanPlay) {
      // eslint-disable-next-line
      videoPlayer = videojs("bcPlayer");
      //    console.log(videoPlayer);
      player(videoPlayer);
      videoPlayer.on("timeupdate", function () {
        let s = this.currentTime();
        currentTime(s);
        //t.scrollToTime(s)
      });

      //    handleTracks(videoPlayer.mediainfo.text_tracks);
      videoDuration(Math.floor(videoPlayer.mediainfo.duration));

      return () => {
        videoPlayer = null;
      };
    }
  }, [videoCanPlay]);

  useEffect(() => {
    let script = document.createElement("script");

    script.src = `https://players.brightcove.net/${account_id}/${player_id}_default/index.min.js`;
    script.async = true;
    script.defer = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [player_id, account_id]);

  return (
    <div className="relative block max-w-2xl">
      <div className="pt-16x9">
        <video
          id="bcPlayer"
          data-video-id={videoId}
          data-account={account_id}
          data-player={player_id}
          data-embed="default"
          data-application-id="Equal Play"
          data-show-draft="true"
          className="video-js absolute top-0 right-0 left-0 bottom-0"
          onLoadedMetadata={(e) => {
            setVideoCanPlay(e.target);
          }}
          controls
          style={{
            width: "100%",
            height: "100%",
          }}
        ></video>
      </div>
    </div>
  );
}

import React from "react";

export default function Input({
 label = "",
 name = "",
 type = "text",
 placeholder = "",
 autoComplete = "",
 onChange,
 value = "",
 required = true,
 css = "",
 checked,
 disabled = false
}) {
 return (
  <div className={`${css} flex flex-col`}>
   <label className="text-sm" htmlFor={name}>
    {label}
   </label>
   <input
    className={`${
     disabled ? "bg-gray-200" : ""
    } mt-2 px-2 py-2 border border-gray-200 rounded focus:outline-none focus:shadow-outline`}
    name={name}
    type={type}
    placeholder={placeholder}
    autoComplete={autoComplete}
    onChange={e => {
     onChange(e);
    }}
    checked={checked}
    value={value}
    required={required}
    disabled={disabled}
   />
  </div>
 );
}

import React, { Fragment, useState } from "react";

import FeatureStatus from "./FeatureStatus";
import Rating from "./Rating";
import Modal from "../Modal";
import Video from "../layouts/Video";
import PreviewLink from "../layouts/PreviewLink";
import { t } from "../../i18n/language";

import { msToHms } from "../utils/timeToTime";

const isValidJSON = (json) => {
  try {
    return JSON.parse(json);
  } catch (error) {
    return null;
  }
};

export default function TableRow({ result }) {
  const [modalOpen, setModalOpen] = useState(false);

  const { name, duration = 0, id, account_id, state, images, sharing } = result;
  const { thumbnail } = result.images;

  const custom_field = result.custom_fields.equalplay
    ? isValidJSON(result.custom_fields.equalplay)
    : null;

  //
  // STATUS OF CAPTIONS AND SUBTITLES
  //

  let subsAndCapsApproved = result.text_tracks
    ? result.text_tracks.filter(
        (t) => t.kind === "subtitles" || t.kind === "captions"
      )
    : [];

  let adArrayDraft = [];
  let adArrayProcessing = [];
  let adArrayUploaded = [];
  let adArrayApproved = [];
  let adArrayDisabled = [];

  let tranArrayDraft = [];
  let tranArrayProcessing = [];
  let tranArrayUploaded = [];
  let tranArrayApproved = [];
  let tranArrayDisabled = [];

  let signArrayDraft = [];
  let signArrayProcessing = [];
  let signArrayUploaded = [];
  let signArrayApproved = [];
  let signArrayDisabled = [];

  if (custom_field) {
    //
    // STATUS OF AUDIO DESCRIPTION
    //
    custom_field.audio_description.map((ad) => {
      if (ad.status === "draft") {
        adArrayDraft.push(ad);
      } else if (ad.status === "processing") {
        adArrayProcessing.push(ad);
      } else if (ad.status === "uploaded") {
        adArrayUploaded.push(ad);
      } else if (ad.status === "approved") {
        adArrayApproved.push(ad);
      } else if (ad.status === "disabled") {
        adArrayDisabled.push(ad);
      }
      return null;
    });
    //
    // STATUS OF TRANSCRIPION
    //
    custom_field.transcript.map((tran) => {
      if (tran.status === "draft") {
        tranArrayDraft.push(tran);
      } else if (tran.status === "processing") {
        tranArrayProcessing.push(tran);
      } else if (tran.status === "uploaded") {
        tranArrayUploaded.push(tran);
      } else if (tran.status === "approved") {
        tranArrayApproved.push(tran);
      } else if (tran.status === "disabled") {
        tranArrayDisabled.push(tran);
      }
      return null;
    });

    //
    // STATUS OF SIGN
    //
    custom_field.sign.map((sign) => {
      if (sign.status === "draft") {
        signArrayDraft.push(sign);
      } else if (sign.status === "processing") {
        signArrayProcessing.push(sign);
      } else if (sign.status === "uploaded") {
        signArrayUploaded.push(sign);
      } else if (sign.status === "approved") {
        signArrayApproved.push(sign);
      } else if (sign.status === "disabled") {
        signArrayDisabled.push(sign);
      }
      return null;
    });
  }

  return (
    <div className="py-4 grid grid-cols-4 md:grid-cols-9 border-b border-gray-200 text-center items-center">
      <div
        className="px-4 grid grid-cols-8 col-span-3 md:col-span-4 text-left items-center"
        style={{ textOverflow: "ellipsis" }}
      >
        <button
          onClick={(e) => {
            if (state === "ACTIVE") {
              setModalOpen(true);
            }
          }}
          className="col-span-2 w-100 self-center relative"
        >
          <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center">
            <span>
              <svg
                className="w-5 h-5 text-white"
                stroke="currentColor"
                strokeWidth="2"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M16 12c.333-.167.5-.6.5-1s-.167-.833-.5-1C13 8 7 4.5 5.5 3.5c-1.2-.8-1.5.333-1.5 1V16c0 1.2.5 1.5 1.5 1L16 12z" />
              </svg>
            </span>
          </div>

          <div
            className="pb-16x9 rounded-sm bg-gray-100"
            style={{
              backgroundImage: `url('${
                images.thumbnail && thumbnail.src.replace(/https:|http:/gi, "")
              }')`,
              backgroundSize: "cover",
            }}
          ></div>
        </button>
        <Modal
          title={t("Preview")}
          width="w-5/5 md:w-3/5"
          show={modalOpen}
          acceptButtonText={t("Close")}
          onAccept={() => {
            setModalOpen(false);
          }}
          onClose={() => {
            setModalOpen(false);
          }}
        >
          <Video title={name} width="w-full" videoId={id} />
          <PreviewLink videoId={id} accountId={account_id} />
        </Modal>

        <div className="col-span-6 ml-4 overflow-hidden">
          <div
            className="text-gray-800 text-sm font-semibold whitespace-no-wrap overflow-hidden"
            style={{ textOverflow: "ellipsis" }}
          >
            {name}
          </div>
          <div className="text-xs text-gray-500">
            {msToHms(duration)}{" "}
            <span className="text-gray-300">&bull; {id}</span>
          </div>
        </div>
      </div>

      {/* VIDEO ACTIVE STATE */}
      {state !== "ACTIVE" ? (
        <div className="col-span-1 md:col-span-5 text-gray-600 text-xs">
          <span
            className="relative tooltip cursor-default border-b border-gray-200 hover:border-gray-500"
            data-tooltip={t("Activate this video in your Brightcove account")}
          >
            <span className="uppercase">{t("Video not active")}</span>
          </span>
        </div>
      ) : sharing && sharing.by_external_acct === true ? (
        <div className="col-span-1 md:col-span-5 text-gray-600 text-xs">
          <span
            className="relative tooltip cursor-default border-b border-gray-200 hover:border-gray-500"
            data-tooltip={t("Shared videos cannot be edited on this account")}
          >
            <span className="uppercase">{t("Shared video")}</span>
          </span>
        </div>
      ) : (
        <Fragment>
          <div className="px-2">
            <Rating
              subsAndCaps={subsAndCapsApproved.length}
              tran={tranArrayApproved.length}
              ad={adArrayApproved.length}
              sl={signArrayApproved.length}
            />
          </div>
          <div className="px-2 hidden md:inline">
            <FeatureStatus
              status={{ approved: subsAndCapsApproved.length }}
              linkTo={`${process.env.PUBLIC_URL}/editor/${id}`}
            />
          </div>
          <div className="px-2 hidden md:inline">
            <FeatureStatus
              status={{
                draft: tranArrayDraft.length,
                processing: tranArrayProcessing.length,
                uploaded: tranArrayUploaded.length,
                approved: tranArrayApproved.length,
                disabled: tranArrayDisabled.length,
              }}
              linkTo={`${process.env.PUBLIC_URL}/editor/${id}`}
            />
          </div>
          <div className="px-2 hidden md:inline">
            <FeatureStatus
              status={{
                draft: adArrayDraft.length,
                processing: adArrayProcessing.length,
                uploaded: adArrayUploaded.length,
                approved: adArrayApproved.length,
                disabled: adArrayDisabled.length,
              }}
              linkTo={`${process.env.PUBLIC_URL}/audio-description/${id}`}
            />
          </div>
          <div className="px-2 hidden md:inline">
            <FeatureStatus
              status={{
                draft: signArrayDraft.length,
                processing: signArrayProcessing.length,
                uploaded: signArrayUploaded.length,
                approved: signArrayApproved.length,
                disabled: signArrayDisabled.length,
              }}
              linkTo={`${process.env.PUBLIC_URL}/sign-language/${id}`}
            />
          </div>
        </Fragment>
      )}
    </div>
  );
}

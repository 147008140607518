import React, { Fragment, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import ReactPaginate from "react-paginate";

import Navbar from "./Navbar";
import TwoColWithSidebar, { Sidebar, Main } from "./layouts/TwoColWithSidebar";
import SidebarSection from "./layouts/SidebarSection";
import SidebarHeader from "./layouts/SidebarHeader";
import SidebarFilters from "./layouts/SidebarFilters";
import Search from "./layouts/Search";
import Table from "./table/Table";
import Notification from "./Notification";
import { t } from "../i18n/language";

import { bcCMSVideos } from "./utils/brightcoveRequest";

export default function Videos() {
  const [bcQuery, setBcQuery] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortOrder, setSortOrder] = useState("-updated_at");
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);

  const account = useSelector((state) => state.user.selectedAccount);
  const defaultTags = useSelector((state) => state.user.default_filter_tags);

  useEffect(() => {
    if (account) {
      setBcQuery([]);
      bcCMSVideos(account, searchQuery, sortOrder, defaultTags, currentPage)
        .then((res) => {
          setBcQuery(res.results.data);
          setTotalPages(Math.ceil(res.totalVideoCount.data.count / 10));
          return res;
        })
        .catch((err) => console.error(err));
    }
  }, [account, searchQuery, sortOrder, defaultTags, currentPage]);

  return (
    <Fragment>
      <Navbar />
      <Notification />
      <TwoColWithSidebar>
        <Sidebar>
          <SidebarSection>
            <SidebarHeader text={t("Filters")} icon="filters" />
            <SidebarFilters sort={(choice) => setSortOrder(choice)} />
          </SidebarSection>
        </Sidebar>
        <Main>
          <Search
            searchQuery={(text) => {
              setSearchQuery(text);
            }}
            placeholder={t("Search videos...")}
          />
          <Table bcQuery={bcQuery} />
          {totalPages <= 1 ? null : (
            <ReactPaginate
              pageCount={totalPages}
              pageRangeDisplayed={2}
              marginPagesDisplayed={1}
              onPageChange={(p) => setCurrentPage(p.selected)}
              containerClassName="mt-4 flex"
              previousLinkClassName="border border-gray-200 text-gray-600 cursor-pointer hover:bg-white mx-1 px-1 px-2 py-1 rounded focus:outline-none focus:shadow-outline"
              nextLinkClassName="border border-gray-200 text-gray-600 cursor-pointer hover:bg-white mx-1 px-1 px-2 py-1 rounded focus:outline-none focus:shadow-outline"
              pageLinkClassName="border border-gray-200 text-gray-600 cursor-pointer hover:bg-white mx-1 px-1 px-2 py-1 rounded focus:outline-none focus:shadow-outline"
              activeLinkClassName="bg-white text-gray-900"
              nextLabel={t("Next")}
              previousLabel={t("Previous")}
            />
          )}
        </Main>
      </TwoColWithSidebar>
    </Fragment>
  );
}
